import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../navigator/NavigateWrapper";
import { getAPIUrl } from "../utils/database";
import packageJson from "./../../package.json";
import Logo from "./../images/logo.png";

class Logs extends Component {
  state = {
    data: [],
  };

  componentDidMount = async () => {
    try {
      const logsData: any = [];
      const logsQuery = await axios.get(`${getAPIUrl()}/sensor/logs`);
      if (logsQuery.data.length > 0) {
        logsQuery.data.map((eachLog: any) => {
          logsData.push({
            name: eachLog.name,
            date: moment(eachLog.date).format("DD-MM-YY hh:mm:ss"),
          });
          return null;
        });
      }
      this.setState({
        data: logsData,
      });
    } catch (err) {}
  };

  render() {
    return (
      <div className="min-h-screen bg-white flex font-body">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6">
          <div className="mx-auto w-full max-w-2xl">
            <div className="flex flex-col items-center justify-center">
              <img
                className="h-12 w-auto mr-2"
                src={Logo}
                alt="LesQ SPP Logo"
              />
              <h2 className="mt-4 text-3xl font-bold text-gray-900 font-display">
                Sistem Pertanian Pintar
              </h2>
              <p className="mt-1 text-sm text-gray-600 font-display">
                Showing Latest 5 Logs Only
              </p>
            </div>

            <div className="mt-8">
              <div className="space-y-4">
                <span className="flex mt-4 justify-end">
                  Version {packageJson.version}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(withRouter(Logs));
