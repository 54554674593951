import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";

interface FirebaseConfig {
  apiKey: string | undefined;
  authDomain: string | undefined;
  projectId: string | undefined;
  storageBucket: string | undefined;
  messagingSenderId: string | undefined;
  appId: string | undefined;
  measurementId?: string | undefined;
}

const firebaseConfig: FirebaseConfig = {
  apiKey: isProd
    ? process.env.REACT_APP_PROD_API_KEY
    : process.env.REACT_APP_DEV_API_KEY,
  authDomain: isProd
    ? process.env.REACT_APP_PROD_AUTH_DOMAIN
    : process.env.REACT_APP_DEV_AUTH_DOMAIN,
  projectId: isProd
    ? process.env.REACT_APP_PROD_PROJECT_ID
    : process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: isProd
    ? process.env.REACT_APP_PROD_STORAGE_BUCKET
    : process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: isProd
    ? process.env.REACT_APP_PROD_SENDER_ID
    : process.env.REACT_APP_DEV_SENDER_ID,
  appId: isProd
    ? process.env.REACT_APP_PROD_APP_ID
    : process.env.REACT_APP_DEV_APP_ID,
};

const app = initializeApp(firebaseConfig);

if (isProd) {
  firebaseConfig["measurementId"] = process.env.REACT_APP_PROD_GA_ID;
  getAnalytics(app);
}

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
