import { CheckIcon } from "@heroicons/react/solid";
import moment from "moment";
import React, { Component } from "react";

export interface Option {
  key: string;
  title: string;
}

interface Props {
  id: string;
  options: Option[];
  value: string;
  page?: string;
  range?: { startDate: Date; endDate: Date } | null;
  error?: string;
  placeholder?: string;
  onChange?: (id: string, key: string) => void;
}

export default class DateRangeSelect extends Component<Props> {
  state = {
    onFocus: false,
  };

  handleShowInput = (onFocus: boolean) => {
    setTimeout(() => {
      this.setState({
        onFocus,
      });
    }, 200);
  };

  handleOnChange = (key: string) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.id, key);
    }
    this.handleShowInput(false);
  };

  renderOptions = () => {
    let optionView: any = [];
    this.props.options.map((eachOption) => {
      optionView.push(
        <li
          key={eachOption.key}
          className="text-gray-900 cursor-default select-none relative px-5 py-3 hover:bg-emerald-300 group"
          onClick={this.handleOnChange.bind(this, eachOption.key)}
        >
          <span className="font-normal block text-xs truncate group-hover:text-white">
            {eachOption.title}
          </span>
          {this.props.value === eachOption.key && (
            <span className="text-emerald-600 absolute inset-y-0 right-0 flex items-center pr-4 group-hover:text-white">
              <CheckIcon className="h-5 w-5" />
            </span>
          )}
        </li>
      );
      return null;
    });

    return optionView;
  };

  renderSelectedResult = () => {
    let selectedValue = "";
    if (this.props.range) {
      const startDay = moment(this.props.range.startDate).format("DD/MM/YYYY");
      const endDay = moment(this.props.range.endDate).format("DD/MM/YYYY");
      if (startDay === endDay) {
        selectedValue = `${startDay} - Custom Time`;
      } else {
        selectedValue = `${startDay} - ${endDay}`;
      }
    } else {
      this.props.options.map((eachOption) => {
        if (eachOption.key === this.props.value) {
          selectedValue = eachOption.title;
        }
        return null;
      });
    }

    const className = "block truncate text-green-600 font-bold";
    if (!selectedValue) {
      selectedValue = this.props.placeholder ?? "Placeholder";
    }

    return <span className={className}>{selectedValue}</span>;
  };

  render() {
    let buttonClassName =
      "bg-white w-full relative rounded-md px-5 py-3 text-left text-xs cursor-default focus:outline-none focus:border-emerald-500";

    let optionClassName =
      "absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm";
    if (this.props.error) {
      buttonClassName += " border-red-300";
    }

    if (this.props.page !== "details") {
      optionClassName += " max-h-60";
    } else {
      optionClassName += " max-h-28 scrollBar";
    }
    return (
      <div className="mt-1 relative">
        <button
          type="button"
          className={buttonClassName}
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={this.handleShowInput.bind(this, !this.state.onFocus)}
          onFocus={this.handleShowInput.bind(this, true)}
          onBlur={this.handleShowInput.bind(this, false)}
        >
          {this.renderSelectedResult()}
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              className="h-5 w-5 text-emerald-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>
        {this.state.onFocus && (
          <ul className={optionClassName}>{this.renderOptions()}</ul>
        )}
        <span className="text-red-500 text-sm">{this.props.error}</span>
      </div>
    );
  }
}
