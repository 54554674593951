import { Dialog, Transition } from "@headlessui/react";
import React, { Component, Fragment } from "react";
import Button from "../base/Button";

interface Props {
  open: boolean;
  title: string;
  content: string;
  onClose: (visible: boolean) => void;
  onClick: () => void;
}

export default class ConfirmationModal extends Component<Props> {
  handleModalAction = () => {
    this.props.onClick();
    this.props.onClose(false);
  };

  handleCloseModal = () => {
    this.props.onClose(false);
  };

  render() {
    return (
      <Transition.Root show={this.props.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.props.open}
          onClose={this.handleCloseModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-10 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="border-b border-gray-200 pb-4">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {this.props.title}
                    </Dialog.Title>
                  </div>
                  <div className="my-8 whitespace-pre-line">
                    {this.props.content}
                  </div>
                  <div className="flex flex-row justify-end">
                    <Button
                      text="Cancel"
                      type="light"
                      className="mx-4"
                      onClick={this.handleCloseModal}
                    />
                    <Button
                      text="Confirm"
                      type="normal"
                      onClick={this.handleModalAction}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
