import { UserModel } from "../../components/model/User";

export interface userStateInterface {
  users: UserModel[];
  size: number;
  loading: boolean;
}

const initState: userStateInterface = {
  users: [],
  size: 0,
  loading: false,
};

const userReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_USER_LIST":
      return {
        ...state,
        users: action.payload.users,
        size: action.payload.size,
      };
    case "UPDATE_USER_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default userReducer;
