import axios from "axios";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import { Option } from "../../components/base/Select";
import {
  ImageAttribute,
  ImageError,
} from "../../components/dashboard/UploadImageModal";
import { Project } from "../../components/model/Project";
import { ProjectStateAttributeError } from "../../pages/CreateProject";
import { getAPIUrl } from "../../utils/database";
import { auth, storage } from "../../utils/firebase";
import { indexedDb } from "../../utils/indexedDB";

export const clearProjects = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_PROJECT_LIST",
      payload: {
        projects: [],
        size: 0,
      },
    });
  };
};

export const getProjectSuggestion = async (text: string) => {
  const token = await auth.currentUser?.getIdToken();
  let data = {
    text,
    sharedTo: auth.currentUser?.uid,
  };
  let projectSuggestion;
  const projectList: Project[] = [];
  projectSuggestion = await axios.post(`${getAPIUrl()}/project/search`, {
    idToken: token,
    token: process.env.REACT_APP_API_TOKEN,
    data,
  });

  if (projectSuggestion) {
    projectSuggestion.data.projectData.forEach((eachDoc: any) => {
      const eachProject = eachDoc as Project;
      projectList.push(eachProject);
    });
    return projectList;
  }
  return [];
};

export const getProjectName = async (selectedId: string) => {
  if (selectedId) {
    const token = await auth.currentUser?.getIdToken();
    const projectData = await axios.post(`${getAPIUrl()}/project/get`, {
      selectedId: selectedId,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });

    await downloadProject(projectData.data.projectData ?? "");

    if (projectData) {
      return projectData.data.projectData.name;
    } else {
      return selectedId;
    }
  }
};

const downloadProject = async (project: Project) => {
  const projectData = await indexedDb.getValue("local", "projects");
  let projects: Project[] = projectData?.projects ?? [];
  if (
    !projects.some((eachProject) => {
      return eachProject._id === project._id;
    })
  ) {
    projects.push(project);
  } else {
    const target = projects.find((eachProject) => {
      return eachProject._id === project._id;
    });
    if (target) {
      Object.assign(target, project);
    }
  }
  await indexedDb.putValue("local", {
    projects: projects,
    type: "projects",
  });
};

export const getOfflineProjectName = async (projectId: string) => {
  const projectData = await indexedDb.getValue("local", "projects");
  let projects: any[] = projectData?.projects ?? [];
  let projectsName = "";

  projects.map((eachProject: Project) => {
    if (eachProject._id === projectId) {
      projectsName = eachProject.name;
    }
    return null;
  });

  return projectsName;
};

export const getOfflineProjectOption = () => {
  return async (dispatch: any, getState: any) => {
    const projectData = await indexedDb.getValue("local", "projects");
    let projects: any[] = projectData?.projects ?? [];

    let projectOpts: Option[] = [];

    projects.map((eachProject) => {
      projectOpts.push({
        key: eachProject._id,
        title: eachProject.name,
      });
      return null;
    });

    dispatch({
      type: "UPDATE_OFFLINE_PROJECT_OPTIONS",
      payload: {
        projectOpts: projectOpts,
      },
    });
  };
};

export const getOfflineGeoPolygonOption = (projectId: string) => {
  return async (dispatch: any, getState: any) => {
    const projectData = await indexedDb.getValue("local", "projects");
    let projects: any[] = projectData?.projects ?? [];

    let geoListOpts: Option[] = [];

    if (projects.length > 0) {
      projects.map((eachProject) => {
        if (eachProject._id === projectId) {
          eachProject.geoList.map((eachPolygon: any) => {
            geoListOpts.push({
              key: eachPolygon.properties.id,
              title: eachPolygon.properties.name,
            });
            return null;
          });
        }
        return null;
      });
    }

    dispatch({
      type: "UPDATE_OFFLINE_GEO_OPTIONS",
      payload: {
        geoListOpts: geoListOpts,
      },
    });
  };
};

export const clearOfflineGeoPolygon = () => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_OFFLINE_GEO_OPTIONS",
      payload: {
        geoListOpts: [],
      },
    });
  };
};

export const clearOfflineProjectOption = () => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_OFFLINE_PROJECT_OPTIONS",
      payload: {
        projectOpts: [],
      },
    });
  };
};

export const uploadImageStorage = async (id: string, file: File | string) => {
  try {
    if (file && id) {
      let uploadTask: any;
      if (typeof file === "string") {
        const storageRef = ref(storage, `/projects/${id}/signature.png`);
        uploadTask = await uploadString(storageRef, file, "data_url");
      } else {
        const storageRef = ref(storage, `/projects/${id}/map.png`);
        uploadTask = await uploadBytesResumable(storageRef, file);
      }
      const uploadTaskUrl: string = await getDownloadURL(uploadTask.ref);
      const urlParams = new URLSearchParams(uploadTaskUrl);
      const fileToken = urlParams.get("token");
      return fileToken;
    }
  } catch (err) {
    return "";
  }
};

const updateProjectLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_PROJECT_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const getProjectsWithPagination = (search: string, skip: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      let projectListResponse;
      const data = {
        text: search,
        skip: skip * 10,
        sharedTo: auth.currentUser?.uid,
      };

      const token = await auth.currentUser?.getIdToken();
      updateProjectLoadingState(dispatch, true);
      if (search.length > 0) {
        projectListResponse = await axios.post(
          `${getAPIUrl()}/project/search`,
          {
            data,
            idToken: token,
            token: process.env.REACT_APP_API_TOKEN,
          }
        );
      } else {
        projectListResponse = await axios.post(`${getAPIUrl()}/project/list`, {
          data,
          idToken: token,
          token: process.env.REACT_APP_API_TOKEN,
        });
      }

      dispatch({
        type: "UPDATE_PROJECT_LIST",
        payload: {
          projects: projectListResponse.data.projectData,
          size: projectListResponse.data.size,
        },
      });
      updateProjectLoadingState(dispatch, false);
    } catch (err: any) {
      updateProjectLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const getSelectedProject = async (selectedId: string) => {
  try {
    const token = await auth.currentUser?.getIdToken();

    let response = await axios.post(`${getAPIUrl()}/project/get`, {
      selectedId,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    if (response.data && response.data.projectData) {
      return response.data.projectData as Project;
    } else {
      return "Project Not Found";
    }
  } catch (err) {}
};

export const getAllProjectLocation = async (sharedTo: string) => {
  try {
    const token = await auth.currentUser?.getIdToken();
    const projectData = await axios.post(`${getAPIUrl()}/project/getLocation`, {
      data: { sharedTo },
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    return projectData.data;
  } catch (err) {}
};

export const getProjectAnalytics = async (userId: string) => {
  const token = await auth.currentUser?.getIdToken();

  let data = {
    userId,
  };
  const sensorAnalytics = await axios.post(
    `${getAPIUrl()}/analytics/getProject`,
    {
      data,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    }
  );
  return sensorAnalytics;
};

export const createProject = async (projectState: Project) => {
  try {
    const token = await auth.currentUser?.getIdToken();

    const createdProjectResponse = await axios.post(
      `${getAPIUrl()}/project/create`,
      {
        data: projectState,
        idToken: token,
        token: process.env.REACT_APP_API_TOKEN,
      }
    );
    return createdProjectResponse.data.created;
  } catch (err) {
    return "Unknown error, please contact developer if this continues";
  }
};

export const updateProject = async (projectState: Project) => {
  try {
    const token = await auth.currentUser?.getIdToken();

    await axios.post(`${getAPIUrl()}/project/update`, {
      data: projectState,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    return "";
  } catch (err) {
    return "Unknown error, please contact developer if this continues";
  }
};

export const deleteProject = async (selectedId: string) => {
  try {
    const token = await auth.currentUser?.getIdToken();

    await axios.post(`${getAPIUrl()}/project/delete`, {
      selectedId,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const handleCreateProjectCondition = (
  projectStateAttribute: Project,
  projectStateAttributeError: ProjectStateAttributeError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "name":
        if (projectStateAttribute.name.replace(/\s/g, "").length <= 0) {
          projectStateAttributeError["nameError"] =
            "Please enter the project's name";
        } else {
          projectStateAttributeError["nameError"] = "";
        }
        break;
      case "crop":
        if (projectStateAttribute.crop.replace(/\s/g, "").length <= 0) {
          projectStateAttributeError["cropError"] =
            "Please enter the project's crop";
        } else {
          projectStateAttributeError["cropError"] = "";
        }
        break;
      case "location":
        if (
          projectStateAttribute.location.name.replace(/\s/g, "").length <= 0
        ) {
          projectStateAttributeError["locationError"] =
            "Please enter the project's location";
        } else {
          projectStateAttributeError["locationError"] = "";
        }
        break;
    }
    return null;
  });
};

export const handleImageCondition = (
  imageAttribute: ImageAttribute,
  imageAttributeError: ImageError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "east":
        if (Number(imageAttribute.east) === 0) {
          imageAttributeError["eastError"] =
            "Please enter the east coordinates";
        } else {
          imageAttributeError["eastError"] = "";
        }
        break;
      case "west":
        if (Number(imageAttribute.west) === 0) {
          imageAttributeError["westError"] =
            "Please enter the west coordinates";
        } else {
          imageAttributeError["westError"] = "";
        }
        break;
      case "north":
        if (Number(imageAttribute.north) === 0) {
          imageAttributeError["northError"] =
            "Please enter the north coordinates";
        } else {
          imageAttributeError["northError"] = "";
        }
        break;
      case "south":
        if (Number(imageAttribute.south) === 0) {
          imageAttributeError["southError"] =
            "Please enter the south coordinates";
        } else {
          imageAttributeError["southError"] = "";
        }
        break;
      case "image":
        if (imageAttribute.file) {
          const fileExtensionFilter = /.(jpg|jpeg|png)$/;
          const fileSize = imageAttribute.file.size / 1024 / 1024;
          if (!fileExtensionFilter.test(imageAttribute.file.name)) {
            imageAttributeError["fileError"] = "Please upload an image file";
          } else if (fileSize > 150) {
            imageAttributeError["fileError"] = "File size cannot exceed 150MB";
          }
        } else {
          imageAttributeError["fileError"] = "Please upload an image file";
        }
        break;
    }
    return null;
  });
};

export const uploadKML = async (kmlFile: File) => {
  try {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${getAPIUrl()}/project/upload`,
      {
        file: kmlFile,
        idToken: token,
        token: process.env.REACT_APP_API_TOKEN,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (err: any) {
    return err.message;
  }
};
