import {
  EyeIcon,
  PencilIcon,
  StatusOfflineIcon,
  StatusOnlineIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import Banner from "../components/base/Banner";
import Button from "../components/base/Button";
import ConfirmationModal from "../components/base/ConfirmationModal";
import Input from "../components/base/Input";
import Overlay from "../components/base/Overlay";
import Select from "../components/base/Select";
import SmartSelect from "../components/base/SmartSelect";
import Table, { TableHeader } from "../components/base/Table";
import TasksModal, { TaskType } from "../components/dashboard/TaskModal";
import { Task } from "../components/model/Task";
import { withRouter } from "../navigator/NavigateWrapper";
import {
  getOfflineProjectName,
  getOfflineProjectOption,
  getProjectSuggestion,
} from "../store/actions/projectActions";
import {
  deleteTask,
  downloadTasksByProject,
  resyncTasks,
} from "../store/actions/tasksActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { indexedDb } from "../utils/indexedDB";

interface Props {
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
  getOfflineProjectOption: () => void;
}

interface State {
  loading: boolean;
  loadingText: string;
  tableLoading: boolean;
  type: TaskType;
  visible: boolean;
  searchText: string;
  projectId: string;
  projectName: string;
  selectedTask: Task;
  tasks: Task[];
  deleteTaskAttribute: {
    _id: string;
    purpose: string;
  };
}

const dummyTask: Task = {
  _id: "",
  date: moment().toDate(),
  location: "",
  manPower: 0,
  purpose: "",
  droneModel: "XAGR150A",
  droneModelOther: "",
  mode: "SPRAY",
  modeOther: "",
  flightSpeed: "",
  flightHeight: 0,
  dosage: 0,
  atomization: 0,
  routeInterval: 0,
  boundarySafetyDistance: 0,
  obstacleSafetyDistance: 0,
  areaCover: 0,
  batteryUsed: 0,
  chargingCycle: 0,
  totalChemicalUsed: 0,
  typeOfChemicalUsed: "",
  operationHours: "",
  flightTime: "",
  totalDownTime: 0,
  limitation: "",
  conclusion: "",
  recommendation: "",
  attachmentToken: "",
  createdBy: "",
  completed: false,
  projectId: "",
  polygonId: [],
};

const initTaskAttribute = () => {
  const clonedTaskAttribute = JSON.parse(JSON.stringify(dummyTask));
  dummyTask["date"] = moment().toDate();
  return clonedTaskAttribute;
};

class Tasks extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;
  state: State = {
    loading: false,
    loadingText: "",
    tableLoading: true,
    type: TaskType.VIEW,
    visible: false,
    searchText: "",
    projectId: "",
    projectName: "",
    selectedTask: initTaskAttribute(),
    tasks: [],
    deleteTaskAttribute: {
      _id: "",
      purpose: "",
    },
  };

  componentDidMount = async () => {
    this.handleOnlineOfflineActions();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      JSON.stringify(prevProps.authStore.user) !==
        JSON.stringify(this.props.authStore.user) ||
      prevProps.utilsStore.onlineStatus !== this.props.utilsStore.onlineStatus
    ) {
      this.handleOnlineOfflineActions();
    }
  };

  componentWillUnmount = () => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = null;
    }
  };

  handleOnlineOfflineActions = async () => {
    if (!this.props.utilsStore.onlineStatus) {
      this.props.getOfflineProjectOption();
      const taskData = await indexedDb.getValue("local", "tasks");
      this.setState({
        tasks: taskData?.tasks ?? [],
        tableLoading: false,
      });
    } else {
      await this.handleUploadOfflineData();
      this.handleGetPreviewData();
    }
  };

  handleUploadOfflineData = async () => {
    this.handleUpdateLoading(
      true,
      "Please wait while we are processing your offline request"
    );
    await resyncTasks();
    this.handleUpdateLoading(false, "");
  };

  handleGetPreviewData = async (selectedProjectId?: string) => {
    if (this.props.authStore.user) {
      let projectId = "";
      if (selectedProjectId) {
        projectId = selectedProjectId;
      } else {
        const projectList = await getProjectSuggestion("");
        if (projectList.length > 0) {
          projectId = projectList[0]._id;
        }
      }

      this.setState({ projectId }, async () => {
        if (projectId) {
          if (this.props.utilsStore.onlineStatus) {
            await downloadTasksByProject(projectId);
          }
          this.handleCurrentProjectName();

          const taskData = await indexedDb.getValue("local", "tasks");
          this.setState({
            tasks: taskData?.tasks ?? [],
            tableLoading: false,
          });
        }
      });
    }
  };

  handleProjectChange = (id: string, value: string | string[]) => {
    this.setState(
      {
        [id]: value,
      },
      async () => {
        this.handleGetPreviewData(this.state.projectId);
        this.handleCurrentProjectName();
      }
    );
  };

  handleFilter = (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
    });
  };

  handleModalVisible = (visible: boolean, type: TaskType) => {
    this.setState(
      {
        visible,
        type,
        selectedTask: initTaskAttribute(),
      },
      async () => {
        if (this.state.type === TaskType.REFRESH) {
          this.handleGetPreviewData(this.state.projectId);
          const taskData = await indexedDb.getValue("local", "tasks");
          this.setState({
            tasks: taskData?.tasks ?? [],
            tableLoading: false,
          });
        }
      }
    );
  };

  handleUpdateLoading = (loading: boolean, loadingText: string) => {
    this.setState({
      loading,
      loadingText,
    });
  };

  handleRemoveTask = async () => {
    this.handleUpdateLoading(
      true,
      "Please wait while we are removing your task"
    );
    const deleteSensorResponse = await deleteTask(
      this.state.deleteTaskAttribute._id
    );

    if (!deleteSensorResponse) {
      await indexedDb.deleteValue("local", "tasks");
      this.handleGetPreviewData();
    } else {
      window.alert("Unable to delete. Please try again later");
    }
    this.handleUpdateLoading(false, "");
  };

  handleUpdateDeleteTaskAttribute = (deleteTaskAttribute: {
    _id: string;
    purpose: string;
  }) => {
    this.setState({
      deleteTaskAttribute,
    });
  };

  handleUpdateSelectedTask = (
    visible: boolean,
    selectedTask: Task,
    type: TaskType
  ) => {
    this.setState({
      visible,
      selectedTask,
      type,
    });
  };

  handleCurrentProjectName = async () => {
    let name = await getOfflineProjectName(this.state.projectId);
    this.setState({ projectName: name });
  };

  renderFilteredData = () => {
    const taskList: Object[] = [];
    if (this.state.tasks.length > 0) {
      let filteredList = this.state.tasks;
      if (this.state.searchText) {
        const searchText = this.state.searchText.toUpperCase().trim();
        filteredList = filteredList.filter((eachTask: Task) => {
          return (
            eachTask.location.toUpperCase().includes(searchText) ||
            eachTask.mode.toUpperCase().includes(searchText) ||
            eachTask.droneModel.toUpperCase().includes(searchText)
          );
        });
      }
      filteredList.map((eachTask) => {
        if (eachTask.projectId === this.state.projectId) {
          taskList.push({
            id: eachTask._id,
            date: moment(eachTask.date).format("DD/MM/YYYY"),
            location: eachTask.location,
            purpose: eachTask.purpose,
            area: eachTask.areaCover,
            completed: eachTask.completed.toString(),
            view: (
              <div className="cursor-pointer ml-2">
                <EyeIcon
                  className="h-5 w-5 block text-green-600"
                  onClick={this.handleUpdateSelectedTask.bind(
                    this,
                    true,
                    eachTask,
                    TaskType.VIEW
                  )}
                />
              </div>
            ),
            edit: (
              <div className="cursor-pointer ml-2">
                <PencilIcon
                  className="h-5 w-5 block text-green-600"
                  onClick={this.handleUpdateSelectedTask.bind(
                    this,
                    true,
                    eachTask,
                    TaskType.UPDATE
                  )}
                />
              </div>
            ),
            delete: (
              <div className="cursor-pointer ml-2">
                <TrashIcon
                  className="h-5 w-5 block text-green-600"
                  onClick={this.handleUpdateDeleteTaskAttribute.bind(this, {
                    _id: eachTask._id,
                    purpose: eachTask.purpose,
                  })}
                />
              </div>
            ),
          });
        }
        return "";
      });
    }

    return taskList;
  };

  renderBannerText = () => {
    switch (this.props.utilsStore.onlineStatus) {
      case true:
        return this.state.projectId
          ? `Re-synced project(${this.state.projectName}) & tasks for offline usages`
          : `Select a project to use during offline mode.`;
      case false:
        return "You are currently in offline mode.";
      default:
        return "";
    }
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "date",
        title: "Date",
      },
      {
        key: "location",
        title: "Location",
      },
      {
        key: "purpose",
        title: "Purpose",
      },
      {
        key: "area",
        title: `Area`,
      },
      {
        key: "completed",
        title: "Completed",
      },
      {
        key: "view",
        title: "View",
      },
      {
        key: "edit",
        title: "Edit",
      },
      {
        key: "delete",
        title: "Delete",
      },
    ];

    if (!this.props.utilsStore.onlineStatus) {
      headers.pop();
    }

    const headerComponent = {
      title: "List of Tasks",
      desc: "Order from latest creation date",
      buttonText: "Add Tasks",
      buttonOnClick: () => {
        this.handleModalVisible(true, TaskType.CREATE);
      },
    };

    const onlineStatus = this.props.utilsStore.onlineStatus;
    return (
      <>
        <ConfirmationModal
          content={`Are you sure that you want to delete the task at ${this.state.deleteTaskAttribute.purpose} ?`}
          onClick={this.handleRemoveTask}
          onClose={this.handleUpdateDeleteTaskAttribute.bind(this, {
            _id: "",
            purpose: "",
          })}
          title="Delete Task Confirmation"
          open={this.state.deleteTaskAttribute._id ? true : false}
        />
        <TasksModal
          type={this.state.type}
          selectedTask={this.state.selectedTask}
          visible={this.state.visible}
          onClose={this.handleModalVisible}
        />
        <Overlay loading={this.state.loading} text={this.state.loadingText} />
        <div className="mt-8">
          <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
            <div className="mt-5">
              <div className="flex flex-col py-2 md:items-center md:flex-row">
                <div className="flex flex-col">
                  <p className="text-xl font-medium">{headerComponent.title}</p>
                  <span className="text-sm text-gray-500">
                    {headerComponent.desc}
                  </span>
                </div>
                <div className="grow" />
                {this.props.utilsStore.onlineStatus ? (
                  <div className="mt-3 md:mt-0 md:ml-3">
                    <SmartSelect
                      id="projectId"
                      placeholder="Project Name"
                      value={this.state.projectId}
                      onChange={this.handleProjectChange}
                    />
                  </div>
                ) : (
                  <div className="w-full mt-3 md:mt-0 md:ml-3 sm:w-44">
                    <Select
                      id="projectId"
                      options={this.props.utilsStore.projectOpts}
                      value={this.state.projectId}
                      onChange={this.handleProjectChange}
                    />
                  </div>
                )}
                <div className="mt-3 md:mt-0 md:ml-3">
                  <Input placeholder="Search" onChange={this.handleFilter} />
                </div>
                <Button
                  className="my-3 md:my-0 md:ml-3"
                  text={headerComponent.buttonText}
                  type="normal"
                  onClick={headerComponent.buttonOnClick}
                />
              </div>
            </div>

            <Banner
              text={this.renderBannerText()}
              icon={
                onlineStatus ? (
                  <StatusOnlineIcon className="h-5 w-5 text-white" />
                ) : (
                  <StatusOfflineIcon className="h-5 w-5 text-white" />
                )
              }
            />
            <Table
              loading={this.state.tableLoading}
              header={headers}
              pageIndex={0}
              size={this.renderFilteredData().length}
              data={this.renderFilteredData()}
              hidePagination={true}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getOfflineProjectOption: () => dispatch(getOfflineProjectOption()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tasks));
