import { combineReducers } from "redux";
import authReducer from "./authReducer";
import projectReducer from "./projectReducer.ts";
import sensorReducer from "./sensorReducer";
import userReducer from "./usersReducer";
import utilsReducer from "./utilsReducer";

const rootReducer = combineReducers({
  authStore: authReducer,
  userStore: userReducer,
  sensorStore: sensorReducer,
  projectStore: projectReducer,
  utilsStore: utilsReducer,
});

export default rootReducer;
