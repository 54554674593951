import { VideoCameraIcon } from "@heroicons/react/outline";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import LineChart from "../components/base/LineChart";
import { DashboardPage } from "../components/dashboard/LeftNav";
import {
  Sensor,
  SensorAnalyticsType,
  SensorStatus,
} from "../components/model/Sensor";
import { withRouter } from "../navigator/NavigateWrapper";
import { getSelectedSensors } from "../store/actions/sensorActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { items } from "../utils";
import IotIconImg from "./../images/icon_iot.png";

interface Props {
  navigate: any;
  page: DashboardPage;
  authStore: authStateInterface;
}

interface State {
  sensor: Sensor;
}

class SensorDetail extends Component<Props> {
  state: State = {
    sensor: {
      _id: "",
      tag: "",
      code: "",
      name: "",
      crop: "",
      location: {
        lat: 0,
        lng: 0,
        name: "",
      },
      type: "SOIL",
      status: "INIT",
      createdAt: moment().toDate(),
      sharedTo: "",
    },
  };
  componentDidMount = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("id");
    if (!selectedId) {
      this.props.navigate("/dashboard/weather");
    } else {
      const sensorData = await getSelectedSensors(selectedId);
      if (!sensorData) {
        this.props.navigate("/dashboard/weather");
      } else {
        if (typeof sensorData === "string") {
          this.props.navigate("/dashboard/weather");
        } else {
          this.setState({
            sensor: sensorData,
          });
        }
      }
    }
  };

  renderPageDetail = () => {
    let sensorType: keyof typeof SensorAnalyticsType = "WEATHER";
    if (this.props.page === DashboardPage.SOILFERTILITYDETAIL) {
      sensorType = "SOIL";
    }
    return sensorType;
  };

  renderSensorSpecs = () => {
    let sensorSpecs: JSX.Element[] = [];
    items.map((eachItems) => {
      if (eachItems.code === this.state.sensor.code) {
        Object.keys(eachItems.specs).map((eachSpecs) => {
          sensorSpecs.push(
            <div className="flex flex-row">
              {eachSpecs}
              <div className="flex-1" />
              {eachItems.specs[eachSpecs]}
            </div>
          );
          return null;
        });

        sensorSpecs.push(
          <img className="object-contain" src={eachItems.image} alt="" />
        );
        return null;
      }
      return null;
    });

    return sensorSpecs;
  };

  render() {
    const sensorType = this.renderPageDetail();

    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:space-x-4">
            <div className="flex flex-col item-center">
              <div className="bg-white flex-1 shadow rounded-lg w-full sm:w-80 py-2 px-4 space-y-4">
                <div className="flex flex-row items-center justify-center">
                  <img className="h-5 w-5 mr-2" src={IotIconImg} alt="" />
                  Specifications - {this.state.sensor.name}
                  <div className="flex-1" />
                </div>
                <div className="flex flex-row">
                  Code
                  <div className="flex-1" />
                  {this.state.sensor.code}
                </div>
                {this.renderSensorSpecs()}
              </div>
              <div className="bg-white shadow rounded-lg w-full sm:w-80 py-2 px-4 space-y-4 mt-5">
                <div className="flex flex-row items-center justify-center">
                  <VideoCameraIcon className="h-5 w-5 mr-2" />
                  Details
                  <div className="flex-1" />
                </div>
                <div className="flex flex-row">
                  Location
                  <div className="flex-1" />
                  <p className="text-right">
                    {this.state.sensor.location.name}
                  </p>
                </div>
                <div className="flex flex-row">
                  GPS
                  <div className="flex-1" />({this.state.sensor.location.lat},
                  {this.state.sensor.location.lng})
                </div>
                <div className="flex flex-row">
                  Status
                  <div className="flex-1" />
                  {SensorStatus[this.state.sensor.status]}
                </div>
                <div className="flex flex-row">
                  Crop
                  <div className="flex-1" />
                  {this.state.sensor.crop}
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-white shadow rounded-lg mt-5 sm:mt-0 sm:w-full">
              <div className="flex flex-row border-b w-full p-4 pt-2">
                <div>
                  <div className="text-xl font-medium">
                    {sensorType === "SOIL" ? "Soil" : "Weather"} Sensor
                    Statistics
                  </div>
                </div>
              </div>
              <LineChart type={sensorType} page={"details"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(withRouter(SensorDetail));
