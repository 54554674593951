import React, { Component } from "react";

interface Props {
  id?: string;
  text: string;
  disable?: boolean;
  className?: string;
  onClick?: (e: any) => void;
}

export default class TextButton extends Component<Props> {
  render() {
    let defaultClassName =
      "rounded-md font-medium text-emerald-500 hover:text-emerald-600 focus:outline-none";
    if (this.props.className) {
      defaultClassName += ` ${this.props.className}`;
    }

    if (this.props.disable) {
      defaultClassName += " opacity-50 cursor-not-allowed";
    }

    return (
      <button
        id={this.props.id}
        type="button"
        className={defaultClassName}
        onClick={this.props.onClick}
      >
        {this.props.text}
      </button>
    );
  }
}
