import { SensorAnalyticsType } from "../components/model/Sensor";
import SPHT from "./images/sensor_PHT.png";
import SWST from "./images/sensor_SWST.png";
import STHT from "./images/sensor_THT.png";
import WRT from "./images/sensor_WRT.png";
import WTHT from "./images/sensor_WTHT.png";

export interface Items {
  code: string;
  name: string;
  image: string;
  type: keyof typeof SensorAnalyticsType;
  specs: {
    [key: string]: string;
  };
  unit: string[];
}

export const items: Items[] = [
  {
    code: "S-THT",
    name: "Soil Temperature & Humidity Transmitter",
    image: STHT,
    type: "SOIL",
    specs: {
      "Power Supply Voltage": "12V~24V DC 1A",
      Power: "<0.4W",
      "Levels of Protection": "IP68",
      "Temperature Range": "-40°C-85°C",
      "Temperature Accuracy": "±0.5°C",
      "Soil Moisture Content Range": "0~100% R",
    },
    unit: ["soilTemperature", "soilHumidity"],
  },
  {
    code: "S-PHT",
    name: "Soil PH Transmitter",
    image: SPHT,
    type: "SOIL",
    specs: {
      "DC Power Supply": "9-24V DC",
      "Electricity Consumption": "≤0.15W",
      "Certainty of Measurement": "±0.5pH",
      "PH Measurement Range": "0-14 pH",
    },
    unit: ["soilPH"],
  },
  {
    code: "W-THT",
    name: "Temperature & Humidity Transmitter",
    image: WTHT,
    type: "WEATHER",
    specs: {
      "Power Supply Voltage": "12V~24V DC 1A",
      Power: "<0.4W",
      "Levels of Protection": "IP67",
      "Temperature Range": "-25°C-85°C",
      "Humidity Range": "0~100% RH",
    },
    unit: ["temperature", "humidity"],
  },
  {
    code: "W-RT",
    name: "Rainfall Transmitter",
    image: WRT,
    type: "WEATHER",
    specs: {
      "Supply Electricity": "7-24V DC",
      "Rain Caliber": "Φ200mm",
      "Measuring Range": "≤30mm/min",
      "Resolution Ratio": "0.2mm",
      Error: "±2%",
    },
    unit: ["rainfall"],
  },
  {
    code: "W-SWST",
    name: "Small Weather Station",
    image: SWST,
    type: "WEATHER",
    specs: {
      "Temperature Range": "-40-80 °C",
      "Humidity Range": "0-100 %RH",
      "Wind Speed": "0-60 m/s",
      "Wind Direction": "16 directions",
      "Atmospheric Pressure": "10-1200 mbar",
      Illuminance: "0-200000 Lux",
    },
    unit: [
      "temperature",
      "humidity",
      "windSpeed",
      "windDirection",
      "airPressure",
      "totalSolarRadiation",
    ],
  },
];
