import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Logs from "../pages/Logs";
import {
  getAuthListener,
  removeAuthListener,
} from "../store/actions/authActions";
import { updateOnlineStatus } from "../store/actions/utilsActions";
import { indexedDb } from "../utils/indexedDB";

declare global {
  interface Date {
    seconds: number;
  }
  interface Window {
    google: any;
  }
}

interface Props {
  getAuthListener: () => void;
  removeAuthListener: () => void;
  updateOnlineStatus: (status: boolean) => void;
}

class Navigator extends Component<Props> {
  componentDidMount = async () => {
    let mapsScript = document.getElementById("googleMaps");

    if (!mapsScript) {
      const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
      const mapAPIKey = isProd
        ? process.env.REACT_APP_PROD_MAP_API
        : process.env.REACT_APP_DEV_MAP_API;

      const mapScript = document.createElement("script");
      mapScript.id = "googleMaps";
      mapScript.type = "text/javascript";
      mapScript.src = `https://maps.googleapis.com/maps/api/js?key=${mapAPIKey}&libraries=places,drawing,geometry`;

      const head = document.getElementsByTagName("head")[0];
      if (head) {
        document.head.appendChild(mapScript);
      }
    }

    this.props.getAuthListener();
    window.addEventListener("online", this.handleOnlineStatus.bind(this, true));
    window.addEventListener(
      "offline",
      this.handleOnlineStatus.bind(this, false)
    );

    await indexedDb.createObjectStore(["local"]);
  };

  componentWillUnmount() {
    this.props.removeAuthListener();
    window.removeEventListener(
      "online",
      this.handleOnlineStatus.bind(this, true)
    );
    window.removeEventListener(
      "offline",
      this.handleOnlineStatus.bind(this, false)
    );
  }

  handleOnlineStatus = (online: boolean) => {
    this.props.updateOnlineStatus(online);
  };

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAuthListener: () => dispatch(getAuthListener()),
    removeAuthListener: () => dispatch(removeAuthListener()),
    updateOnlineStatus: (status: boolean) =>
      dispatch(updateOnlineStatus(status)),
  };
};

export default connect(null, mapDispatchToProps)(Navigator);
