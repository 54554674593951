import { Dialog, Transition } from "@headlessui/react";
import React, { Component, Fragment } from "react";
import {
  handleImageCondition,
  uploadImageStorage,
} from "../../store/actions/projectActions";
import { authStateInterface } from "../../store/reducers/authReducer";
import Button from "../base/Button";
import Input from "../base/Input";
import Overlay from "../base/Overlay";
import UploadInput from "../base/UploadInput";
import { Image, Project } from "../model/Project";

interface Props {
  visible: boolean;
  authStore: authStateInterface;
  projectStateAttribute: Project;
  onClose: (bool: boolean) => void;
  handleSubmit: (id: string, value: Image) => void;
}

export interface ImageAttribute extends Image {
  file: File | "";
}

export interface ImageError {
  fileError: string;
  eastError: string;
  westError: string;
  northError: string;
  southError: string;
}

interface State {
  loading: boolean;
  image: ImageAttribute;
  imageError: ImageError;
}

export default class UploadImageModal extends Component<Props> {
  state: State = {
    loading: false,
    image: {
      file: "",
      token: "",
      east: 0,
      north: 0,
      south: 0,
      west: 0,
    },
    imageError: {
      fileError: "",
      eastError: "",
      westError: "",
      northError: "",
      southError: "",
    },
  };

  componentDidMount = () => {
    if (this.props.projectStateAttribute.image) {
      this.setState({
        image: this.props.projectStateAttribute.image,
      });
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      JSON.stringify(this.props.projectStateAttribute.image) !==
      JSON.stringify(prevProps.projectStateAttribute.image)
    ) {
      this.setState({
        image: this.props.projectStateAttribute.image,
      });
    }
  };

  handleCloneImageAttribute = () => {
    const clonedImage = JSON.parse(JSON.stringify(this.state.image));
    clonedImage["file"] = this.state.image.file;
    return clonedImage;
  };

  handleOnChange = (e: any) => {
    const clonedImage = this.handleCloneImageAttribute();
    clonedImage[e.target.id] = e.target.value;
    this.setState({
      image: clonedImage,
    });
  };

  handleUploadChange = (e: any) => {
    const clonedImage = this.handleCloneImageAttribute();
    clonedImage["file"] = e.target.files[0];
    this.setState({
      image: clonedImage,
    });
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleSubmit = () => {
    this.handleLoading(true);
    const conditionsList: string[] = ["file", "north", "south", "east", "west"];
    const clonedImageError = JSON.parse(JSON.stringify(this.state.imageError));
    handleImageCondition(this.state.image, clonedImageError, conditionsList);

    this.setState(
      {
        imageError: clonedImageError,
      },
      async () => {
        if (
          !this.state.imageError.eastError &&
          !this.state.imageError.westError &&
          !this.state.imageError.northError &&
          !this.state.imageError.southError &&
          !this.state.imageError.fileError
        ) {
          let fileUrl;
          if (this.state.image.file) {
            fileUrl = await uploadImageStorage(
              this.props.projectStateAttribute._id,
              this.state.image.file
            );
          } else {
            fileUrl = this.props.projectStateAttribute.image?.token;
          }

          if (fileUrl) {
            const imageAttribute: Image = {
              token: fileUrl,
              east: this.state.image.east,
              north: this.state.image.north,
              south: this.state.image.south,
              west: this.state.image.west,
            };
            this.props.handleSubmit("image", imageAttribute);
            this.props.onClose(false);
          }
        }
        this.handleLoading(false);
      }
    );
  };

  render() {
    return (
      <Transition.Root show={this.props.visible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={this.props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Overlay
            loading={this.state.loading}
            text="Please wait while we are uploading the image"
          />
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-8 pt-5 pb-4 sm:pb-4">
                    <div className="mt-2 text-center sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-bold text-gray-900"
                      >
                        Upload Map Overlay
                      </Dialog.Title>
                    </div>

                    <div className="flex flex-col space-y-2 mt-5">
                      <UploadInput
                        accept=".png,.jpg,.jpeg"
                        file={this.state.image.file}
                        error={this.state.imageError.fileError}
                        onChange={this.handleUploadChange}
                      />

                      <div className="flex flex-col flex-1">
                        <span className="w-36 inline-flex items-center text-md text-gray-800">
                          {"West Boundary (←)"}
                        </span>
                        <Input
                          id="west"
                          placeholder="LatLngBounds (eg 12.5)"
                          value={this.state.image.west.toString()}
                          error={this.state.imageError.westError}
                          onChange={this.handleOnChange}
                        />

                        <span className="w-36 mt-1 inline-flex items-center text-md text-gray-800">
                          {"North Boundary (↑)"}
                        </span>
                        <Input
                          id="north"
                          placeholder="LatLngBounds (eg 12.5)"
                          value={this.state.image.north.toString()}
                          error={this.state.imageError.northError}
                          onChange={this.handleOnChange}
                        />

                        <span className="w-36 mt-1 inline-flex items-center text-md text-gray-800">
                          {"East Boundary (→)"}
                        </span>
                        <Input
                          id="east"
                          placeholder="LatLngBounds (eg 12.5)"
                          value={this.state.image.east.toString()}
                          error={this.state.imageError.eastError}
                          onChange={this.handleOnChange}
                        />

                        <span className="w-40 mt-1 inline-flex items-center text-md text-gray-800">
                          {"South Boundary (↓)"}
                        </span>
                        <Input
                          id="south"
                          placeholder="LatLngBounds (eg 12.5)"
                          value={this.state.image.south.toString()}
                          error={this.state.imageError.southError}
                          onChange={this.handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <Button
                      text="Upload"
                      type="normal"
                      onClick={this.handleSubmit}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
