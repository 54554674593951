import { DateRange } from "../../components/base/LineChart";
import { Sensor } from "../../components/model/Sensor";

export interface sensorStateInterface {
  sensors: Sensor[];
  size: number;
  loading: boolean;
  dateRange: keyof typeof DateRange;
  range: {
    startDate: Date;
    endDate: Date;
  } | null;
}

const initState: sensorStateInterface = {
  sensors: [],
  size: 0,
  loading: false,
  dateRange: "SEVEN",
  range: null,
};

const sensorReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_SENSOR_LIST":
      return {
        ...state,
        sensors: action.payload.sensors,
        size: action.payload.size,
      };
    case "UPDATE_SENSOR_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "UPDATE_SENSOR_DATE_RANGE":
      return {
        ...state,
        dateRange: action.payload.dateRange,
      };
    case "UPDATE_SENSOR_CUSTOM_DATE":
      return {
        ...state,
        range: action.payload.range,
      };
    default:
      return state;
  }
};

export default sensorReducer;
