import { IDBPDatabase, openDB } from "idb";

class IndexedDb {
  private database: string;
  private db: any;

  constructor(database: string) {
    this.database = database;
  }

  public async createObjectStore(tableNames: string[]) {
    this.db = await openDB(this.database, 1, {
      upgrade(db: IDBPDatabase) {
        for (const tableName of tableNames) {
          if (db.objectStoreNames.contains(tableName)) {
            continue;
          }
          db.createObjectStore(tableName, {
            autoIncrement: true,
            keyPath: "type",
          });
        }
      },
    });
  }

  public async getValue(tableName: string, id: string) {
    try {
      const tx = this.db.transaction(tableName, "readonly");
      const store = tx.objectStore(tableName);
      const result = await store.get(id);
      return result;
    } catch (err) {
      return "Error";
    }
  }

  public async putValue(tableName: string, value: object) {
    try {
      const tx = this.db.transaction(tableName, "readwrite");
      const store = tx.objectStore(tableName);
      await store.put(value);
    } catch (err) {
      return "Error";
    }
  }

  public async deleteValue(tableName: string, id: string) {
    try {
      const tx = this.db.transaction(tableName, "readwrite");
      const store = tx.objectStore(tableName);
      const result = await store.get(id);
      if (!result) {
        return result;
      }
      await store.delete(id);
      return id;
    } catch (err) {
      return "Error";
    }
  }
}

export const indexedDb = new IndexedDb("local");
