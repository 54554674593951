import axios from "axios";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import {
  TaskErrorState,
  TaskState,
} from "../../components/dashboard/TaskModal";
import { Task } from "../../components/model/Task";
import { GeoJSONAttribute } from "../../pages/CreateProject";
import { getAPIUrl } from "../../utils/database";
import { auth, storage } from "../../utils/firebase";
import { indexedDb } from "../../utils/indexedDB";
import { getSelectedProject, updateProject } from "./projectActions";

export const downloadTasksByProject = async (projectId: string) => {
  let taskListResponse;
  const token = await auth.currentUser?.getIdToken();

  let data = {
    createdBy: auth.currentUser?.uid,
    projectId,
  };

  taskListResponse = await axios.post(`${getAPIUrl()}/task/list`, {
    data,
    idToken: token,
    token: process.env.REACT_APP_API_TOKEN,
  });

  if (taskListResponse.data.taskData.length > 0) {
    const taskData = await indexedDb.getValue("local", "tasks");
    let tasks: any[] = taskData?.tasks ?? [];
    let newTasks: any = [];
    if (tasks && tasks.length > 0) {
      taskListResponse.data.taskData.map((eachTask: any) => {
        if (
          tasks.some((task) => {
            return task._id === eachTask._id;
          })
        ) {
          tasks.splice(
            tasks.findIndex((x) => x._id === eachTask._id),
            1
          );
          newTasks.push(eachTask);
        } else {
          newTasks.push(eachTask);
        }
        return null;
      });
    } else {
      newTasks = taskListResponse.data.taskData;
    }

    await indexedDb.putValue("local", {
      tasks: tasks.concat(newTasks),
      type: "tasks",
    });
  }
};

export const resyncTasks = async () => {
  try {
    const newTaskData = await indexedDb.getValue("local", "update");
    const tasksData = await indexedDb.getValue("local", "tasks");
    const imagesData = await indexedDb.getValue("local", "images");

    if (newTaskData && newTaskData?.id.length > 0) {
      await Promise.all(
        newTaskData.id.map(async (eachTaskId: string) => {
          const taskAttribute = tasksData.tasks.find(
            (x: Task) => x._id === eachTaskId
          );
          const imageAttribute = imagesData?.images.find((x: any) => {
            return x.id === eachTaskId;
          });

          if (taskAttribute) {
            if (imageAttribute) {
              const fileUrl = await uploadImageStorage(
                eachTaskId,
                imageAttribute.dataURL
              );

              taskAttribute["attachmentToken"] = fileUrl ?? "";
            }
            const isTask = await getTask(eachTaskId);
            isTask
              ? await updateTask(taskAttribute)
              : await createTask(taskAttribute);
          }
        })
      );

      await indexedDb.deleteValue("local", "images");
      await indexedDb.deleteValue("local", "update");
    }
  } catch (err) {}
};

export const uploadImageStorage = async (id: string, file: File | string) => {
  try {
    let fileToken;
    if (file && id) {
      let uploadTask: any;
      if (typeof file === "string") {
        const storageRef = ref(storage, `/tasks/${id}/preview.png`);
        uploadTask = await uploadString(storageRef, file, "data_url");
      } else {
        const storageRef = ref(storage, `/tasks/${id}/preview.png`);
        uploadTask = await uploadBytesResumable(storageRef, file);
      }
      const uploadTaskUrl: string = await getDownloadURL(uploadTask.ref);
      const urlParams = new URLSearchParams(uploadTaskUrl);
      fileToken = urlParams.get("token");
    }
    return fileToken;
  } catch (err) {
    return "";
  }
};

export const getTask = async (selectedId: string) => {
  try {
    const token = await auth.currentUser?.getIdToken();
    const taskData = await axios.post(`${getAPIUrl()}/task/get`, {
      selectedId: selectedId,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    if (taskData) {
      return taskData.data.taskData;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const createTask = async (taskState: Task) => {
  try {
    const token = await auth.currentUser?.getIdToken();

    const createdTaskResponse = await axios.post(`${getAPIUrl()}/task/create`, {
      data: taskState,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    return createdTaskResponse.data.created;
  } catch (err) {
    return "Unknown error, please contact developer if this continues";
  }
};

export const updateTask = async (taskState: Task) => {
  try {
    const token = await auth.currentUser?.getIdToken();

    await axios.post(`${getAPIUrl()}/task/update`, {
      data: taskState,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });

    if (taskState.completed) {
      const projectData = await getSelectedProject(taskState.projectId);
      if (projectData && typeof projectData !== "string") {
        const clonedNewProjectData = JSON.parse(JSON.stringify(projectData));
        clonedNewProjectData.geoList.map((eachGeoList: GeoJSONAttribute) => {
          if (taskState.polygonId.includes(eachGeoList.properties.id)) {
            eachGeoList.properties.color = "BLUE";
          }
          return null;
        });
        await updateProject(clonedNewProjectData);
      }
    }

    return "";
  } catch (err) {
    return "Unknown error, please contact developer if this continues";
  }
};

export const updateTaskOffline = async (taskStore: any) => {
  try {
    delete taskStore["file"];
    const taskData = await indexedDb.getValue("local", "tasks");
    let tasks: any[] = taskData?.tasks ?? [];
    let newTask: Task[] = [];

    if (
      tasks.some((offline) => {
        return offline._id === taskStore._id;
      })
    ) {
      tasks.map((eachTask) => {
        newTask.push(eachTask._id === taskStore._id ? taskStore : eachTask);
        return "";
      });
    } else {
      tasks.push(taskStore);
      newTask = tasks;
    }

    await indexedDb.putValue("local", {
      tasks: newTask,
      type: "tasks",
    });

    const updateTaskData = await indexedDb.getValue("local", "update");
    let updateId: any[] = updateTaskData?.id ?? [];

    if (!updateId.includes(taskStore._id)) {
      updateId.push(taskStore._id);
    }

    await indexedDb.putValue("local", {
      id: updateId,
      type: "update",
    });
  } catch (err) {
    return "Unknown error, please contact developer if this continues";
  }
};

export const getLinkedTaskList = async (
  projectId: string,
  polygonId?: string
) => {
  try {
    const token = await auth.currentUser?.getIdToken();
    const apiQuery: any = {
      projectId,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    };
    if (polygonId) {
      apiQuery["polygonId"] = polygonId;
    }
    const taskList = await axios.post(
      `${getAPIUrl()}/task/linkedTask`,
      apiQuery
    );
    return taskList.data.taskData;
  } catch (err) {
    return [];
  }
};

export const deleteTask = async (selectedId: string) => {
  try {
    const token = await auth.currentUser?.getIdToken();

    await axios.post(`${getAPIUrl()}/task/delete`, {
      selectedId,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const handleCreateTaskCondition = (
  taskState: TaskState,
  taskError: TaskErrorState,
  typeList: string[]
) => {
  //eslint-disable-next-line
  const numberRegEx = /\-?\d*\.?\d{1,2}/;
  typeList.map((eachType) => {
    switch (eachType) {
      case "projectId":
        if (taskState.projectId.replace(/\s/g, "").length <= 0) {
          taskError["projectError"] = "Please select a project";
        } else {
          taskError["projectError"] = "";
        }
        break;
      case "polygonId":
        if (taskState.polygonId.length <= 0) {
          taskError["polygonError"] = "Please select a polygon";
        } else {
          taskError["polygonError"] = "";
        }
        break;
      case "location":
        if (taskState.location.replace(/\s/g, "").length <= 0) {
          taskError["locationError"] = "Please enter the location";
        } else {
          taskError["locationError"] = "";
        }
        break;
      case "purpose":
        if (taskState.purpose.replace(/\s/g, "").length <= 0) {
          taskError["purposeError"] = "Please enter the purpose";
        } else {
          taskError["purposeError"] = "";
        }
        break;
      case "droneModelOther":
        if (taskState.droneModel === "OTHER") {
          if (
            typeof taskState.droneModelOther === "string" &&
            taskState.droneModelOther.replace(/\s/g, "").length <= 0
          ) {
            taskError["droneOtherError"] =
              "Please enter the model of the drone";
          } else {
            taskError["droneOtherError"] = "";
          }
        }
        break;
      case "modeOther":
        if (taskState.mode === "OTHER") {
          if (
            typeof taskState.modeOther === "string" &&
            taskState.modeOther.replace(/\s/g, "").length <= 0
          ) {
            taskError["modeOtherError"] = "Please enter the mode";
          } else {
            taskError["modeOtherError"] = "";
          }
        } else {
          taskError["modeOtherError"] = "";
        }
        break;
      case "manPower":
        if (
          !numberRegEx.test(taskState.manPower.toString()) ||
          Number(taskState.manPower) <= 0
        ) {
          taskError["manPowerError"] =
            "Please enter a number for man power required";
        } else {
          taskError["manPowerError"] = "";
        }
        break;
      case "flightSpeed":
        if (taskState.flightSpeed.replace(/\s/g, "").length <= 0) {
          taskError["flightSpeedError"] = "Please enter the flight speed";
        } else {
          taskError["flightSpeedError"] = "";
        }
        break;
      case "flightHeight":
        if (
          !numberRegEx.test(taskState.flightHeight.toString()) ||
          Number(taskState.flightHeight) <= 0
        ) {
          taskError["flightHeightError"] =
            "Please enter a number for flight height";
        } else {
          taskError["flightHeightError"] = "";
        }
        break;
      case "dosage":
        if (
          !numberRegEx.test(taskState.dosage.toString()) ||
          Number(taskState.dosage) <= 0
        ) {
          taskError["dosageError"] = "Please enter a number for dosage";
        } else {
          taskError["dosageError"] = "";
        }
        break;
      case "atomization":
        if (
          !numberRegEx.test(taskState.atomization.toString()) ||
          Number(taskState.atomization) <= 0
        ) {
          taskError["atomizationError"] =
            "Please enter a number for atomization";
        } else {
          taskError["atomizationError"] = "";
        }
        break;
      case "routeInterval":
        if (
          !numberRegEx.test(taskState.routeInterval.toString()) ||
          Number(taskState.routeInterval) <= 0
        ) {
          taskError["routeIntervalError"] =
            "Please enter a number for route interval";
        } else {
          taskError["routeIntervalError"] = "";
        }
        break;
      case "boundarySafetyDistance":
        if (
          !numberRegEx.test(taskState.boundarySafetyDistance.toString()) ||
          Number(taskState.boundarySafetyDistance) <= 0
        ) {
          taskError["boundarySafetyDistanceError"] =
            "Please enter a number for boundary safety distance";
        } else {
          taskError["boundarySafetyDistanceError"] = "";
        }
        break;
      case "obstacleSafetyDistance":
        if (
          !numberRegEx.test(taskState.obstacleSafetyDistance.toString()) ||
          Number(taskState.obstacleSafetyDistance) <= 0
        ) {
          taskError["obstacleSafetyDistanceError"] =
            "Please enter a number for obstacle safety distance";
        } else {
          taskError["obstacleSafetyDistanceError"] = "";
        }
        break;
      case "areaCover":
        if (
          !numberRegEx.test(taskState.areaCover.toString()) ||
          Number(taskState.areaCover) <= 0
        ) {
          taskError["areaCoverError"] = "Please enter a number for area cover";
        } else {
          taskError["areaCoverError"] = "";
        }
        break;
      case "batteryUsed":
        if (
          !numberRegEx.test(taskState.batteryUsed.toString()) ||
          Number(taskState.batteryUsed) <= 0
        ) {
          taskError["batteryUsedError"] =
            "Please enter a number for battery used";
        } else {
          taskError["batteryUsedError"] = "";
        }
        break;
      case "chargingCycle":
        if (
          !numberRegEx.test(taskState.chargingCycle.toString()) ||
          Number(taskState.chargingCycle) <= 0
        ) {
          taskError["chargingCycleError"] =
            "Please enter a number for charging cycle";
        } else {
          taskError["chargingCycleError"] = "";
        }
        break;
      case "totalChemicalUsed":
        if (
          !numberRegEx.test(taskState.totalChemicalUsed.toString()) ||
          Number(taskState.totalChemicalUsed) < 0
        ) {
          taskError["totalChemicalUsedError"] =
            "Please enter a number for total chemical used";
        } else {
          taskError["totalChemicalUsedError"] = "";
        }
        break;
      case "typeOfChemicalUsed":
        if (taskState.typeOfChemicalUsed.replace(/\s/g, "").length <= 0) {
          taskError["typeOfChemicalUsedError"] =
            "Please enter the type of chemical used";
        } else {
          taskError["typeOfChemicalUsedError"] = "";
        }
        break;
      case "operationHours":
        if (taskState.operationHours.replace(/\s/g, "").length <= 0) {
          taskError["operationHoursError"] = "Please enter the operation hours";
        } else {
          taskError["operationHoursError"] = "";
        }
        break;
      case "flightTime":
        if (taskState.flightTime.replace(/\s/g, "").length <= 0) {
          taskError["flightTimeError"] = "Please enter the flight time";
        } else {
          taskError["flightTimeError"] = "";
        }
        break;
      case "totalDownTime":
        if (
          !numberRegEx.test(taskState.totalDownTime.toString()) ||
          Number(taskState.totalDownTime) < 0
        ) {
          taskError["totalDownTimeError"] =
            "Please enter a number for total down time";
        } else {
          taskError["totalDownTimeError"] = "";
        }
        break;
      case "limitation":
        if (taskState.limitation.replace(/\s/g, "").length <= 0) {
          taskError["limitationError"] = "Please enter the limitation";
        } else {
          taskError["limitationError"] = "";
        }
        break;
      case "conclusion":
        if (taskState.conclusion.replace(/\s/g, "").length <= 0) {
          taskError["conclusionError"] = "Please enter the conclusion";
        } else {
          taskError["conclusionError"] = "";
        }
        break;
      case "recommendation":
        if (taskState.recommendation.replace(/\s/g, "").length <= 0) {
          taskError["recommendationError"] = "Please enter the recommendation";
        } else {
          taskError["recommendationError"] = "";
        }
        break;
      case "file":
        if (taskState.file) {
          const fileExtensionFilter = /.(jpg|jpeg|png)$/;
          const fileSize = taskState.file.size / 1024 / 1024;
          if (!fileExtensionFilter.test(taskState.file.name)) {
            taskError["fileError"] = "Please upload an image file";
          } else if (fileSize > 5) {
            taskError["fileError"] = "File size cannot exceed 5MB";
          }
        } else {
          taskError["fileError"] = "Please upload an image file";
        }
        break;
    }
    return null;
  });
};
