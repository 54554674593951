import axios from "axios";
import { DateRange } from "../../components/base/LineChart";
import { SensorErrorAttribute } from "../../components/dashboard/CreateSensorModal";
import { Sensor, SensorAnalyticsType } from "../../components/model/Sensor";
import { getAPIUrl } from "../../utils/database";
import { auth } from "../../utils/firebase";

export const getSensorsWithPagination = (
  text: string,
  type: keyof typeof SensorAnalyticsType,
  skip: number
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let sensorListResponse;
      const token = await auth.currentUser?.getIdToken();

      let data = {
        text,
        type,
        sharedTo: auth.currentUser?.uid,
        skip: skip * 10,
      };

      updateSensorLoadingState(dispatch, true);
      if (text.length > 0) {
        sensorListResponse = await axios.post(`${getAPIUrl()}/sensor/search`, {
          data,
          idToken: token,
          token: process.env.REACT_APP_API_TOKEN,
        });
      } else {
        sensorListResponse = await axios.post(`${getAPIUrl()}/sensor/list`, {
          data,
          idToken: token,
          token: process.env.REACT_APP_API_TOKEN,
        });
      }

      dispatch({
        type: "UPDATE_SENSOR_LIST",
        payload: {
          sensors: sensorListResponse.data.sensorData,
          size: sensorListResponse.data.size,
        },
      });
      updateSensorLoadingState(dispatch, false);
    } catch (err: any) {
      updateSensorLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const getSensorAnalytics = async (
  userId: string,
  type: keyof typeof SensorAnalyticsType
) => {
  const token = await auth.currentUser?.getIdToken();

  let data = {
    userId,
    type,
  };
  const sensorAnalytics = await axios.post(
    `${getAPIUrl()}/analytics/getSensor`,
    {
      data,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    }
  );
  return sensorAnalytics;
};

export const getSensorSuggestion = async (
  type: keyof typeof SensorAnalyticsType,
  text: string
) => {
  const token = await auth.currentUser?.getIdToken();

  let data = {
    type,
    text,
  };

  let sensorSuggestion;
  const sensorList: Sensor[] = [];
  sensorSuggestion = await axios.post(`${getAPIUrl()}/sensor/search`, {
    idToken: token,
    token: process.env.REACT_APP_API_TOKEN,
    data,
  });

  if (sensorSuggestion) {
    sensorSuggestion.data.sensorData.forEach((eachDoc: any) => {
      const eachSensor = eachDoc as Sensor;
      sensorList.push(eachSensor);
    });
    return sensorList;
  }
  return [];
};

export const getSensorGraph = async (data: any) => {
  let graphData;
  const token = await auth.currentUser?.getIdToken();
  if (data.sensorId) {
    graphData = await axios.post(`${getAPIUrl()}/sensor/graph`, {
      data,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
  }
  return graphData;
};

export const getSelectedSensors = async (selectedId: string) => {
  try {
    const token = await auth.currentUser?.getIdToken();

    const sensorData = await axios.post(`${getAPIUrl()}/sensor/get`, {
      data: { _id: selectedId },
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    if (sensorData) {
      return sensorData.data as Sensor;
    } else {
      return selectedId;
    }
  } catch (err) {}
};

export const getSensorCode = async (selectedId: string) => {
  const token = await auth.currentUser?.getIdToken();

  const sensorData = await axios.post(`${getAPIUrl()}/sensor/get`, {
    data: { _id: selectedId },
    idToken: token,
    token: process.env.REACT_APP_API_TOKEN,
  });
  if (sensorData) {
    return sensorData.data.name;
  } else {
    return selectedId;
  }
};

export const createSensor = async (sensorState: Sensor) => {
  try {
    const token = await auth.currentUser?.getIdToken();

    const createSensorResponse = await axios.post(`${getAPIUrl()}/sensor/add`, {
      data: sensorState,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    return createSensorResponse.data.created;
  } catch (err) {
    return "Unknown error, please contact developer if this continues";
  }
};

export const getAllSensorLocation = async (sharedTo: string) => {
  try {
    const token = await auth.currentUser?.getIdToken();
    const sensorData = await axios.post(`${getAPIUrl()}/sensor/getLocation`, {
      data: { sharedTo },
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    return sensorData.data;
  } catch (err) {}
};

export const updateSensor = async (sensorState: Sensor) => {
  try {
    const token = await auth.currentUser?.getIdToken();

    await axios.post(`${getAPIUrl()}/sensor/update`, {
      data: sensorState,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    return "";
  } catch (err) {
    return "Unknown error, please contact developer if this continues";
  }
};

export const deleteSensor = async (_id: string) => {
  try {
    const token = await auth.currentUser?.getIdToken();
    await axios.post(`${getAPIUrl()}/sensor/delete`, {
      data: { _id },
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });

    return "";
  } catch (err) {
    return "Unknown error, please contact developer if this continues";
  }
};

export const clearSensors = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_SENSOR_LIST",
      payload: {
        sensors: [],
        size: 0,
      },
    });
  };
};

const updateSensorLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_SENSOR_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const updateSensorDateRange = (dateRange: keyof typeof DateRange) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_SENSOR_DATE_RANGE",
      payload: {
        dateRange,
      },
    });
  };
};

export const updateSensorCustomRange = (range: Object) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_SENSOR_CUSTOM_DATE",
      payload: {
        range,
      },
    });
  };
};

export const handleCreateSensorCondition = (
  sensorStateAttribute: Sensor,
  sensorStateAttributeError: SensorErrorAttribute,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "id":
        if (sensorStateAttribute._id.replace(/\s/g, "").length <= 0) {
          sensorStateAttributeError["idError"] = "Please enter the sensor's ID";
        } else {
          sensorStateAttributeError["idError"] = "";
        }
        break;
      case "name":
        if (sensorStateAttribute.name.replace(/\s/g, "").length <= 0) {
          sensorStateAttributeError["nameError"] =
            "Please enter the sensor's name";
        } else {
          sensorStateAttributeError["nameError"] = "";
        }
        break;
      case "code":
        if (sensorStateAttribute.code.replace(/\s/g, "").length <= 0) {
          sensorStateAttributeError["codeError"] =
            "Please enter the sensor's code";
        } else {
          sensorStateAttributeError["codeError"] = "";
        }
        break;

      case "crop":
        if (sensorStateAttribute.crop.replace(/\s/g, "").length <= 0) {
          sensorStateAttributeError["cropError"] =
            "Please enter the sensor's crop";
        } else {
          sensorStateAttributeError["cropError"] = "";
        }
        break;
      case "location":
        if (sensorStateAttribute.location.name.replace(/\s/g, "").length <= 0) {
          sensorStateAttributeError["locationError"] =
            "Please enter the sensor's location";
        } else {
          sensorStateAttributeError["locationError"] = "";
        }
        break;

      case "tag":
        if (sensorStateAttribute.tag.replace(/\s/g, "").length <= 0) {
          sensorStateAttributeError["tagError"] =
            "Please enter the sensor's tag";
        } else {
          sensorStateAttributeError["tagError"] = "";
        }
        break;
    }
    return null;
  });
};
