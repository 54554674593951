export enum SensorStatus {
  INIT = "Initialised",
  DEP = "Deployed",
  ABN = "Abnormal",
}

export enum SensorAnalyticsType {
  SOIL = "soil",
  WEATHER = "weather",
}

/**
 * @collection sensors
 * @description Sensors of LesQ SPP Dashboard
 *
 * _id - Generated UUID v4
 * name - Name of the sensor
 * code - Code of the sensor
 * createdAt - Creation date of the sensor
 * type - Type of the sensor (eg Soil, Weather)
 * tag - Tag of the sensor (eg Zone A, Zone B)
 * crop - Crop type
 * status : Enum of sensor status
 * location - Location of the sensor
 * sharedTo - Shared to Firebase Auth ID
 */
export interface Sensor {
  _id: string;
  name: string;
  code: string;
  createdAt: Date;
  type: keyof typeof SensorAnalyticsType;
  tag: string;
  crop: string;
  status: keyof typeof SensorStatus;
  location: {
    name: string;
    lat: number;
    lng: number;
  };
  sharedTo: string;
}
