import { Project } from "../../components/model/Project";

export interface projectStateInterface {
  projects: Project[];
  size: number;
  loading: boolean;
}

const initState: projectStateInterface = {
  projects: [],
  size: 0,
  loading: false,
};

const projectReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_PROJECT_LIST":
      return {
        ...state,
        projects: action.payload.projects,
        size: action.payload.size,
      };
    case "UPDATE_PROJECT_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default projectReducer;
