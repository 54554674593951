import { TrashIcon } from "@heroicons/react/outline";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/base/Button";
import ConfirmationModal from "../components/base/ConfirmationModal";
import IconButton from "../components/base/IconButton";
import Input from "../components/base/Input";
import Overlay from "../components/base/Overlay";
import Table, { TableHeader } from "../components/base/Table";
import UserModal from "../components/dashboard/UserModal";
import { Role, UserModel } from "../components/model/User";
import { withRouter } from "../navigator/NavigateWrapper";
import {
  clearUsers,
  createUser,
  deleteUser,
  getUsersWithPagination,
  handleCreateUserCondition,
} from "../store/actions/userActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { userStateInterface } from "../store/reducers/usersReducer";

export interface UserState extends UserModel {
  password: string;
}

export interface UserStateError {
  nameError: string;
  emailError: string;
  passwordError: string;
  companyError: string;
}

interface Props {
  authStore: authStateInterface;
  history: any;
  navigate: any;
  location: any;
  userStore: userStateInterface;
  clearUsers: () => void;
  getUsersWithPagination: (search: string, skip: number) => void;
}

interface State {
  loading: boolean;
  pageIndex: number;
  searchText: string;
  modalVisible: boolean;
  selectedUser: {
    _id: string;
    name: string;
  };
  userList: UserModel[];
  userState: UserState;
  userStateError: UserStateError;
}
class Users extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;

  state: State = {
    userList: [],
    modalVisible: false,
    loading: false,
    pageIndex: 0,
    selectedUser: {
      _id: "",
      name: "",
    },
    userState: {
      _id: "",
      name: "",
      email: "",
      password: "",
      company: "",
      role: "USER",
      createdAt: moment().toDate(),
    },
    userStateError: {
      nameError: "",
      emailError: "",
      passwordError: "",
      companyError: "",
    },
    searchText: "",
  };

  componentDidMount = async () => {
    if (this.props.authStore.user?.role === "SUPER") {
      this.handleGetPreviewData();
    } else {
      this.props.navigate("/dashboard");
    }
  };

  componentWillUnmount = () => {
    this.props.clearUsers();
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = null;
    }
  };

  handleUpdateLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex }, () => {
      this.handleGetPreviewData();
    });
  };

  handleFilter = (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.searchTimeout = setTimeout(() => {
        this.handleUpdatePageIndex(0);
      }, 500);
    });
  };

  handleGetPreviewData = async () => {
    this.props.getUsersWithPagination(
      this.state.searchText,
      this.state.pageIndex
    );
  };

  handleModalVisible = (modalVisible: boolean) => {
    this.setState({
      modalVisible,
      userState: {
        _id: "",
        name: "",
        email: "",
        password: "",
        company: "",
        role: "USER",
        createdAt: moment().toDate(),
      },
      userStateError: {
        nameError: "",
        emailError: "",
        passwordError: "",
        companyError: "",
      },
    });
  };

  handleSubmit = () => {
    this.setState({
      modalVisible: false,
    });
    this.handleUpdateLoading(true);
    const conditionsList: string[] = ["email", "password", "name", "company"];

    const clonedUserError = JSON.parse(
      JSON.stringify(this.state.userStateError)
    );

    handleCreateUserCondition(
      this.state.userState,
      clonedUserError,
      conditionsList
    );

    this.setState(
      {
        userStateError: clonedUserError,
      },
      async () => {
        if (
          !this.state.userStateError.nameError &&
          !this.state.userStateError.emailError &&
          !this.state.userStateError.passwordError &&
          !this.state.userStateError.companyError
        ) {
          const createUserResponse = await createUser(this.state.userState);

          if (typeof createUserResponse !== "string") {
            this.handleGetPreviewData();
          } else {
            const clonedUserError = JSON.parse(
              JSON.stringify(this.state.userStateError)
            );
            clonedUserError["emailError"] = createUserResponse;
            this.setState({
              userStateError: clonedUserError,
              modalVisible: true,
              loading: false,
            });
          }
        }
        this.handleUpdateLoading(false);
      }
    );
  };

  handleOnChange = (e: any) => {
    const clonedUserState = JSON.parse(JSON.stringify(this.state.userState));
    clonedUserState[e.target.id] = e.target.value;
    this.setState({
      userState: clonedUserState,
    });
  };

  handleSelectChange = (id: string, value: string) => {
    const clonedUserState = JSON.parse(JSON.stringify(this.state.userState));
    clonedUserState[id] = value;
    this.setState({
      userState: clonedUserState,
    });
  };

  handleRemoveUser = async () => {
    this.handleUpdateLoading(true);
    const userDeleteResponse = await deleteUser(this.state.selectedUser._id);
    if (!userDeleteResponse) {
      this.handleGetPreviewData();
    } else {
      window.alert("Unable to delete. Please try again later");
    }
    this.handleUpdateLoading(false);
  };

  handleUpdateSelectedUser = (selectedUser: { _id: string; name: string }) => {
    this.setState({
      selectedUser,
    });
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (this.props.userStore.users.length > 0) {
      this.props.userStore.users.map((eachUser) =>
        listView.push({
          id: eachUser._id,
          email: eachUser.email,
          name: eachUser.name,
          company: eachUser.company,
          role: Role[eachUser.role],
          createdAt: moment(eachUser.createdAt).format("DD-MM-YY"),
          remove: eachUser.role !== "SUPER" && (
            <IconButton
              icon={<TrashIcon className="w-5 h-5 text-emerald-500" />}
              onClick={this.handleUpdateSelectedUser.bind(this, {
                _id: eachUser._id,
                name: eachUser.name,
              })}
            />
          ),
        })
      );
    }
    return listView;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "email",
        title: "Email",
      },
      {
        key: "name",
        title: "Name",
      },
      {
        key: "company",
        title: "Company",
      },
      {
        key: "role",
        title: "Role",
      },
      {
        key: "createdAt",
        title: "Created At",
      },
      {
        key: "remove",
        title: "Remove",
      },
    ];

    const headerComponent = {
      title: "List of Users",
      desc: "Order from latest creation date",
      buttonText: "Add Users",
      buttonOnClick: () => {
        this.handleModalVisible(true);
      },
    };
    return (
      <>
        <Overlay
          loading={this.state.loading}
          text="Please wait while we are updating your request"
        />
        <UserModal
          userState={this.state.userState}
          userStateError={this.state.userStateError}
          visible={this.state.modalVisible}
          handleOnChange={this.handleOnChange}
          handleSelectChange={this.handleSelectChange}
          handleSubmit={this.handleSubmit}
          onClose={this.handleModalVisible}
        />

        <ConfirmationModal
          content={`Are you sure that you want to remove the user - ${this.state.selectedUser?.name} ?`}
          title="Remove Confirmation"
          onClick={this.handleRemoveUser}
          open={this.state.selectedUser._id ? true : false}
          onClose={this.handleUpdateSelectedUser.bind(this, {
            _id: "",
            name: "",
          })}
        />
        <div className="mt-8">
          <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
            <div className="mt-5">
              <div className="flex flex-col py-2 sm:items-center sm:flex-row">
                <div className="flex flex-col">
                  <p className="text-xl font-medium">{headerComponent.title}</p>
                  <span className="text-sm text-gray-500">
                    {headerComponent.desc}
                  </span>
                </div>
                <div className="flex-1" />
                <div className="mt-3 sm:mt-0">
                  <Input placeholder="Search" onChange={this.handleFilter} />
                </div>
                <Button
                  className="my-3 sm:my-0 sm:ml-5"
                  text={headerComponent.buttonText}
                  type="normal"
                  onClick={headerComponent.buttonOnClick}
                />
              </div>
            </div>

            <Table
              loading={this.props.userStore.loading}
              header={headers}
              pageIndex={this.state.pageIndex}
              size={this.props.userStore.size}
              data={this.renderFilteredData()}
              handlePage={this.handleUpdatePageIndex}
            />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    userStore: state.userStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearUsers: () => dispatch(clearUsers()),
    getUsersWithPagination: (search: string, skip: number) =>
      dispatch(getUsersWithPagination(search, skip)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Users));
