import { XIcon } from "@heroicons/react/solid";
import React, { Component } from "react";
import IconButton from "../base/IconButton";

interface Props {
  text: string;
  icon: JSX.Element;
  className?: string;
}

interface State {
  visible?: boolean;
}

export default class Banner extends Component<Props> {
  state: State = {
    visible: true,
  };

  handleHideBanner = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    if (!this.state.visible) {
      return null;
    }

    let defaultClassName = "w-full";
    if (this.props.className) {
      defaultClassName += ` ${this.props.className}`;
    }

    return (
      <div className={defaultClassName}>
        <div className="p-2 rounded-lg bg-emerald-600 shadow-lg sm:p-3">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg ring-2 ring-white">
                {this.props.icon}
              </span>
              <p className="ml-3 font-medium text-white truncate">
                <span className="inline">{this.props.text}</span>
              </p>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <IconButton
                onClick={this.handleHideBanner}
                icon={<XIcon className="h-5 w-5 text-white" />}
                background="bg-currentColor rounded-md hover:outline-none hover:ring-2 hover:ring-white focus:outline-none focus:ring-2 focus:ring-white"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
