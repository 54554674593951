import { XCircleIcon } from "@heroicons/react/outline";
import { PhotographIcon } from "@heroicons/react/solid";
import React, { Component } from "react";

interface Props {
  accept?: string;
  error?: string;
  hidePreview?: boolean;
  file?: File | File[] | "";
  onChange?: (e: any) => void;
}

class UploadInput extends Component<Props> {
  handleOnChange = (e: any) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  handleRemoveFile = (e: any, index: any) => {
    if (this.props.onChange) {
      this.props.onChange(index);
    }
  };

  renderImageFile = () => {
    let imageFileList: JSX.Element[] = [];
    if (Array.isArray(this.props.file) && this.props.file.length > 0) {
      this.props.file.map((eachFile, index) => {
        imageFileList.push(
          <div className="relative mb-2">
            <img
              alt=""
              src={URL.createObjectURL(eachFile)}
              className="mx-auto w-full max-w-40 h-32 w-auto object-cover block"
            />
            <XCircleIcon
              className="h-5 w-5 cursor-pointer text-red-600 absolute top-0 right-0 hover:text-red-500"
              onClick={this.handleOnChange.bind(this, index)}
            />
          </div>
        );
        return null;
      });
    } else if (this.props.file instanceof File) {
      imageFileList.push(
        <img
          alt=""
          src={URL.createObjectURL(this.props.file)}
          className="mx-auto h-32 w-auto max-w-40 object-cover"
        />
      );
    }
    return imageFileList;
  };

  render() {
    const defaultBorderColor = " border-gray-300";
    const errorBorderColor = "border-red-300";
    return (
      <div className="sm:mt-1 sm:col-span-2">
        <div
          className={`${
            this.props.error ? errorBorderColor : defaultBorderColor
          } border-2 max-w-lg flex justify-center px-6 pt-5 pb-6  border-dashed rounded-md`}
        >
          <div className="space-y-1 text-center">
            {(
              Array.isArray(this.props.file)
                ? this.props.file.length > 0
                : this.props.file !== "" && !this.props.hidePreview
            ) ? (
              this.renderImageFile()
            ) : (
              <PhotographIcon className="mx-auto h-12 w-12 text-gray-300" />
            )}
            <div className="flex text-sm text-gray-600 justify-center">
              <label className="relative cursor-pointer bg-white rounded-md font-medium text-emerald-600 hover:text-emerald-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-emerald-500">
                <span>Upload Image</span>
                <input
                  id="file-upload"
                  name="Please select an image (5MB)"
                  type="file"
                  className="sr-only"
                  accept={this.props.accept}
                  onChange={this.handleOnChange}
                />
              </label>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG, JPEG up to 5MB</p>
          </div>
        </div>
        <span className="text-red-500 text-sm">{this.props.error}</span>
      </div>
    );
  }
}

export default UploadInput;
