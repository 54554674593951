import { Dialog, Transition } from "@headlessui/react";
import React, { Component, Fragment } from "react";
import { UserState, UserStateError } from "../../pages/Users";
import Button from "../base/Button";
import Input from "../base/Input";
import Select, { Option } from "../base/Select";
import { Role } from "../model/User";

const roleOption: Option[] = [
  {
    key: "OPERATOR",
    title: Role["OPERATOR"],
  },
  {
    key: "USER",
    title: Role["USER"],
  },
];

interface Props {
  visible: boolean;
  userState: UserState;
  userStateError: UserStateError;
  onClose: (bool: boolean) => void;
  handleSubmit: () => void;
  handleOnChange: (e: any) => void;
  handleSelectChange: (id: string, value: string) => void;
}

export default class UserModal extends Component<Props> {
  render() {
    return (
      <Transition.Root show={this.props.visible} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={this.props.onClose.bind(this, false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-bold text-gray-900"
                      >
                        Create User
                      </Dialog.Title>
                    </div>
                    <div className="flex flex-col space-y-5 ml-4 mt-5">
                      <div className="flex flex-row">
                        <span className="w-44 inline-flex items-center text-md text-gray-800">
                          Name
                        </span>
                        <div className="ml-5 w-full">
                          <Input
                            placeholder="Name"
                            id="name"
                            value={this.props.userState.name}
                            error={this.props.userStateError.nameError}
                            onChange={this.props.handleOnChange}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <span className="w-44 inline-flex items-center text-md text-gray-800">
                          Email
                        </span>
                        <div className="ml-5 w-full">
                          <Input
                            placeholder="Email"
                            id="email"
                            value={this.props.userState.email}
                            error={this.props.userStateError.emailError}
                            onChange={this.props.handleOnChange}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <span className="w-44 inline-flex items-center text-md text-gray-800">
                          Password
                        </span>
                        <div className="ml-5 w-full">
                          <Input
                            placeholder="Password"
                            type="password"
                            id="password"
                            value={this.props.userState.password}
                            error={this.props.userStateError.passwordError}
                            onChange={this.props.handleOnChange}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <span className="w-44 inline-flex items-center text-md text-gray-800">
                          Role
                        </span>
                        <div className="ml-5 w-full">
                          <Select
                            id="role"
                            placeholder={"Role"}
                            value={this.props.userState.role}
                            options={roleOption}
                            onChange={this.props.handleSelectChange}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <span className="w-44 inline-flex items-center text-md text-gray-800">
                          Company Name
                        </span>
                        <div className="ml-5 w-full">
                          <Input
                            placeholder="Company Name"
                            id="company"
                            value={this.props.userState.company}
                            error={this.props.userStateError.companyError}
                            onChange={this.props.handleOnChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <Button
                      text="Add"
                      type="normal"
                      onClick={this.props.handleSubmit}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
