import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../components/dashboard/Header";
import LeftNav, { DashboardPage } from "../components/dashboard/LeftNav";
import { withRouter } from "../navigator/NavigateWrapper";
import SecurityNavigator from "../navigator/SecurityNavigator";
import { updateUnits } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import CreateProject from "./CreateProject";
import Home from "./Home";
import Mapview from "./Mapview";
import ReportView from "./ReportView";
import Sensor from "./Sensor";
import SensorDetail from "./SensorDetail";
import Tasks from "./Tasks";
import Users from "./Users";

interface Props {
  location: any;
  navigate: any;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
  updateUnits: (units: string) => void;
}

interface State {
  leftNavMobileMenu: boolean;
  page: DashboardPage;
}

class Dashboard extends Component<Props> {
  state: State = {
    leftNavMobileMenu: false,
    page: DashboardPage.PROJECTS,
  };

  componentDidMount() {
    if (this.props.utilsStore.onlineStatus) {
      this.handlePageNavigation();
    } else {
      this.setState({
        page: DashboardPage.TASKS,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handlePageNavigation();
    }
    if (
      prevProps.utilsStore.onlineStatus !==
        this.props.utilsStore.onlineStatus &&
      !this.props.utilsStore.onlineStatus
    ) {
      this.setState({
        page: DashboardPage.TASKS,
      });
    }
  }

  handleNavigatePage = (page: DashboardPage, id?: string) => {
    let path = "";
    switch (page) {
      case DashboardPage.PROJECTS:
        path = `/dashboard/home`;
        break;
      case DashboardPage.CREATEPROJECT:
        path = `/dashboard/createProject?id=${id}`;
        break;
      case DashboardPage.SOILFERTILITYDETAIL:
        path = `dashboard/soilFertilityDetail?id=${id}`;
        break;
      case DashboardPage.SOILFERTILITY:
        path = `/dashboard/soilFertility`;
        break;
      case DashboardPage.WEATHERSTATION:
        path = `/dashboard/weatherStation`;
        break;
      case DashboardPage.WEATHERSTATIONDETAIL:
        path = `/dashboard/weatherStationDetail?id=${id}`;
        break;
      case DashboardPage.USERS:
        path = `/dashboard/users`;
        break;
      case DashboardPage.REPORT:
        path = `/dashboard/report?id=${id}`;
        break;
      case DashboardPage.TASKS:
        path = `/dashboard/tasks`;
        break;
      default:
        break;
    }

    if (path) {
      this.props.navigate(path);
    }

    this.setState({
      page,
    });
  };

  handlePageNavigation = () => {
    const path = window.location.pathname;
    let page: DashboardPage = DashboardPage.PROJECTS;

    if (path.includes("dashboard/soilDetail")) {
      page = DashboardPage.SOILFERTILITYDETAIL;
    } else if (path.includes("dashboard/soil")) {
      page = DashboardPage.SOILFERTILITY;
    } else if (path.includes("dashboard/weatherDetail")) {
      page = DashboardPage.WEATHERSTATIONDETAIL;
    } else if (path.includes("dashboard/weather")) {
      page = DashboardPage.WEATHERSTATION;
    } else if (path.includes("dashboard/createProject")) {
      page = DashboardPage.CREATEPROJECT;
    } else if (path.includes("dashboard/users")) {
      page = DashboardPage.USERS;
    } else if (path.includes("dashboard/report")) {
      page = DashboardPage.REPORT;
    } else if (path.includes("dashboard/tasks")) {
      page = DashboardPage.TASKS;
    } else if (path.includes("dashboard/map")) {
      page = DashboardPage.MAP;
    }

    this.setState({
      page,
    });
  };

  handleToggleLeftNavMobileMenu = () => {
    this.setState({
      leftNavMobileMenu: !this.state.leftNavMobileMenu,
    });
  };

  renderContent = () => {
    switch (this.state.page) {
      case DashboardPage.PROJECTS:
        return <Home handleNavigatePage={this.handleNavigatePage} />;
      case DashboardPage.CREATEPROJECT:
        return (
          <CreateProject
            handleNavigatePage={this.handleNavigatePage}
            authStore={this.props.authStore}
            utilsStore={this.props.utilsStore}
          />
        );
      case DashboardPage.TASKS:
        return <Tasks />;
      case DashboardPage.SOILFERTILITY:
      case DashboardPage.WEATHERSTATION:
        return <Sensor page={this.state.page} />;
      case DashboardPage.SOILFERTILITYDETAIL:
      case DashboardPage.WEATHERSTATIONDETAIL:
        return <SensorDetail page={this.state.page} />;
      case DashboardPage.USERS:
        return (
          <Users
            handleNavigatePage={this.handleNavigatePage}
            authStore={this.props.authStore}
          />
        );
      case DashboardPage.REPORT:
        return <ReportView utilsStore={this.props.utilsStore} />;
      case DashboardPage.MAP:
        return <Mapview />;
      default:
        return null;
    }
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }
    return (
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <SecurityNavigator location={this.props.location} />
        {this.state.page !== DashboardPage.REPORT && (
          <LeftNav
            navigate={this.props.navigate}
            authStore={this.props.authStore}
            utilsStore={this.props.utilsStore}
            leftNavMobileMenu={this.state.leftNavMobileMenu}
            handleToggleLeftNavMobileMenu={this.handleToggleLeftNavMobileMenu}
            updateUnits={this.props.updateUnits}
            page={this.state.page}
          />
        )}
        <div className="flex-1 overflow-auto focus:outline-none">
          <Header
            handleToggleLeftNavMobileMenu={this.handleToggleLeftNavMobileMenu}
          />
          <main className="flex-1 relative z-0 overflow-y-auto">
            {this.renderContent()}
          </main>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUnits: (units: string) => dispatch(updateUnits(units)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
