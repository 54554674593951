import {
  ChevronDownIcon,
  ChevronUpIcon,
  LocationMarkerIcon,
  MapIcon,
  PencilIcon,
  TrashIcon,
  UploadIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import React, { Component, createRef } from "react";
import { Address } from "../components/base/AddressInput";
import ConfirmationModal from "../components/base/ConfirmationModal";
import Overlay from "../components/base/Overlay";
import CreateProjectCard from "../components/dashboard/CreateProjectCard";
import { DashboardPage } from "../components/dashboard/LeftNav";
import PolygonCard from "../components/dashboard/PolygonCard";
import UploadImageModal from "../components/dashboard/UploadImageModal";
import {
  GeoPolygon,
  GeoPolygonColor,
  Image,
  Project,
} from "../components/model/Project";
import {
  createProject,
  getSelectedProject,
  handleCreateProjectCondition,
  updateProject,
  uploadKML,
} from "../store/actions/projectActions";
import { renderPolygonColorStatus } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";

export interface ProjectStateAttributeError {
  nameError: string;
  locationError: string;
  cropError: string;
  geoListError: string;
}

export interface GeoJSONAttribute {
  type: string;
  geometry: {
    type: string;
    coordinates: any[];
  };
  properties: GeoPolygon;
}

interface Props {
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
  handleNavigatePage: (page: DashboardPage) => void;
}

interface State {
  creation: boolean;
  collapse: boolean;
  createProjectVisible: boolean;
  polygonModalVisible: boolean;
  uploadImageModalVisible: boolean;
  imageOverlayVisible: boolean;
  loading: boolean;
  selectedPolygonIndex: number;
  projectStateAttribute: Project;
  projectStateAttributeError: ProjectStateAttributeError;
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default class CreateProject extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;

  private map = createRef<HTMLDivElement>();
  private googleMap: any = "";
  private imageOverlay: any = null;
  private infoWindowList: any[] = [];
  mapListener: any = null;

  state: State = {
    creation: false,
    createProjectVisible: false,
    polygonModalVisible: false,
    uploadImageModalVisible: false,
    imageOverlayVisible: true,
    loading: false,
    collapse: true,
    projectStateAttribute: {
      _id: "",
      name: "",
      location: {
        lat: 0,
        lng: 0,
        name: "",
      },
      createdAt: moment().toDate(),
      service: "SPRAY",
      crop: "",
      geoList: [],
      ownedBy: "",
      sharedTo: [],
      image: {
        token: "",
        east: 0,
        north: 0,
        south: 0,
        west: 0,
      },
      signature: "",
      remarks: "",
    },
    projectStateAttributeError: {
      nameError: "",
      locationError: "",
      cropError: "",
      geoListError: "",
    },
    selectedPolygonIndex: -1,
  };

  componentDidMount = async () => {
    this.handleLoading(true);
    while (!window.google) {
      await sleep(1000);
    }
    this.handleLoading(false);
    this.handleInitGoogleMap();

    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("id");

    if (selectedId && selectedId !== "new") {
      const projectData = await getSelectedProject(selectedId);
      if (!projectData) {
        this.props.handleNavigatePage(DashboardPage.PROJECTS);
      } else {
        if (typeof projectData === "string") {
          this.props.handleNavigatePage(DashboardPage.PROJECTS);
        } else {
          this.setState(
            {
              projectStateAttribute: projectData,
            },
            async () => {
              await this.handleLoadPolygon();
              this.handleZoomToLocation();
              this.handleDisplayImageOverlay(true);
            }
          );
        }
      }
    } else if (selectedId === "new") {
      this.setState({
        creation: true,
        createProjectVisible: true,
      });
    } else {
      this.props.handleNavigatePage(DashboardPage.PROJECTS);
    }
  };

  componentWillUnmount = () => {
    if (this.mapListener) {
      this.mapListener.remove();
    }
  };

  handleInitGoogleMap = () => {
    this.googleMap = new window.google.maps.Map(this.map.current, {
      center: new window.google.maps.LatLng(3.139, 101.6869),
      zoom: 15,
      disableDefaultUI: true,
      mapTypeId: "satellite",
    });

    if (
      this.props.authStore.user?.role === "SUPER" ||
      this.props.authStore.user?.role === "OPERATOR"
    ) {
      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
        },
        drawingControl: true,
        polygonOptions: {
          fillColor: "#5DB075",
          strokeColor: "#FFFFFF",
        },
      });

      drawingManager.setMap(this.googleMap);
      this.mapListener = window.google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        this.handleMapOverlayListener
      );
    }
  };

  handleSelectPolygon = (selectedPolygonIndex: number, visible: boolean) => {
    this.setState({ selectedPolygonIndex, polygonModalVisible: visible });
  };

  handleCollapse = () => {
    const collapse = !this.state.collapse;
    this.setState({ collapse });
  };

  handleLoadPolygon = async () => {
    this.googleMap.data.forEach((element: any) => {
      this.googleMap.data.remove(element);
    });

    await this.googleMap.data.addGeoJson({
      type: "FeatureCollection",
      features: this.state.projectStateAttribute.geoList,
    });

    this.infoWindowList.forEach((eachInfoWindow) => {
      eachInfoWindow.close();
    });

    this.infoWindowList = [];

    if (this.infoWindowList.length === 0) {
      this.googleMap.data.forEach((element: any) => {
        try {
          const bounds = new window.google.maps.LatLngBounds();
          const coordinates = element.getGeometry().getArray()[0].h;
          coordinates.map((eachCoor: any) => {
            bounds.extend(eachCoor);
            return null;
          });
          const infowindow = new window.google.maps.InfoWindow();
          infowindow.setContent(element.getProperty("name"));
          infowindow.setPosition({
            lat: bounds.getCenter().lat(),
            lng: bounds.getCenter().lng(),
          });
          infowindow.open(this.googleMap);
          this.infoWindowList.push(infowindow);
        } catch (err) {}
      });
    }

    this.handleUpdatePolygonColor();
  };

  handleUpdatePolygonColor = () => {
    this.googleMap.data.setStyle((feature: any) => {
      let color = GeoPolygonColor.DEFAULT;
      if (feature.j.color) {
        color =
          GeoPolygonColor[feature.j.color as keyof typeof GeoPolygonColor];
      }

      return {
        fillColor: color,
        strokeColor: "#FFFFFF",
        editable: true,
      };
    });

    let selectedLatLngIndex = -1;

    this.googleMap.data.addListener("mouseup", (e: any) => {
      let selectedId = e.feature.j.id;

      let newCoordinates: number[][] = [];
      const latLngList: any = [];

      e.feature.getGeometry().forEachLatLng((element: any, index: number) => {
        if (selectedLatLngIndex === index) {
          newCoordinates.push([e.latLng.lng(), e.latLng.lat()]);
          const latLngObject = new window.google.maps.LatLng(
            e.latLng.lat(),
            e.latLng.lng()
          );
          latLngList.push(latLngObject);
        } else {
          newCoordinates.push([element.lng(), element.lat()]);
          const latLngObject = new window.google.maps.LatLng(
            element.lat(),
            element.lng()
          );
          latLngList.push(latLngObject);
        }
      });

      newCoordinates.push(newCoordinates[0]);

      const clonedGeoJson = JSON.parse(
        JSON.stringify(this.state.projectStateAttribute.geoList)
      );

      const area =
        window.google.maps.geometry.spherical.computeArea(latLngList);

      clonedGeoJson.map((eachGeoList: any) => {
        if (selectedId === eachGeoList.properties.id) {
          eachGeoList.geometry.coordinates[0] = newCoordinates;
          eachGeoList.properties.area = area.toFixed(2);
        }
        return null;
      });

      const clonedProjectAttribute = JSON.parse(
        JSON.stringify(this.state.projectStateAttribute)
      );

      clonedProjectAttribute["geoList"] = clonedGeoJson;

      this.setState(
        {
          projectStateAttribute: clonedProjectAttribute,
        },
        async () => {
          this.handleSubmit();
        }
      );
    });

    this.googleMap.data.addListener("mousedown", (e: any) => {
      const clickedLat = e.latLng.lat();
      const clickedlng = e.latLng.lng();

      e.feature.getGeometry().forEachLatLng((element: any, index: number) => {
        const eachLat = element.lat();
        const eachLng = element.lng();
        if (clickedLat === eachLat && clickedlng === eachLng) {
          selectedLatLngIndex = index;
        }
      });
    });
  };

  handleMapOverlayListener = (event: any) => {
    let newPolygon = event.overlay;
    newPolygon.type = event.type;

    let area = window.google.maps.geometry.spherical.computeArea(
      newPolygon.getPath()
    );

    newPolygon.setMap(null);

    let newGeoJson: GeoJSONAttribute = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [],
      },
      properties: {
        id: crypto.randomUUID(),
        name: `Polygon ${this.state.projectStateAttribute.geoList.length + 1}`,
        area: area.toFixed(2),
        color: "DEFAULT",
      },
    };

    let newCoordinates: number[][] = [];
    for (let point of newPolygon.getPath().getArray()) {
      newCoordinates.push([point.lng(), point.lat()]);
    }

    newCoordinates.push(newCoordinates[0]);

    newGeoJson.geometry.coordinates.push(newCoordinates);

    const clonedProjectAttribute = JSON.parse(
      JSON.stringify(this.state.projectStateAttribute)
    );

    clonedProjectAttribute["geoList"].push(newGeoJson);
    this.setState(
      {
        projectStateAttribute: clonedProjectAttribute,
      },
      async () => {
        await this.handleLoadPolygon();
        this.handleSubmit();
      }
    );
  };

  handleUpdateModalVisible = (id: string, visible: boolean) => {
    this.setState({
      [id]: visible,
      selectedPolygonIndex: -1,
    });
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleZoomToPolygon = (geoJson: GeoJSONAttribute) => {
    const bounds = new window.google.maps.LatLngBounds();
    geoJson.geometry.coordinates[0].forEach((latlng: any) => {
      const latLng = new window.google.maps.LatLng(latlng[1], latlng[0]);
      bounds.extend(latLng);
    });
    this.googleMap.fitBounds(bounds);
  };

  handleZoomToOverlay = () => {
    const imageBounds = {
      north: Number(this.state.projectStateAttribute.image?.north),
      south: Number(this.state.projectStateAttribute.image?.south),
      east: Number(this.state.projectStateAttribute.image?.east),
      west: Number(this.state.projectStateAttribute.image?.west),
    };
    this.googleMap.fitBounds(imageBounds);
  };

  handleDisplayImageOverlay = (imageOverlayVisible: boolean) => {
    if (imageOverlayVisible) {
      if (
        this.state.projectStateAttribute.image &&
        this.state.projectStateAttribute.image?.token &&
        this.state.projectStateAttribute.image?.east &&
        this.state.projectStateAttribute.image?.west &&
        this.state.projectStateAttribute.image?.north &&
        this.state.projectStateAttribute.image?.south
      ) {
        if (this.imageOverlay) {
          this.imageOverlay.setMap(null);
          this.imageOverlay = null;
        }

        const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
        let defaultWebUrl =
          "https://firebasestorage.googleapis.com/v0/b/lesq-spp-dev.appspot.com/o/projects%2F";
        if (isProd) {
          defaultWebUrl =
            "https://firebasestorage.googleapis.com/v0/b/lesq-spp.appspot.com/o/projects%2F";
        }
        const imageBounds = {
          north: Number(this.state.projectStateAttribute.image?.north),
          south: Number(this.state.projectStateAttribute.image?.south),
          east: Number(this.state.projectStateAttribute.image?.east),
          west: Number(this.state.projectStateAttribute.image?.west),
        };
        this.imageOverlay = new window.google.maps.GroundOverlay(
          `${defaultWebUrl}${this.state.projectStateAttribute._id}%2Fmap.png?alt=media&token=${this.state.projectStateAttribute.image?.token}`,
          imageBounds
        );

        this.imageOverlay.setMap(this.googleMap);
        this.googleMap.fitBounds(imageBounds);
      }
    } else {
      this.imageOverlay.setMap(null);
      this.imageOverlay = null;
    }
    this.setState({
      imageOverlayVisible,
    });
  };

  handleSubmit = async () => {
    this.handleLoading(true);

    const conditionsList: string[] = ["name", "crop", "location"];

    const clonedProjectAttribute = JSON.parse(
      JSON.stringify(this.state.projectStateAttribute)
    );

    const clonedProjectAttributeError = JSON.parse(
      JSON.stringify(this.state.projectStateAttributeError)
    );

    handleCreateProjectCondition(
      clonedProjectAttribute,
      clonedProjectAttributeError,
      conditionsList
    );

    this.setState(
      {
        projectStateAttributeError: clonedProjectAttributeError,
      },
      async () => {
        if (
          !this.state.projectStateAttributeError.nameError &&
          !this.state.projectStateAttributeError.cropError &&
          !this.state.projectStateAttributeError.locationError
        ) {
          if (this.state.creation) {
            const uuid = crypto.randomUUID();

            clonedProjectAttribute["_id"] = uuid;
            clonedProjectAttribute["ownedBy"] = this.props.authStore.user?._id;

            const createProjectResponse = await createProject(
              clonedProjectAttribute
            );
            if (typeof createProjectResponse === "string") {
              const clonedProjectAttributeError = JSON.parse(
                JSON.stringify(this.state.projectStateAttributeError)
              );
              clonedProjectAttributeError["nameError"] = createProjectResponse;
              this.setState({
                projectStateAttributeError: clonedProjectAttributeError,
              });
            } else {
              this.setState({
                creation: false,
                createProjectVisible: false,
                projectStateAttribute: clonedProjectAttribute,
              });
            }
          } else {
            const createProjectResponse = await updateProject(
              clonedProjectAttribute
            );
            if (createProjectResponse) {
              const clonedProjectAttributeError = JSON.parse(
                JSON.stringify(this.state.projectStateAttributeError)
              );
              clonedProjectAttributeError["nameError"] = clonedProjectAttribute;
            }
          }
        }
      }
    );
    this.handleLoading(false);
  };

  handleAddressChange = (address: Address) => {
    const clonedProjectAttribute = JSON.parse(
      JSON.stringify(this.state.projectStateAttribute)
    );
    clonedProjectAttribute["location"] = address;
    this.setState(
      {
        projectStateAttribute: clonedProjectAttribute,
      },
      () => {
        if (!this.state.creation) {
          if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = null;
          }

          this.searchTimeout = setTimeout(() => {
            this.handleZoomToLocation();
            this.handleSubmit();
          }, 500);
        } else {
          this.handleZoomToLocation();
        }
      }
    );
  };

  handleZoomToLocation = () => {
    if (
      this.state.projectStateAttribute.location.lat !== 0 &&
      this.state.projectStateAttribute.location.lng !== 0
    ) {
      if (this.state.projectStateAttribute.geoList.length > 0) {
        var bounds = new window.google.maps.LatLngBounds();
        this.googleMap.data.forEach((feature: any) => {
          feature.getGeometry().forEachLatLng((latlng: any) => {
            bounds.extend(latlng);
          });
        });

        this.googleMap.fitBounds(bounds);
      } else {
        this.googleMap.setZoom(15);
        this.googleMap.setCenter(
          new window.google.maps.LatLng(
            this.state.projectStateAttribute.location.lat,
            this.state.projectStateAttribute.location.lng
          )
        );
      }
    }
  };

  handleOnChange = (id: string, value: string | string[] | Image) => {
    const clonedProjectAttribute = JSON.parse(
      JSON.stringify(this.state.projectStateAttribute)
    );
    clonedProjectAttribute[id] = value;
    this.setState(
      {
        projectStateAttribute: clonedProjectAttribute,
      },
      () => {
        if (!this.state.creation) {
          if (typeof value === "object" && !Array.isArray(value)) {
            this.handleDisplayImageOverlay(true);
          }

          if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = null;
          }

          this.searchTimeout = setTimeout(() => {
            this.handleSubmit();
          }, 500);
        }
      }
    );
  };

  handleSubmitPolygonCard = (properties: GeoPolygon) => {
    const clonedProjectAttribute = JSON.parse(
      JSON.stringify(this.state.projectStateAttribute)
    );
    const clonedGeoPolygon = JSON.parse(
      JSON.stringify(
        this.state.projectStateAttribute.geoList[
          this.state.selectedPolygonIndex
        ]
      )
    );

    clonedGeoPolygon["properties"] = properties;
    clonedProjectAttribute["geoList"][this.state.selectedPolygonIndex] =
      clonedGeoPolygon;

    this.setState(
      {
        projectStateAttribute: clonedProjectAttribute,
        selectedPolygonIndex: -1,
        polygonModalVisible: false,
      },
      async () => {
        await this.handleLoadPolygon();
        this.handleSubmit();
      }
    );
  };

  handleRemovePolygon = (index: number) => {
    const clonedProjectAttribute = JSON.parse(
      JSON.stringify(this.state.projectStateAttribute)
    );
    clonedProjectAttribute["geoList"].splice(index, 1);

    this.setState(
      {
        projectStateAttribute: clonedProjectAttribute,
      },
      async () => {
        await this.handleLoadPolygon();
        this.handleSubmit();
      }
    );
  };

  handleUploadInput = () => {
    document.getElementById("kmlFile")?.click();
  };

  handleUploadChange = async (e: any) => {
    const file = e.target.files[0];
    const response = await uploadKML(file);
    const clonedProjectAttribute = JSON.parse(
      JSON.stringify(this.state.projectStateAttribute)
    );
    if (typeof response != "string" && response) {
      await Promise.all(
        response.data.geojson.features.map(
          async (eachFeature: any, index: number) => {
            if (eachFeature.geometry.type !== "Polygon") {
              return;
            }
            const latLngList: any = [];
            let newGeoJson: GeoJSONAttribute = {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [],
              },
              properties: {
                id: crypto.randomUUID(),
                name: `Polygon ${index + 1}`,
                area: 0,
                color: "DEFAULT",
              },
            };
            let newCoordinates: number[][] = [];
            eachFeature.geometry.coordinates[0].map((eachCoordinate: any) => {
              const latLngObject = new window.google.maps.LatLng(
                eachCoordinate[1],
                eachCoordinate[0]
              );
              newCoordinates.push([eachCoordinate[0], eachCoordinate[1]]);
              latLngList.push(latLngObject);
              return "";
            });
            const area =
              window.google.maps.geometry.spherical.computeArea(latLngList);
            newGeoJson.properties.area = area.toFixed(2);
            newGeoJson.geometry.coordinates.push(newCoordinates);
            clonedProjectAttribute["geoList"].push(newGeoJson);
          }
        )
      );
      this.setState(
        {
          projectStateAttribute: clonedProjectAttribute,
        },
        async () => {
          await this.handleLoadPolygon();
          this.handleZoomToLocation();
          this.handleSubmit();
        }
      );
    }
  };

  renderDrawingManager = () => {
    let polygonList: JSX.Element[] = [];

    const filterGeoList = this.state.projectStateAttribute.geoList.sort(
      (a: GeoJSONAttribute, b: GeoJSONAttribute) => {
        const textA = a.properties.name.toUpperCase();
        const textB = b.properties.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }
    );

    filterGeoList.map((eachGeoJson: GeoJSONAttribute, index: number) => {
      polygonList.push(
        <div className="flex flex-row px-4 py-2 w-full" key={index}>
          <label>{eachGeoJson.properties.name}</label>
          <div className="grow" />
          <LocationMarkerIcon
            className="w-5 h-5 text-emerald-500 hover:text-emerald-600 cursor-pointer"
            onClick={this.handleZoomToPolygon.bind(this, eachGeoJson)}
          />

          <PencilIcon
            className="w-5 h-5 ml-2 text-emerald-500 hover:text-emerald-600 cursor-pointer"
            onClick={this.handleSelectPolygon.bind(this, index, true)}
          />
          {this.props.authStore.user?.role === "SUPER" ||
          this.props.authStore.user?.role === "OPERATOR" ? (
            <TrashIcon
              className="w-5 h-5 ml-2 text-emerald-500 hover:text-emerald-600 cursor-pointer"
              onClick={this.handleSelectPolygon.bind(this, index, false)}
            />
          ) : (
            <></>
          )}
        </div>
      );
      return null;
    });

    return (
      <div className="bg-white rounded-md">
        <div
          className={`flex flex-row items-center px-2 py-2 ${
            this.state.collapse ? "border-b" : ""
          } cursor-pointer`}
          onClick={this.handleCollapse}
        >
          <MapIcon className="w-5 h-5" />
          <h3 className="text-md font-medium ml-2">Polygon</h3>
          <div className="grow" />
          {this.state.collapse ? (
            <ChevronUpIcon className="w-5 h-5 text-gray-400" />
          ) : (
            <ChevronDownIcon className="w-5 h-5 text-gray-400" />
          )}
        </div>
        {this.state.collapse && (
          <>
            {polygonList.length > 0 ? (
              polygonList
            ) : (
              <div className="px-4 py-4 w-full text-center">
                Please click on the map to draw a polygon
              </div>
            )}
            <div className="flex flex-row items-center justify-center px-2 py-2 cursor-pointer bg-white mb-4 rounded-md text-emerald-500 hover:text-emerald-600 border-t border-gray-100 mb-4">
              <div className="flex flex-row" onClick={this.handleUploadInput}>
                <UploadIcon className="w-5 h-5 mt-1 mb-2" />
                <h3 className="text-md font-medium ml-2">Upload KML</h3>
                <div className="grow" />
              </div>
              <input
                id="kmlFile"
                style={{ border: "none", display: "none" }}
                type="file"
                accept=".kml"
                onChange={this.handleUploadChange}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  renderLegend = () => {
    return (
      <div className="bg-white rounded-md">
        <div
          className={`flex flex-row items-center px-2 py-2 ${
            this.state.collapse ? "border-b" : ""
          } cursor-pointer`}
          onClick={this.handleCollapse}
        >
          <h3 className="text-md font-medium ml-2">Color Legend</h3>
          <div className="grow" />
        </div>

        {Object.keys(GeoPolygonColor).map((eachValue, index) => (
          <div
            className="flex flex-row items-center px-4 py-0.5 w-full"
            key={index}
          >
            <div
              className="h-2 w-2 rounded-full"
              style={{
                backgroundColor:
                  GeoPolygonColor[eachValue as keyof typeof GeoPolygonColor],
              }}
            />
            <label className="ml-2">
              {renderPolygonColorStatus(
                eachValue as keyof typeof GeoPolygonColor
              )}
            </label>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const polygonDeletion =
      this.state.selectedPolygonIndex >= 0 && !this.state.polygonModalVisible;
    return (
      <>
        <Overlay
          loading={this.state.loading}
          text={"Please wait while we are creating the project"}
        />
        <ConfirmationModal
          content={`Are you sure that you want to remove Polygon - ${
            polygonDeletion
              ? `${
                  this.state.projectStateAttribute.geoList[
                    this.state.selectedPolygonIndex
                  ].properties.name
                }`
              : ""
          }`}
          onClick={this.handleRemovePolygon.bind(
            this,
            this.state.selectedPolygonIndex
          )}
          onClose={this.handleSelectPolygon.bind(this, -1, false)}
          title="Delete Polygon"
          open={polygonDeletion ? true : false}
        />
        <UploadImageModal
          authStore={this.props.authStore}
          projectStateAttribute={this.state.projectStateAttribute}
          visible={this.state.uploadImageModalVisible}
          onClose={this.handleUpdateModalVisible.bind(
            this,
            "uploadImageModalVisible",
            false
          )}
          handleSubmit={this.handleOnChange}
        />

        <CreateProjectCard
          isModal={true}
          modalVisible={this.state.createProjectVisible}
          imageOverlayVisible={false}
          authStore={this.props.authStore}
          projectStateAttribute={this.state.projectStateAttribute}
          projectStateAttributeError={this.state.projectStateAttributeError}
          handleOnChange={this.handleOnChange}
          handleUploadChange={() => {}}
          handleNavigatePage={this.props.handleNavigatePage}
          handleAddressChange={this.handleAddressChange}
          handleSubmit={this.handleSubmit}
          handleZoomToOverlay={() => {}}
        />
        <PolygonCard
          projectId={this.state.projectStateAttribute._id}
          visible={this.state.polygonModalVisible}
          authStore={this.props.authStore}
          utilsStore={this.props.utilsStore}
          geoPolygon={
            this.state.selectedPolygonIndex >= 0
              ? this.state.projectStateAttribute.geoList[
                  this.state.selectedPolygonIndex
                ].properties
              : null
          }
          handleUpdateModalVisible={this.handleUpdateModalVisible}
          handleSubmitPolygonCard={this.handleSubmitPolygonCard}
        />

        <div className="z-10 absolute left-2 top-0 w-72 space-y-5 rounded-lg overflow-y-auto max-h-screen flex flex-col sm:right-2 sm:left-auto scrollBar">
          <div className="mt-2">
            {!this.state.creation && (
              <CreateProjectCard
                isModal={false}
                modalVisible={false}
                imageOverlayVisible={this.state.imageOverlayVisible}
                authStore={this.props.authStore}
                projectStateAttribute={this.state.projectStateAttribute}
                projectStateAttributeError={
                  this.state.projectStateAttributeError
                }
                handleOnChange={this.handleOnChange}
                handleUploadChange={this.handleUpdateModalVisible.bind(
                  this,
                  "uploadImageModalVisible",
                  true
                )}
                handleNavigatePage={this.props.handleNavigatePage}
                handleDisplayImageOverlay={this.handleDisplayImageOverlay}
                handleAddressChange={this.handleAddressChange}
                handleSubmit={this.handleSubmit}
                handleZoomToOverlay={this.handleZoomToOverlay}
              />
            )}
            <div className="my-2">{this.renderDrawingManager()}</div>
            <div className="my-2">{this.renderLegend()}</div>
          </div>
        </div>
        <div className="min-h-screen max-h-screen h-auto" ref={this.map}></div>
      </>
    );
  }
}
