import { Dialog, Transition } from "@headlessui/react";
import {
  IdentificationIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/outline";
import React, { Component, Fragment } from "react";
import { getUserAccessList } from "../../store/actions/userActions";
import { Role, UserModel } from "../model/User";
import Button from "./Button";
import { Option } from "./Select";
import SmartSelect from "./SmartSelect";

interface Props {
  id: string;
  visible: boolean;
  title?: string;
  value: string[];
  placeholder?: string;
  className?: string;
  error?: string;
  onChange: (id: string, value: string | string[]) => void;
  onClose: (bool: boolean) => void;
}

interface State {
  valuePlaceholder: string;
  onFocus: boolean;
  options: Option[];
  userList: UserModel[];
}

export default class ShareToModal extends Component<Props> {
  typingTimeout: ReturnType<typeof setTimeout> | undefined;
  private initiFocusDiv = React.createRef<HTMLDivElement>();

  state: State = {
    valuePlaceholder: "",
    onFocus: false,
    userList: [],
    options: [],
  };

  componentDidMount = () => {
    this.handleGetUserAccessList(this.props.value);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
      this.handleGetUserAccessList(this.props.value);
    }
  };

  handleOnChange = (id: string, value: string | string[]) => {
    this.props.onChange(id, value);
    if (Array.isArray(value)) {
      this.handleGetUserAccessList(value);
    }
  };

  handleOnSelectOnChange = (key: string) => {
    let newSelectedList = this.props.value?.length > 0 ? this.props.value : [];
    if (this.props.value?.includes(key)) {
      newSelectedList.splice(newSelectedList.indexOf(key), 1);
      this.props.onChange(this.props.id, newSelectedList);
    } else {
      newSelectedList?.push(key);
      this.props.onChange(this.props.id, newSelectedList);
    }
    this.handleGetUserAccessList(newSelectedList);
  };

  handleGetUserAccessList = async (id: string[]) => {
    if (id.length > 0) {
      const userData = await getUserAccessList(id);
      if (userData.length > 0) {
        this.props.value.map((eachId) => {
          if (
            !userData.some((eachUser: UserModel) => {
              return eachUser._id === eachId;
            })
          ) {
            const newShareList = this.props.value ?? [];
            newShareList.splice(newShareList.indexOf(eachId), 1);
            this.props.onChange(this.props.id, newShareList);
          }
          return null;
        });
        this.setState({
          userList: userData,
        });
      }
    }
  };

  renderAccessUser = () => {
    let userList: JSX.Element[] = [];
    this.state.userList.map((eachUser: UserModel, index: number) => {
      userList.push(
        <div className="flex justify-center items-center" key={eachUser._id}>
          <div className="p-2 rounded-full inline-flex items-center justify-center text-gray-400 bg-emerald-500">
            {eachUser.role === "OPERATOR" ? (
              <IdentificationIcon className="w-5 h-5 text-white" />
            ) : (
              <UserIcon className="w-5 h-5 text-white" />
            )}
          </div>
          <div className="flex flex-col ml-3">
            <p className="font-medium">
              {eachUser.name} ( {Role[eachUser.role]} )
            </p>
            <span className="text-sm text-gray-500">{eachUser.email}</span>
          </div>
          <div className="grow" />
          <TrashIcon
            className="w-5 h-5 ml-2 text-emerald-500 hover:text-emerald-600 cursor-pointer"
            onClick={this.handleOnSelectOnChange.bind(this, eachUser._id)}
          />
        </div>
      );
      return null;
    });
    return userList;
  };

  render() {
    return (
      <Transition.Root show={this.props.visible} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={this.props.onClose}
          initialFocus={this.initiFocusDiv}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div
              className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
              ref={this.initiFocusDiv}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="absolute bg-white rounded-lg px-4 pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                  <div className="flex flex-row">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Sharing Project
                    </h3>
                  </div>
                  <SmartSelect
                    id="sharedTo"
                    className="mt-2"
                    placeholder="Email"
                    value={this.props.value}
                    onChange={this.handleOnChange}
                  />
                  {this.props.value.length > 0 && (
                    <div className="mt-5 w-full">
                      <p className="text-md font-medium text-gray-900">
                        People with access
                      </p>
                      <div className="space-y-2 mt-2">
                        {this.renderAccessUser()}
                      </div>
                    </div>
                  )}

                  <div className="mt-5 sm:flex sm:flex-row-reverse">
                    <Button
                      text="Share"
                      className="ml-5"
                      type="normal"
                      onClick={this.props.onClose.bind(this, false)}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
