import { MarkerClusterer } from "@googlemaps/markerclusterer";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import AddressInput, { Address } from "../components/base/AddressInput";
import MarkerDetailsModal from "../components/base/MarkerDetailsModal";
import { withRouter } from "../navigator/NavigateWrapper";
import { getAllProjectLocation } from "../store/actions/projectActions";
import { getAllSensorLocation } from "../store/actions/sensorActions";
import { authStateInterface } from "../store/reducers/authReducer";

interface Marker {
  _id: string;
  location: Address;
  type: string;
  sensorType?: string;
}

interface State {
  location: Address;
  markers: Marker[];
  selectedMarker: {
    _id: string;
    type: string;
    sensorType: string;
  };
  modalVisible: boolean;
}

interface Props {
  navigate: any;
  authStore: authStateInterface;
}

class Mapview extends Component<Props> {
  private map = createRef<HTMLDivElement>();
  private googleMap: any = "";
  private markerList: any = [];

  state: State = {
    location: {
      lat: 0,
      lng: 0,
      name: "",
    },
    markers: [],
    selectedMarker: {
      _id: "",
      type: "",
      sensorType: "",
    },
    modalVisible: false,
  };

  componentDidMount = async () => {
    this.handleClearMarker();
    this.handleInitGoogleMap();
    this.handleGetAllMarkerLocation();
  };

  componentWillUnmount = () => {
    this.handleClearMarker();
  };

  handleModalVisble = (visible: boolean) => {
    this.setState({
      modalVisible: visible,
    });
  };

  handleInitGoogleMap = () => {
    this.googleMap = new window.google.maps.Map(this.map.current, {
      center: new window.google.maps.LatLng(4.1423, 102.0619),
      zoom: 8,
      disableDefaultUI: true,
      mapTypeId: "satellite",
    });
  };

  handleClearMarker = () => {
    if (this.markerList.length > 0) {
      this.markerList.map((eachMarker: any) => {
        window.google.maps.event.clearListeners(eachMarker, "click");
        return null;
      });
      this.markerList = [];
    }
  };

  handleGetAllMarkerLocation = async () => {
    const sensorData = await getAllSensorLocation(
      this.props.authStore.user?._id ?? ""
    );
    const projectData = await getAllProjectLocation(
      this.props.authStore.user?._id ?? ""
    );
    sensorData.map((eachSensor: any) => {
      eachSensor["sensorType"] = eachSensor.type;
      eachSensor["type"] = "sensor";
      return "";
    });
    projectData.map((eachProject: any) => {
      eachProject["type"] = "project";
      return "";
    });
    const markerList = sensorData.concat(projectData);
    this.setState(
      {
        markers: markerList,
      },
      () => {
        this.handleRenderMarker();
      }
    );
  };

  handleRenderMarker = () => {
    this.handleClearMarker();
    const map = this.googleMap;
    this.state.markers.map((eachMarker) => {
      const marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          eachMarker.location.lat,
          eachMarker.location.lng
        ),
        map: this.googleMap,
        icon:
          eachMarker.type === "project"
            ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
            : "http://maps.google.com/mapfiles/ms/icons/red-pushpin.png",
      });

      marker.addListener("click", () => {
        this.handleModalVisble(true);
        this.setState({
          selectedMarker: {
            _id: eachMarker._id,
            type: eachMarker.type,
            sensorType: eachMarker.sensorType ?? "",
          },
        });
      });
      this.markerList.push(marker);
      return "";
    });
    new MarkerClusterer({ map, markers: this.markerList });
  };

  handleNavigateProjectDetails = () => {
    if (this.state.selectedMarker.type === "project") {
      this.props.navigate(
        `/dashboard/createProject?id=${this.state.selectedMarker._id}`
      );
    } else {
      if (this.state.selectedMarker.sensorType === "WEATHER") {
        this.props.navigate(
          `/dashboard/weatherDetail?id=${this.state.selectedMarker._id}`
        );
      } else {
        this.props.navigate(
          `/dashboard/soilDetail?id=${this.state.selectedMarker._id}`
        );
      }
    }
  };

  handleAddressChange = (address: Address) => {
    this.setState(
      {
        location: address,
      },
      () => {
        this.handleZoomToLocation();
      }
    );
  };

  handleZoomToLocation = () => {
    if (this.state.location.lat !== 0 && this.state.location.lng !== 0) {
      this.googleMap.setZoom(15);
      this.googleMap.setCenter(
        new window.google.maps.LatLng(
          this.state.location.lat,
          this.state.location.lng
        )
      );
    }
  };

  render() {
    return (
      <>
        <div className="absolute top-5 left-5 z-50">
          <AddressInput
            id="location"
            disableTitle={true}
            onChange={this.handleAddressChange}
            value={this.state.location}
            error={""}
          />
        </div>
        <MarkerDetailsModal
          onClick={this.handleNavigateProjectDetails}
          authStore={this.props.authStore}
          onClose={this.handleModalVisble.bind(this, false)}
          selectedMarker={this.state.selectedMarker}
          open={this.state.modalVisible}
        />
        <div className="min-h-screen max-h-screen h-auto" ref={this.map}></div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(withRouter(Mapview));
