export enum TaskMode {
  SPRAY = "Spraying",
  MAP = "Mapping",
  SPREAD = "Spreading",
  OTHER = "Other",
}

export enum DroneType {
  XAGR150A = "XAG R150 A",
  XAGR150B = "XAG R150 B",
  XAGP40A = "XAG P40 A",
  XAGP40B = "XAG P40 B",
  XAGV40A = "XAG V40 A",
  XAGV40B = "XAG V40 B",
  XAGXP2020A = "XAG XP2020 A",
  DJIPHANTOM4RTKA = "DJI PHANTOM 4RTK A",
  DJIPHANTOM4RTKB = "DJI PHANTOM 4RTK B",
  DJIMINI2 = "DJI MINI 2",
  DJIMAVICPRO = "DJI MAVIC PRO",
  OTHER = "Other",
}

/**
 * @collection Task
 * @description Task of LesQ SPP Dashboard
 *
 * _id - Generated UUID v4
 * date - Date of task
 * location - Location of task
 * manPower: Total number of man power required for the task
 * purpose: Purpose of LesQ task
 * droneModel: Type of drone
 * droneModelOther: Other type of drone
 * mode: Type of task mode
 * modeOther : Other type of mode
 * flightSpeed: Flight speed (Eg Entry:3ms, Operation:7ms, Exit:3ms)
 * flightHeight: Flight height (min)
 * dosage: Dosage
 * atomization: Atomization (micron)
 * routeInterval: Route interval of the task (m)
 * boundarySafetyDistance: Boundary Safety Distance of the task (m)
 * obstacleSafetyDistance: Obstacle Safety Distance of the task (m)
 * areaCover: Area cover in (acre)
 * batteryUsed: Number of battery used (unit)
 * chargingCycle: Charging cycle of the battery
 * totalChemicalUsed: Volume of the chemical used
 * typeOfChemicalUsed: Type of checmical used
 * operationHours: Operation hours (Eg 8.30 AM - 12.00 PM)
 * flightTime: Flight time (Eg F1: 10.00 AM, F2: 11.00 AM, F3: 12.00 PM)
 * totalDownTime: Total down time (hours)
 * limitation: Limitation of the task
 * conclusion: Conclusion of the task
 * recommendation: Recommendation for the task
 * completed - Whether mapping is completed
 * projectId - Project ID linked for this tasks
 * polygonId - Polygon ID of Project linked for this tasks
 */

export interface Task {
  _id: string;
  date: Date;
  location: string;
  manPower: number;
  purpose: string;
  droneModel: keyof typeof DroneType;
  droneModelOther?: string;
  mode: keyof typeof TaskMode;
  modeOther?: string;
  flightSpeed: string;
  flightHeight: number;
  dosage: number;
  atomization: number;
  routeInterval: number;
  boundarySafetyDistance: number;
  obstacleSafetyDistance: number;
  areaCover: number;
  batteryUsed: number;
  chargingCycle: number;
  totalChemicalUsed: number;
  typeOfChemicalUsed: string;
  operationHours: string;
  flightTime: string;
  totalDownTime: number;
  limitation: string;
  conclusion: string;
  recommendation: string;
  attachmentToken: string;
  createdBy: string;
  completed: boolean;
  projectId: string;
  polygonId: string[];
}
