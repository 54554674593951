import { Component } from "react";
import { connect } from "react-redux";
import { authStateInterface } from "../store/reducers/authReducer";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import { indexedDb } from "../utils/indexedDB";
import { withRouter } from "./NavigateWrapper";

interface Props {
  utilsStore: utilsStateInterface;
  authStore: authStateInterface;
  location: any;
  navigate: any;
}

class SecurityNavigator extends Component<Props> {
  componentDidMount = () => {
    this.handleValidateLoginCondition();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      prevProps.authStore.userLoading !== this.props.authStore.userLoading ||
      prevProps.authStore.userAuth !== this.props.authStore.userAuth ||
      prevProps.authStore.user !== this.props.authStore.user ||
      prevProps.utilsStore.onlineStatus !== this.props.utilsStore.onlineStatus
    ) {
      this.handleValidateLoginCondition();
    }
  };

  handleValidateLoginCondition = async () => {
    switch (this.props.utilsStore.onlineStatus) {
      case true:
        const newTask = await indexedDb.getValue("local", "update");
        if (newTask) {
          this.props.navigate("/dashboard/tasks");
        } else {
          if (
            !this.props.authStore.userAuth &&
            this.props.location.pathname !== "/"
          ) {
            this.props.navigate("/");
          } else if (
            this.props.authStore.userAuth &&
            this.props.location.pathname === "/"
          ) {
            this.props.navigate("/dashboard");
          }
        }
        break;
      case false:
        const user = await indexedDb.getValue("local", "user");
        if (user) {
          this.props.navigate("/dashboard/tasks");
        } else {
          this.props.navigate("/");
        }
        break;
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(withRouter(SecurityNavigator));
