import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import React, { Component } from "react";
import IconButton from "./IconButton";

export interface TableHeader {
  key: string;
  title: string;
}

interface Props {
  header: TableHeader[];
  data: Object[];
  size: number;
  pageIndex: number;
  className?: string;
  loading?: boolean;
  newTabUrl?: string;
  hideFooter?: boolean;
  hidePagination?: boolean;
  handlePage?: (e: any) => void;
  onClickRow?: (e: any) => void;
}

const tableDataLimit = 10;
const tablePageSize = 5;

export default class Table extends Component<Props> {
  handleClick = (id: string, event: any) => {
    if (this.props.onClickRow) {
      this.props.onClickRow(id);
    }
    event.preventDefault();
  };

  renderTableHeader = () => {
    let renderHeader: any = [];
    this.props.header.map((eachHeader) => {
      if (eachHeader.key !== "id") {
        renderHeader.push(
          <th
            key={eachHeader.key}
            className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            {eachHeader.title}
          </th>
        );
      }
      return null;
    });
    return renderHeader;
  };

  renderTableBody = () => {
    let renderBody: any = [];
    if (this.props.loading) {
      renderBody.push(
        <tr key="dataLoading">
          <td className="w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">
            <div className="flex">
              <p className="group inline-flex space-x-2 truncate text-sm text-gray-500 truncate">
                Loading...
              </p>
            </div>
          </td>
        </tr>
      );
    } else if (this.props.data.length === 0) {
      renderBody.push(
        <tr key="noData">
          <td className="w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">
            <div className="flex">
              <p className="group inline-flex space-x-2 truncate text-sm text-gray-500 truncate">
                No Data Available
              </p>
            </div>
          </td>
        </tr>
      );
    } else {
      this.props.data.map((eachData: any, index: any) => {
        renderBody.push(
          <tr
            key={eachData.id}
            onClick={this.handleClick.bind(this, eachData.id)}
            className={
              this.props.onClickRow ? "cursor-pointer hover:bg-gray-100" : ""
            }
          >
            {this.renderRowData(eachData, index)}
          </tr>
        );
        return null;
      });
    }

    return renderBody;
  };

  renderRowData = (eachData: any, index: number) => {
    const renderRowData: any = [];
    const tableId = eachData.id;
    this.props.header.map((eachHeader, colIndex) => {
      if (eachHeader.key !== "id") {
        const value = eachData[eachHeader.key];
        if (this.props.onClickRow) {
          renderRowData.push(
            <td
              key={eachHeader.key + String(index)}
              className={`ax-w-0 w-full px-6 py-4 whitespace-nowrap text-sm ${
                colIndex === 1 ? "text-green-600 font-bold" : "text-gray-900"
              } `}
            >
              <a href={this.props.newTabUrl + tableId} className="w-full block">
                {value}
              </a>
            </td>
          );
        } else {
          renderRowData.push(
            <td
              key={eachHeader.key + String(index)}
              className="ax-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900"
            >
              {value}
            </td>
          );
        }
      }
      return null;
    });
    return renderRowData;
  };

  renderPageNumber = () => {
    let pageNumberList: JSX.Element[] = [];
    let pageOffset = Math.floor(this.props.pageIndex / tablePageSize);
    for (
      let i = pageOffset * tablePageSize;
      i * tableDataLimit < this.props.size &&
      i < pageOffset * tablePageSize + tablePageSize;
      i++
    ) {
      let pageNumberClassName =
        "relative items-center px-3 py-2 border text-sm font-medium cursor-pointer sm:px-4";
      if (this.props.pageIndex === i) {
        pageNumberClassName += " bg-green-50 border-green-500 text-green-600";
      } else {
        pageNumberClassName +=
          " border-gray-300 bg-white text-gray-500 hover:text-gray-500 hover:bg-gray-100 bg-white";
      }
      pageNumberList.push(
        <span
          aria-current="page"
          className={pageNumberClassName}
          onClick={
            this.props.pageIndex !== i
              ? this.props.handlePage?.bind(this, i)
              : () => {}
          }
        >
          {i + 1}
        </span>
      );
    }
    return pageNumberList;
  };

  renderPagination = () => {
    const paginationClassName =
      "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500";
    const nextDataAvailable =
      (this.props.pageIndex + 1) * tableDataLimit < this.props.size;
    return (
      <nav
        className="relative inline-flex rounded-md shadow-sm -space-x-px"
        aria-label="Pagination"
      >
        <IconButton
          disabled={this.props.pageIndex === 0 ? true : false}
          icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
          className={`${
            this.props.pageIndex === 0
              ? "cursor-not-allowed"
              : "hover:bg-gray-50"
          } ${paginationClassName} rounded-l-md`}
          onClick={this.props.handlePage?.bind(this, this.props.pageIndex - 1)}
        />
        {this.renderPageNumber()}
        <IconButton
          disabled={nextDataAvailable ? false : true}
          icon={<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />}
          className={`${
            nextDataAvailable ? "hover:bg-gray-50" : "cursor-not-allowed"
          } ${paginationClassName} rounded-r-md`}
          onClick={this.props.handlePage?.bind(this, this.props.pageIndex + 1)}
        />
      </nav>
    );
  };

  render() {
    let defaultClassName = "block";
    if (this.props.className) {
      defaultClassName += ` ${this.props.className}`;
    }

    return (
      <div className={defaultClassName}>
        <div className="flex flex-col mt-2">
          <div className="scrollBar align-middle min-w-full overflow-x-auto shadow overflow-hidden bg-white sm:rounded-t-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>{this.renderTableHeader()}</tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {this.renderTableBody()}
              </tbody>
            </table>
          </div>
          {!this.props.hideFooter && (
            <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 sm:rounded-b-lg">
              <div className="flex-1 flex flex-col sm:items-center sm:justify-between sm:flex-row">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">
                      {this.props.size}{" "}
                      {this.props.size > 1 ? "results" : "result"}
                    </span>
                  </p>
                </div>
                {!this.props.hidePagination && (
                  <div className="mt-3 sm:mt-0">{this.renderPagination()}</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
