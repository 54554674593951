import { GeoPolygonColor } from "../../components/model/Project";

export const updateOnlineStatus = (status: boolean) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_ONLINE_STATUS",
      payload: {
        onlineStatus: status,
      },
    });
  };
};

export const updateUnits = (units: string) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_UNITS",
      payload: {
        units: units,
      },
    });
  };
};

export const renderPolygonColorStatus = (
  color: keyof typeof GeoPolygonColor
) => {
  let status = "";
  switch (color) {
    case "RED":
      status = "Restricted";
      break;
    case "BLUE":
      status = "Completed";
      break;
    case "YELLOW":
      status = "On-going";
      break;
    case "PURPLE":
      status = "Future";
      break;
    default:
      status = "Default";
      break;
  }
  return status;
};
