import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import Button from "../base/Button";
import Select from "../base/Select";

interface Props {
  open: boolean;
  title: string;
  startDate: Date;
  endDate: Date;
  dateRangeError: string;
  startTime: string;
  endTime: string;
  timeRangeError: string;
  onChange: (e: any, id: string) => void;
  onClick: () => void;
  onClose: () => void;
}

interface State {
  date: Date | null;
}

export default class DateRangeModal extends Component<Props> {
  state: State = {
    date: null,
  };

  renderTimeOptions = () => {
    const fromTime = moment().startOf("day");
    const toTime = moment().endOf("day");
    const duration = moment.duration(toTime.diff(fromTime));
    const diff = duration.hours();
    const timeList = [];

    for (let i = 0; diff >= i; i++) {
      let result = moment(fromTime).add(i, "hours").format("HH:mm");
      timeList.push({
        title: result,
        key: result,
      });
    }
    return timeList;
  };

  render() {
    return (
      <Transition.Root show={this.props.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-10 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg sm:p-6">
                <div>
                  <div className="border-b border-gray-200 pb-4">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Select Date Range
                    </Dialog.Title>
                  </div>
                  <div className="grid grid-cols-1 gap-5 pb-80 mt-4 sm:grid-cols-2">
                    <div className="flex flex-col">
                      <div className="font-medium text-sm  m-1">Start Date</div>
                      <DatePicker
                        dateFormat="dd/MM/yy"
                        selected={this.props.startDate}
                        onChange={(date: Date) => {
                          this.props.onChange(date, "startDate");
                        }}
                        selectsStart
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        nextMonthButtonLabel=">"
                        previousMonthButtonLabel="<"
                      />
                    </div>
                    <div className="flex flex-col">
                      <div className="font-medium text-sm  m-1 ">End Date</div>
                      <DatePicker
                        dateFormat="dd/MM/yy"
                        selected={this.props.endDate}
                        onChange={(date: Date) => {
                          this.props.onChange(date, "endDate");
                        }}
                        selectsStart
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        nextMonthButtonLabel=">"
                        previousMonthButtonLabel="<"
                      />
                      <span className="text-red-500 text-sm">
                        {this.props.dateRangeError}
                      </span>
                    </div>
                    {moment(this.props.startDate).format("DD/MM/YYYY") ===
                      moment(this.props.endDate).format("DD/MM/YYYY") && (
                      <>
                        <div className="flex flex-col">
                          <div className="font-medium text-sm">Start Time</div>
                          <Select
                            id="startTime"
                            placeholder="Start Time"
                            options={this.renderTimeOptions()}
                            value={this.props.startTime}
                            onChange={(id: string, key: string) => {
                              this.props.onChange(key, id);
                            }}
                          />
                        </div>
                        <div className="flex flex-col">
                          <div className="font-medium text-sm">End Time</div>
                          <Select
                            id="endTime"
                            placeholder="End Time"
                            options={this.renderTimeOptions()}
                            value={this.props.endTime}
                            onChange={(id: string, key: string) => {
                              this.props.onChange(key, id);
                            }}
                          />
                          <span className="text-red-500 text-sm">
                            {this.props.timeRangeError}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex flex-row justify-end">
                    <Button
                      size="small"
                      text="Cancel"
                      className="mr-4"
                      type="light"
                      onClick={this.props.onClose}
                    ></Button>
                    <Button
                      size="small"
                      text={"Confirm"}
                      type="normal"
                      onClick={this.props.onClick}
                    ></Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
