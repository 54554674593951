import {
  BellIcon,
  BookOpenIcon,
  CloudUploadIcon,
  CurrencyDollarIcon,
  EyeIcon,
  SparklesIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import Button from "../components/base/Button";
import ConfirmationModal from "../components/base/ConfirmationModal";
import Input from "../components/base/Input";
import Overlay from "../components/base/Overlay";
import Table, { TableHeader } from "../components/base/Table";
import EmailVerifyModal from "../components/dashboard/EmailVerifyModal";
import { DashboardPage } from "../components/dashboard/LeftNav";
import { ServiceType } from "../components/model/Project";
import ProjectAnalyticsModel from "../components/model/ProjectAnalytics";
import {
  clearProjects,
  deleteProject,
  getProjectAnalytics,
  getProjectsWithPagination,
} from "../store/actions/projectActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { projectStateInterface } from "../store/reducers/projectReducer.ts";
import { utilsStateInterface } from "../store/reducers/utilsReducer";

interface Props {
  projectStore: projectStateInterface;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
  getProjectsWithPagination: (search: string, skip: number) => void;
  clearProjects: () => void;
  handleNavigatePage: (page: DashboardPage, id?: string) => void;
}

interface State {
  pageIndex: number;
  searchText: string;
  loading: boolean;
  selectedProject: {
    _id: string;
    name: string;
  };
  analytics: ProjectAnalyticsModel;
  verify: boolean;
}

class Home extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;

  state: State = {
    pageIndex: 0,
    searchText: "",
    loading: false,
    selectedProject: {
      _id: "",
      name: "",
    },
    analytics: {
      _id: "",
      completedArea: 0,
      pesticidesUsed: 0,
      flyingDuration: 0,
      ongoingOps: 0,
      segmentsArea: {
        mapping: 0,
        seedling: 0,
        spraying: 0,
      },
      createdDate: new Date(),
      dateString: "",
      dateStringWithTime: "",
      userId: "",
    },
    verify: true,
  };

  componentDidMount = async () => {
    this.handleCheckEmailVerified();
    await this.handleGetPreviewData();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      JSON.stringify(prevProps.authStore.user) !==
      JSON.stringify(this.props.authStore.user)
    ) {
      this.props.clearProjects();
      this.handleGetPreviewData();
    }

    if (
      JSON.stringify(prevProps.authStore.userAuth) !==
      JSON.stringify(this.props.authStore.userAuth)
    ) {
      this.handleCheckEmailVerified();
    }
  };

  componentWillUnmount = () => {
    this.props.clearProjects();
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = null;
    }
  };

  handleCheckEmailVerified = () => {
    // if (this.props.authStore.userAuth) {
    //   this.setState({
    //     verify: this.props.authStore.userAuth.emailVerified,
    //   });
    // }
  };

  handleGetPreviewData = async () => {
    if (this.props.authStore.user) {
      this.props.getProjectsWithPagination(
        this.state.searchText,
        this.state.pageIndex
      );
      const analyticsData = await getProjectAnalytics(
        this.props.authStore.user?._id ?? ""
      );
      this.setState({
        analytics: analyticsData.data,
      });
    }
  };

  handleFilter = (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.searchTimeout = setTimeout(() => {
        this.handleUpdatePageIndex(0);
      }, 500);
    });
  };

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex }, () => {
      this.handleGetPreviewData();
    });
  };

  handleNavigateProject = (id: string) => {
    this.props.handleNavigatePage(DashboardPage.CREATEPROJECT, id);
  };

  handleExportReport = (id: string, e: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.props.handleNavigatePage(DashboardPage.REPORT, id);
  };

  handleUpdateSelectProject = (
    selectedProject: {
      _id: string;
      name: string;
    },
    e: any
  ) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({
      selectedProject,
    });
  };

  handleUpdateLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleRemoveProject = async () => {
    this.handleUpdateLoading(true);
    const projectDeleteResponse = await deleteProject(
      this.state.selectedProject._id
    );

    if (!projectDeleteResponse) {
      this.handleGetPreviewData();
    } else {
      window.alert("Unable to delete. Please try again later");
    }
    this.handleUpdateLoading(false);
  };

  renderFilteredData = () => {
    const acreUnit = this.props.utilsStore.units === "acre";
    const projectList: Object[] = [];
    let area = 0;
    if (this.props.projectStore.projects.length > 0) {
      this.props.projectStore.projects.map((eachProject) => {
        if (eachProject.geoList.length > 0) {
          eachProject.geoList.map((eachData: any) => {
            area += Number(eachData.properties.area);
            return "";
          });
        }
        projectList.push({
          id: eachProject._id,
          name: eachProject.name,
          serviceType: ServiceType[eachProject.service],
          cropsType: eachProject.crop,
          size: Number(area / (acreUnit ? 1 : 2.471)).toFixed(2),
          progress: eachProject.signature ? "Yes" : "No",
          export: (
            <div className="cursor-pointer ml-2">
              <BookOpenIcon
                className="h-5 w-5 block text-green-600"
                onClick={this.handleExportReport.bind(this, eachProject._id)}
              />
            </div>
          ),
          view: (
            <div className="cursor-pointer ml-2">
              <EyeIcon
                className="h-5 w-5 block text-green-600"
                onClick={this.handleNavigateProject.bind(this, eachProject._id)}
              />
            </div>
          ),
          remove: (
            <div className="cursor-pointer ml-2">
              <TrashIcon
                className="h-5 w-5 block text-green-600"
                onClick={this.handleUpdateSelectProject.bind(this, {
                  _id: eachProject._id,
                  name: eachProject.name,
                })}
              />
            </div>
          ),
        });
        return "";
      });
    }

    return projectList;
  };

  renderDashboardContent = () => {
    const acreUnit = this.props.utilsStore.units === "acre";
    const updated = moment(this.state.analytics.createdDate).format(
      "DD/MM/YY ha"
    );
    const content = [
      {
        icon: <CurrencyDollarIcon className="h-5 w-5 text-white" />,
        title: "Completed Ops",
        unit: "Total Tasks(s)",
        content: this.state.analytics.completedArea,
        viewBox: "0 0 24 24",
        fontSize: "text-lg",
        bgColor: "bg-emerald-400",
        textColor: "text-emerald-400",
        updated: updated,
      },
      {
        icon: <SparklesIcon className="h-5 w-5 text-white" />,
        title: "Pesticides Used",
        unit: "Total Litre(s)",
        content: Number(this.state.analytics.pesticidesUsed).toFixed(2),
        fontSize: "text-lg",
        viewBox: "0 0 24 24",
        inverted: true,
        bgColor: "bg-emerald-400",
        textColor: "text-emerald-400",
        updated: updated,
      },
      {
        icon: <CloudUploadIcon className="h-5 w-5 text-white" />,
        title: "Flying Area",
        unit: `Total Area(${acreUnit ? "acre" : "hectare"})`,
        content: Number(
          this.state.analytics.flyingDuration / (acreUnit ? 4047 : 10000)
        ).toFixed(2),
        fontSize: "text-lg",
        viewBox: "0 0 24 24",
        inverted: true,
        bgColor: "bg-emerald-400",
        textColor: "text-emerald-400",
        updated: updated,
      },
      {
        icon: <BellIcon className="h-5 w-5 text-white" />,
        title: "Ongoing Ops",
        unit: "Total Tasks(s)",
        content: this.state.analytics.ongoingOps,
        fontSize: "text-lg",
        viewBox: "0 0 24 24",
        inverted: true,
        bgColor: "bg-red-400",
        textColor: "text-red-400",
        updated: updated,
      },
    ];

    const contentViewList: any = [];

    content.map((eachContent, key: number) => {
      contentViewList.push(
        <div className="bg-white overflow-hidden shadow rounded-lg" key={key}>
          <div className="py-3 px-5">
            <div className="flex">
              <div>
                <div className="flex flex-row justify-center items-center">
                  <span
                    className={`flex p-2 mt-1 rounded-lg ${eachContent.bgColor}`}
                  >
                    {eachContent.icon}
                  </span>
                  <dt
                    className={`ml-2 text-lg ${eachContent.textColor} font-medium truncate`}
                  >
                    {eachContent.title}
                  </dt>
                </div>
                <div
                  className={`${eachContent.fontSize} px-2 text-2xl font-bold text-gray-900 mt-2`}
                >
                  {eachContent.content}
                </div>
                <span className={`px-2 text-xs text-gray-600 mt-2`}>
                  {eachContent.unit}
                </span>
                <div className={`px-2 text-xs text-gray-600`}>
                  Updated - {eachContent.updated}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      return null;
    });

    return contentViewList;
  };

  renderRadioChart = () => {
    const options = {
      labels: Object.keys(this.state.analytics.segmentsArea),
      plotOptions: {},
    };

    const series = Object.values(this.state.analytics.segmentsArea);

    return (
      <div className="flex flex-col justify-center bg-white overflow-hidden shadow rounded-lg mt-4 pl-4 pb-3 w-full lg:mt-0">
        <h3 className="ml-2 text-lg truncate font-medium my-4">
          Service Segments (Ha)
        </h3>
        <div className="w-80">
          <ReactApexChart options={options} series={series} type="donut" />
        </div>
      </div>
    );
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "ID",
      },
      {
        key: "name",
        title: "Name",
      },
      {
        key: "serviceType",
        title: "Service Type",
      },
      {
        key: "cropsType",
        title: "Crop Type",
      },
      {
        key: "size",
        title: `Size (${
          this.props.utilsStore.units === "acre" ? "Acre" : "Hectare"
        })`,
      },
      {
        key: "progress",
        title: "Completed",
      },
      {
        key: "view",
        title: "View",
      },
      {
        key: "export",
        title: "Report",
      },
    ];

    if (this.props.authStore.user) {
      const isAdmin = this.props.authStore.user.role === "SUPER";
      if (isAdmin) {
        headers.push({
          key: "remove",
          title: "Remove",
        });
      }
    }

    const headerComponent = {
      title: "List of Projects",
      desc: "Order from latest updated date",
      buttonText: "Add Project",
      buttonOnClick: this.handleNavigateProject.bind(this, "new"),
    };

    return (
      <div className="mt-8">
        <EmailVerifyModal
          visible={!this.state.verify}
          authStore={this.props.authStore}
        />
        <Overlay
          loading={this.state.loading}
          text="Please wait while we are processing your request"
        />
        <ConfirmationModal
          content={`Are you sure that you want to remove the project - ${this.state.selectedProject.name} ?`}
          onClick={this.handleRemoveProject}
          onClose={this.handleUpdateSelectProject.bind(this, {
            _id: "",
            name: "",
          })}
          title="Delete Project Confirmation"
          open={this.state.selectedProject._id ? true : false}
        />
        <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
          <div className="lg:flex lg:space-x-4 w-full">
            <div className="flex-shrink-0 lg:w-1/2">
              <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2">
                {this.renderDashboardContent()}
              </div>
            </div>
            {this.renderRadioChart()}
          </div>

          <div className="mt-5">
            <div className="flex flex-col py-2 sm:items-center sm:flex-row">
              <div className="flex flex-col">
                <p className="text-xl font-medium">{headerComponent.title}</p>
                <span className="text-sm text-gray-500">
                  {headerComponent.desc ?? ""}
                </span>
              </div>
              <div className="flex-1" />
              <div className="mt-3 sm:mt-0">
                <Input
                  placeholder="Search"
                  onChange={this.handleFilter}
                  value={this.state.searchText}
                />
              </div>
              {this.props.authStore.user?.role === "SUPER" && (
                <Button
                  className="my-3 sm:my-0 sm:ml-5"
                  text={headerComponent.buttonText}
                  type="normal"
                  onClick={headerComponent.buttonOnClick}
                />
              )}
            </div>
            <Table
              newTabUrl="/dashboard/createProject?id="
              pageIndex={this.state.pageIndex}
              header={headers}
              data={this.renderFilteredData()}
              size={this.props.projectStore.size}
              onClickRow={(id) => {
                this.handleNavigateProject(id);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    projectStore: state.projectStore,
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearProjects: () => dispatch(clearProjects()),
    getProjectsWithPagination: (search: string, skip: number) =>
      dispatch(getProjectsWithPagination(search, skip)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
