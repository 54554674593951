import { Option } from "../../components/base/Select";

export interface utilsStateInterface {
  onlineStatus: boolean;
  projectOpts: Option[];
  geoListOpts: Option[];
  units: string;
}

const initState: utilsStateInterface = {
  onlineStatus: navigator.onLine,
  projectOpts: [],
  geoListOpts: [],
  units: "hectare",
};

const utilsReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_ONLINE_STATUS":
      return {
        ...state,
        onlineStatus: action.payload.onlineStatus,
      };
    case "UPDATE_OFFLINE_PROJECT_OPTIONS":
      return {
        ...state,
        projectOpts: action.payload.projectOpts,
      };
    case "UPDATE_OFFLINE_GEO_OPTIONS":
      return {
        ...state,
        geoListOpts: action.payload.geoListOpts,
      };
    case "UPDATE_UNITS":
      return {
        ...state,
        units: action.payload.units,
      };
    default:
      return state;
  }
};

export default utilsReducer;
