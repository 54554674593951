import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/base/Button";
import Input from "../components/base/Input";
import Overlay from "../components/base/Overlay";
import { withRouter } from "../navigator/NavigateWrapper";
import SecurityNavigator from "../navigator/SecurityNavigator";
import {
  handleAuthCondition,
  resetPassword,
  signIn,
} from "../store/actions/authActions";
import { authStateInterface } from "../store/reducers/authReducer";
import packageJson from "./../../package.json";
import HomeBG from "./../images/home_bg.jpeg";
import Logo from "./../images/logo.png";

enum AuthType {
  LOGIN = "login",
  RESETPASSWORD = "reset",
}

export interface AdminState {
  email: string;
  password: string;
}

export interface AdminStateError {
  emailError: string;
  passwordError: string;
}

export interface AdminState {
  email: string;
  password: string;
}

export interface AdminStateError {
  emailError: string;
  passwordError: string;
}

interface State {
  loading: boolean;
  type: AuthType;
  adminState: AdminState;
  adminStateError: AdminStateError;
}

interface Props {
  navigate: any;
  location: any;
  authStore: authStateInterface;
}

class Login extends Component<Props> {
  state: State = {
    loading: false,
    type: AuthType.LOGIN,
    adminState: {
      email: "",
      password: "",
    },
    adminStateError: {
      emailError: "",
      passwordError: "",
    },
  };

  handleChangeAuthType = (type: AuthType) => {
    this.setState({
      type,
      adminStateError: {
        emailError: "",
        passwordError: "",
      },
    });
  };

  handleInputChange = (e: any) => {
    const clonedAdminState = JSON.parse(JSON.stringify(this.state.adminState));
    clonedAdminState[e.target.id] = e.target.value;
    this.setState({
      adminState: clonedAdminState,
    });
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleSubmit = async () => {
    const conditionsList: string[] = ["email"];

    if (this.state.type === AuthType.LOGIN) {
      conditionsList.push("password");
    }
    const clonedAdminError = JSON.parse(
      JSON.stringify(this.state.adminStateError)
    );

    handleAuthCondition(
      this.state.adminState,
      clonedAdminError,
      conditionsList
    );

    this.setState(
      {
        adminStateError: clonedAdminError,
      },
      async () => {
        if (this.state.type === AuthType.LOGIN) {
          if (
            !this.state.adminStateError.emailError &&
            !this.state.adminStateError.passwordError
          ) {
            this.handleLoading(true);
            const signInError = await signIn({
              email: this.state.adminState.email.replace(/\s/g, ""),
              password: this.state.adminState.password.replace(/\s/g, ""),
            });
            const clonedSignInError = JSON.parse(
              JSON.stringify(this.state.adminStateError)
            );
            clonedSignInError["emailError"] = signInError ? signInError : "";
            this.setState({
              adminStateError: clonedSignInError,
            });
            this.handleLoading(false);
          }
        } else if (this.state.type === AuthType.RESETPASSWORD) {
          if (!this.state.adminStateError.emailError) {
            this.handleLoading(true);
            const resetPasswordError = await resetPassword(
              this.state.adminState.email
            );
            const resetAdminError = JSON.parse(
              JSON.stringify(this.state.adminStateError)
            );
            if (resetPasswordError) {
              resetAdminError["emailError"] = resetPasswordError;
            } else {
              resetAdminError["emailError"] =
                "Password reset link has been sent to your email if you have previously signed up";
            }
            this.setState({
              adminStateError: resetAdminError,
            });
            this.handleLoading(false);
          }
        }
      }
    );
  };

  renderAuthForm() {
    if (this.state.type === AuthType.LOGIN) {
      return (
        <>
          <div>
            <div className="mt-1">
              <Input
                id="email"
                className="mt-1"
                placeholder="Email"
                value={this.state.adminState.email}
                error={this.state.adminStateError.emailError}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div>
            <div className="mt-1">
              <Input
                id="password"
                type="password"
                className="mt-1"
                placeholder="Password"
                value={this.state.adminState.password}
                error={this.state.adminStateError.passwordError}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <div className="mt-1">
              <Input
                id="email"
                className="mt-1"
                placeholder="Email"
                value={this.state.adminState.email}
                error={this.state.adminStateError.emailError}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </>
      );
    }
  }

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }

    return (
      <div className="min-h-screen bg-white flex font-body">
        <SecurityNavigator location={this.props.location} />
        <Overlay
          loading={this.state.loading}
          text="Please hold on while we are verifying your account"
        />
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="flex flex-col items-center justify-center">
              <img
                className="h-12 w-auto mr-2"
                src={Logo}
                alt="LesQ SPP Logo"
              />
              <h2 className="mt-4 text-3xl font-bold text-gray-900 font-display">
                Sistem Pertanian Pintar
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <div className="space-y-4">
                  {this.renderAuthForm()}
                  <div className="pt-4">
                    <Button
                      type="normal"
                      text={
                        this.state.type === AuthType.LOGIN
                          ? "Login"
                          : "Reset Password"
                      }
                      onClick={this.handleSubmit}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <span
                      className="text-md font-bold text-emerald-600 cursor-pointer hover:text-emerald-400"
                      onClick={this.handleChangeAuthType.bind(
                        this,
                        this.state.type === AuthType.LOGIN
                          ? AuthType.RESETPASSWORD
                          : AuthType.LOGIN
                      )}
                    >
                      {this.state.type === AuthType.LOGIN
                        ? "Forgot Your Password ? "
                        : "Remember Your Password ?"}
                    </span>
                  </div>
                  <span className="flex mt-4 justify-end">
                    Version {packageJson.version}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={HomeBG}
            alt=""
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(withRouter(Login));
