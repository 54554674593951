import { Dialog, Transition } from "@headlessui/react";
import { Component, Fragment } from "react";
import {
  handleSignOut,
  resendEmailVerification,
} from "../../store/actions/authActions";
import { authStateInterface } from "../../store/reducers/authReducer";
import Button from "../base/Button";

interface Props {
  visible: boolean;
  authStore: authStateInterface;
}

export default class EmailVerifyModal extends Component<Props> {
  handleResentEmailVerification = async () => {
    try {
      await resendEmailVerification();
      window.alert("Email verification sent!");
    } catch (err) {}
  };

  signOut = async () => {
    await handleSignOut();
  };

  render() {
    return (
      <Transition.Root show={this.props.visible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Email Verification
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Welcome to LesQ SPP (Sistem Pertanian Pintar). In
                          order to proceed, please verify your email. If you do
                          not receive any verification email, please click
                          "Resend" to resend the verification email.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 flex flex-row">
                    <div className="flex-grow" />
                    <Button
                      text="Logout"
                      type="light"
                      className="mr-5"
                      onClick={this.signOut}
                    />
                    <Button
                      text="Resend"
                      type="normal"
                      onClick={this.handleResentEmailVerification}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
