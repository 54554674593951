import { Transition } from "@headlessui/react";
import React, { Component } from "react";

interface Props {
  loading: boolean;
  text: string;
}
export default class Overlay extends Component<Props> {
  render() {
    if (this.props.loading) {
      return (
        <div
          className="fixed z-50 inset-0 overflow-y-auto"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-10 w-10 rounded-full border-4 border-t-4 border-emerald-100 loading-spinner" />
                <Transition
                  show={this.props.loading}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="mt-3 text-center sm:mt-5">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Loading
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{this.props.text}</p>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
