import { Dialog, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CubeTransparentIcon,
  EyeIcon,
  EyeOffIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import React, { Component, Fragment } from "react";
import { ProjectStateAttributeError } from "../../pages/CreateProject";
import { authStateInterface } from "../../store/reducers/authReducer";
import AddressInput, { Address } from "../base/AddressInput";
import Button from "../base/Button";
import Input from "../base/Input";
import Select, { Option } from "../base/Select";
import ShareToModal from "../base/ShareToModal";
import TextButton from "../base/TextButton";
import { Project, ServiceType } from "../model/Project";
import { DashboardPage } from "./LeftNav";

const serviceOptions: Option[] = Object.keys(ServiceType).map(
  (eachServiceKey) => {
    return {
      key: eachServiceKey,
      title: ServiceType[eachServiceKey as keyof typeof ServiceType],
    };
  }
);

interface Props {
  isModal: boolean;
  modalVisible: boolean;
  imageOverlayVisible: boolean;
  authStore: authStateInterface;
  projectStateAttribute: Project;
  projectStateAttributeError: ProjectStateAttributeError;
  handleOnChange: (id: string, value: string | string[]) => void;
  handleUploadChange: () => void;
  handleAddressChange: (address: Address) => void;
  handleNavigatePage: (page: DashboardPage) => void;
  handleDisplayImageOverlay?: (visible: boolean) => void;
  handleSubmit: (id: string, visible: boolean) => void;
  handleZoomToOverlay: () => void;
}

interface State {
  collapse: boolean;
  shareUserVisible: boolean;
}

export default class CreateProjectCard extends Component<Props> {
  state: State = {
    collapse: true,
    shareUserVisible: false,
  };

  handleUpdateModal = (shareUserVisible: boolean) => {
    this.setState({
      shareUserVisible,
    });
  };

  handleCollapse = () => {
    const collapse = !this.state.collapse;
    this.setState({
      collapse,
    });
  };

  handleOnChange = (e: any) => {
    this.props.handleOnChange(e.target.id, e.target.value);
  };

  handleSubmit = () => {
    if (this.props.handleSubmit) {
      this.props.handleSubmit("createProjectVisible", false);
    }
  };

  handleBack = () => {
    this.props.handleNavigatePage(DashboardPage.PROJECTS);
  };

  renderProjectForm = () => {
    const collapse = this.props.isModal ? true : this.state.collapse;
    const isAdmin =
      this.props.authStore.user?.role === "SUPER" ||
      this.props.authStore.user?.role === "OPERATOR";

    const containerStyle = isAdmin ? "w-full" : "w-full pb-3 border-b-2";
    return (
      <>
        <ShareToModal
          id="sharedTo"
          visible={this.state.shareUserVisible}
          onChange={this.props.handleOnChange}
          onClose={this.handleUpdateModal}
          value={this.props.projectStateAttribute.sharedTo ?? ""}
        />
        {collapse && (
          <div className="flex flex-col space-y-2 mx-4 mt-2 pb-5">
            <span className="w-36 inline-flex items-center text-md text-gray-800">
              Name :
            </span>
            <div className={containerStyle}>
              {isAdmin ? (
                <Input
                  id="name"
                  value={this.props.projectStateAttribute.name}
                  error={this.props.projectStateAttributeError.nameError}
                  placeholder="Project Name"
                  onChange={this.handleOnChange}
                />
              ) : (
                <span className="text-base">
                  {this.props.projectStateAttribute.name}
                </span>
              )}
            </div>
            <span className="w-36 inline-flex items-center text-md text-gray-800">
              Crops Type :
            </span>
            <div className={containerStyle}>
              {isAdmin ? (
                <Input
                  id="crop"
                  value={this.props.projectStateAttribute.crop}
                  error={this.props.projectStateAttributeError.cropError}
                  placeholder="Eg Palm Oil/ Paddy"
                  onChange={this.handleOnChange}
                />
              ) : (
                <span className="text-base">
                  {this.props.projectStateAttribute.crop}
                </span>
              )}
            </div>
            <span className="w-36 inline-flex items-center text-md text-gray-800">
              Location :
            </span>
            <div className={containerStyle}>
              {isAdmin ? (
                <AddressInput
                  id="location"
                  disableTitle={true}
                  onChange={this.props.handleAddressChange}
                  value={this.props.projectStateAttribute.location}
                  error={this.props.projectStateAttributeError.locationError}
                />
              ) : (
                <span className="text-base">
                  {this.props.projectStateAttribute.location.name}
                </span>
              )}
            </div>
            <span className="w-36 inline-flex items-center text-md text-gray-800">
              Service Type :
            </span>
            <div className={containerStyle}>
              {isAdmin ? (
                <Select
                  id="service"
                  placeholder={"Service"}
                  value={this.props.projectStateAttribute.service}
                  options={serviceOptions}
                  onChange={this.props.handleOnChange}
                />
              ) : (
                <span className="text-base">
                  {
                    ServiceType[
                      this.props.projectStateAttribute
                        .service as keyof typeof ServiceType
                    ]
                  }
                </span>
              )}
            </div>
            {this.renderModalSection()}
          </div>
        )}
      </>
    );
  };

  renderPreviewImage = () => {
    const isAdmin =
      this.props.authStore.user?.role === "SUPER" ||
      this.props.authStore.user?.role === "OPERATOR";

    return (
      <div className="flex flex-row w-full justify-center items-center">
        <label
          className={`${
            isAdmin
              ? "font-medium cursor-pointer text-emerald-500 hover:text-emerald-600"
              : "text-base"
          }}`}
          onClick={isAdmin ? this.props.handleUploadChange : () => {}}
        >
          Preview Map Overlay
        </label>
        <div className="grow" />
        <LocationMarkerIcon
          className="w-5 h-5 text-emerald-500 hover:text-emerald-600 cursor-pointer mr-2"
          onClick={this.props.handleZoomToOverlay}
        />
        {this.props.imageOverlayVisible ? (
          <EyeOffIcon
            className="w-5 h-5 text-emerald-500 hover:text-emerald-600 cursor-pointer"
            onClick={this.props.handleDisplayImageOverlay?.bind(this, false)}
          />
        ) : (
          <EyeIcon
            className="w-5 h-5 text-emerald-500 hover:text-emerald-600 cursor-pointer"
            onClick={this.props.handleDisplayImageOverlay?.bind(this, true)}
          />
        )}
      </div>
    );
  };

  renderModalSection = () => {
    const isOwner = this.props.authStore.user?.role === "SUPER";
    const isAdmin =
      this.props.authStore.user?.role === "SUPER" ||
      this.props.authStore.user?.role === "OPERATOR";

    if (isAdmin) {
      return (
        <>
          {!this.props.isModal && (
            <>
              <span className="w-36 pt-1 inline-flex items-center text-md text-gray-800">
                Map Overlay :
              </span>
              {this.props.projectStateAttribute.image?.token ? (
                <> {this.renderPreviewImage()}</>
              ) : (
                <TextButton
                  text="Upload Overlay"
                  onClick={this.props.handleUploadChange}
                />
              )}

              {isOwner && (
                <>
                  <span className="w-36 inline-flex items-center text-md text-gray-800">
                    Share To :
                  </span>
                  <TextButton
                    text="Share to Others"
                    onClick={this.handleUpdateModal.bind(this, true)}
                  />
                </>
              )}
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <span className="w-36 inline-flex items-center text-md text-gray-800">
            Imported Image :
          </span>
          {this.renderPreviewImage()}
        </>
      );
    }
  };

  renderCreateProjectHeader = () => {
    let defaultHeaderCss = "text-md font-medium ml-2";
    let defaultHeader = "Project Details";
    if (this.props.isModal) {
      defaultHeaderCss = "text-lg ml-5 leading-6 font-medium text-gray-900";
      defaultHeader = "Create Project";
    }
    return (
      <>
        <CubeTransparentIcon className="w-5 h-5" />
        <h3 className={defaultHeaderCss}>{defaultHeader}</h3>
        <div className="grow" />
      </>
    );
  };

  render() {
    if (this.props.isModal) {
      return (
        <Transition.Root show={this.props.modalVisible} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => {}}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                    <div className="flex flex-row">
                      {this.renderCreateProjectHeader()}
                    </div>
                    {this.renderProjectForm()}
                    <div className="flex flex-row px-4 py-3 mt-5 sm:px-6">
                      <Button
                        className="mr-5"
                        text="Back"
                        type="light"
                        onClick={this.handleBack}
                      />
                      <Button
                        text="Next"
                        type="normal"
                        onClick={this.handleSubmit}
                      />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      );
    } else {
      return (
        <div className="bg-white rounded-md">
          <div
            className={`flex flex-row items-center px-2 py-2 cursor-pointer ${
              this.state.collapse ? "border-b" : ""
            }`}
            onClick={this.handleCollapse}
          >
            {this.renderCreateProjectHeader()}
            {this.state.collapse ? (
              <ChevronUpIcon className="w-5 h-5 text-gray-400" />
            ) : (
              <ChevronDownIcon className="w-5 h-5 text-gray-400" />
            )}
          </div>
          {this.renderProjectForm()}
        </div>
      );
    }
  }
}
