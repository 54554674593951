import { ApexOptions } from "apexcharts";
import moment from "moment";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import Select, { Option } from "../../components/base/Select";
import SmartSelect from "../../components/base/SmartSelect";
import { withRouter } from "../../navigator/NavigateWrapper";
import { sleep } from "../../pages/CreateProject";
import {
  getSelectedSensors,
  getSensorGraph,
  updateSensorCustomRange,
  updateSensorDateRange,
} from "../../store/actions/sensorActions";
import { sensorStateInterface } from "../../store/reducers/sensorReducer";
import { Items, items } from "../../utils";
import { SensorAnalyticsType } from "../model/Sensor";
import { SensorData } from "../model/SensorData";
import DateRangeModal from "./DateRangeModal";
import DateRangeSelect from "./DateRangeSelect";

export enum DateRange {
  "SEVEN" = "Last 7 days",
  "FOURTEEN" = "Last 14 days",
  "TWENTYEIGHT" = "Last 28 days",
  "CUSTOM" = "Custom Date Range",
}

interface Props {
  type: keyof typeof SensorAnalyticsType;
  page?: string;
  sensorStore: sensorStateInterface;
  handleOnChange: (id: string, key: string | string[]) => void;
  updateSensorDateRange: (dateRange: keyof typeof DateRange) => void;
  updateSensorCustomRange: (range: Object | null) => void;
}

interface State {
  sensorId: string;
  sensorCode: string;
  attribute: string;
  sensorData: SensorData[];
  xaxis: number[];
  graphData: number[];
  dateRange: any;
  dateRangeModalVisible: boolean;
  startDate: Date;
  endDate: Date;
  startTime: string;
  endTime: string;
  timeRangeError: string;
  dateRangeError: string;
}

class LineChart extends Component<Props> {
  state: State = {
    sensorId: "",
    sensorCode: "",
    attribute: "",
    sensorData: [],
    graphData: [],
    xaxis: [],
    dateRangeModalVisible: false,
    dateRange: "SEVEN",
    startDate: moment().toDate(),
    endDate: moment().toDate(),
    startTime: moment().startOf("day").format("HH:00"),
    endTime: moment().endOf("day").format("HH:00"),
    timeRangeError: "",
    dateRangeError: "",
  };

  componentDidMount = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("id");
    if (selectedId) {
      this.setState(
        {
          sensorId: selectedId,
        },
        () => {
          this.handleGetSensorCode();
          this.handleGraphData();
        }
      );
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.type !== this.props.type) {
      this.setState({
        sensorId: "",
        sensorCode: "",
        attribute: "",
        sensorData: [],
        graphData: [],
        xaxis: [],
        dateRangeModalVisible: false,
        dateRange: "SEVEN",
        startDate: moment().toDate(),
        endDate: moment().toDate(),
        startTime: moment().startOf("day").format("HH:00"),
        endTime: moment().endOf("day").format("HH:00"),
        timeRangeError: "",
        dateRangeError: "",
      });
    }
  };

  handleOnChangeAttribute = (id: string, key: string) => {
    this.setState(
      {
        [id]: key,
      },
      () => {
        this.handleRerenderGraphDetails();
      }
    );
  };

  handleOnChange = (id: string, key: string | string[]) => {
    if (!Array.isArray(key)) {
      if (id === "sensorId") {
        this.setState(
          {
            sensorId: key,
          },
          () => {
            this.handleGetSensorCode();
            this.handleGraphData();
          }
        );
      } else {
        this.setState(
          {
            dateRange: key,
          },
          async () => {
            if (this.state.dateRange === "CUSTOM") {
              this.handleDateRangeModalVisible(true);
            } else {
              this.props.updateSensorCustomRange(null);
            }
            this.props.updateSensorDateRange(this.state.dateRange);
            await sleep(300);
            this.handleGraphData();
          }
        );
      }
    }
  };

  handleDateRangeModalVisible = (visible: boolean) => {
    this.setState({
      dateRangeModalVisible: visible,
      dateRangeError: "",
    });
  };

  handleDateChange = (value: any, id: string) => {
    this.setState({
      [id]: value,
    });
  };

  handleDateRangeSubmit = () => {
    if (moment(this.state.startDate).isAfter(moment(this.state.endDate))) {
      this.setState({
        dateRangeError: "Please select a proper date range",
      });
    } else if (
      moment(this.state.startDate).format("DD/MM/YYYY") ===
        moment(this.state.endDate).format("DD/MM/YYYY") &&
      !moment(this.state.endTime, "HH:mm").isAfter(
        moment(this.state.startTime, "HH:mm")
      )
    ) {
      this.setState({
        timeRangeError: "Please select a proper time range",
      });
    } else {
      this.setState(
        {
          dateRangeError: "",
          timeRangeError: "",
        },
        async () => {
          this.handleDateRangeModalVisible(false);
          const range = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          };
          this.props.updateSensorCustomRange(range);
          await sleep(300);
          this.handleGraphData();
        }
      );
    }
  };

  handleGetSensorCode = async () => {
    const sensorData = await getSelectedSensors(this.state.sensorId);
    if (typeof sensorData !== "string" && sensorData) {
      this.setState(
        {
          sensorCode: sensorData.code,
        },
        () => {
          const attributes = this.renderAttributeOptions();
          let attribute = "";
          if (attributes.length > 0) {
            attribute = attributes[0].key;
          }
          this.setState({ attribute });
        }
      );
    }
  };

  handleGraphData = async () => {
    let dateRange = {};
    if (this.props.sensorStore.dateRange === "SEVEN") {
      dateRange = {
        endDate: moment().toDate(),
        startDate: moment().subtract(7, "days").toDate(),
      };
    } else if (this.props.sensorStore.dateRange === "FOURTEEN") {
      dateRange = {
        endDate: moment().toDate(),
        startDate: moment().subtract(14, "days").toDate(),
      };
    } else if (this.props.sensorStore.dateRange === "TWENTYEIGHT") {
      dateRange = {
        endDate: moment().toDate(),
        startDate: moment().subtract(28, "days").toDate(),
      };
    } else {
      if (this.props.sensorStore.range) {
        dateRange = {
          endDate: this.props.sensorStore.range.endDate,
          startDate: this.props.sensorStore.range.startDate,
        };
      }
    }
    const data = {
      sensorId: this.state.sensorId,
      ...dateRange,
    };

    const graphData = await getSensorGraph(data);
    if (graphData) {
      this.setState(
        {
          sensorData: graphData.data,
        },
        () => {
          this.handleRerenderGraphDetails();
        }
      );
    }
  };

  handleRerenderGraphDetails = () => {
    const series: any = [];
    const xaxis: any = [];
    if (
      this.props.sensorStore.dateRange === "CUSTOM" &&
      moment(this.state.startDate).format("DD/MM/YYYY") ===
        moment(this.state.endDate).format("DD/MM/YYYY")
    ) {
      this.state.sensorData.map((eachData: SensorData) => {
        eachData.logs.map((eachLog) => {
          if (
            moment(moment(eachLog.time).format("HH:mm"), "HH:mm").isBetween(
              moment(this.state.startTime, "HH:mm"),
              moment(this.state.endTime, "HH:mm")
            )
          ) {
            const log = JSON.parse(JSON.stringify(eachLog));
            series.push(Number(log[this.state.attribute] ?? 0) / 10);
            xaxis.push(moment(eachLog.time).format("hh:mm a"));
          }
          return "";
        });
        return "";
      });
    } else {
      this.state.sensorData.map((eachData: SensorData) => {
        let total = 0;
        eachData.logs.map((eachLog) => {
          const log = JSON.parse(JSON.stringify(eachLog));
          total = total + Number(log[this.state.attribute] ?? 0) / 10;
          return "";
        });
        series.push((total / eachData.logs.length).toFixed(2));
        xaxis.push(moment(eachData.createdAt).format("DD/MM/YYYY"));
        return "";
      });
    }
    this.setState({
      graphData: series,
      xaxis,
    });
  };

  renderDateRangeOptions = () => {
    const dateRangeOptions: Option[] = [];
    Object.keys(DateRange).map((eachDateRangeKey) => {
      dateRangeOptions.push({
        key: eachDateRangeKey,
        title: DateRange[eachDateRangeKey as keyof typeof DateRange],
      });
      return null;
    });
    return dateRangeOptions;
  };

  renderAttributeOptions = () => {
    const attributeOptions: Option[] = [];

    items.map((eachItem: Items) => {
      if (eachItem.code === this.state.sensorCode) {
        eachItem.unit.map((eachUnit) => {
          attributeOptions.push({
            key: eachUnit,
            title: eachUnit.charAt(0).toUpperCase() + eachUnit.slice(1),
          });
          return "";
        });
      }
      return "";
    });

    return attributeOptions;
  };

  render() {
    const options: ApexOptions = {
      chart: {
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#77B6EA", "#545454"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: this.state.xaxis,
        title: {
          text: "Date",
          style: { fontSize: "11", fontWeight: "bold" },
        },
      },
      yaxis: {
        title: {
          text: "Value",
          style: { fontSize: "11", fontWeight: "bold" },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    };

    const series = [
      {
        name: "Value",
        data: this.state.graphData,
      },
    ];

    let defaultClassName =
      "flex flex-col bg-white justify-center rounded-lg mt-4 px-5 py-3 w-full lg:mt-0";

    if (this.props.page !== "details") {
      defaultClassName += " shadow";
    }

    return (
      <>
        <DateRangeModal
          title="Create Date"
          open={this.state.dateRangeModalVisible}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          dateRangeError={this.state.dateRangeError}
          startTime={this.state.startTime}
          endTime={this.state.endTime}
          timeRangeError={this.state.timeRangeError}
          onChange={this.handleDateChange}
          onClick={this.handleDateRangeSubmit}
          onClose={this.handleDateRangeModalVisible.bind(this, false)}
        />
        <div className={defaultClassName}>
          <div className="flex flex-row items-center pb-2">
            <h3 className="text-lg truncate font-medium">
              {this.props.page !== "details" && "Sensor Statistics"}
            </h3>
          </div>

          <div className="flex flex-col sm:flex-row justify-start items-center">
            {this.props.page !== "details" && (
              <div className="w-full sm:w-3/5">
                <SmartSelect
                  id="sensorId"
                  placeholder="Search sensor code"
                  value={this.state.sensorId}
                  type={this.props.type}
                  error={""}
                  onChange={this.handleOnChange}
                />
              </div>
            )}

            {this.state.sensorId && (
              <div className="w-full mt-2 ml-0 sm:w-2/5 sm:mt-0 sm:ml-4">
                <Select
                  id="attribute"
                  placeholder="Attribute"
                  options={this.renderAttributeOptions()}
                  value={this.state.attribute}
                  onChange={this.handleOnChangeAttribute}
                />
              </div>
            )}
          </div>
          <div className="w-11/12">
            <ReactApexChart
              height={300}
              options={options}
              series={series}
              type="line"
            />
          </div>
          <div className="flex flex-col sm:flex-row w-full justify-center items-center">
            <div className="grow" />
            <div className="w-full mt-2 sm:w-56 sm:mt-0">
              <DateRangeSelect
                id="selectedRange"
                placeholder="Select date range"
                value={this.props.sensorStore.dateRange}
                page={this.props.page}
                error={""}
                range={this.props.sensorStore.range}
                options={this.renderDateRangeOptions()}
                onChange={this.handleOnChange}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    sensorStore: state.sensorStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSensorDateRange: (dateRange: keyof typeof DateRange) =>
      dispatch(updateSensorDateRange(dateRange)),
    updateSensorCustomRange: (range: Object) =>
      dispatch(updateSensorCustomRange(range)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LineChart));
