import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import {
  clearOfflineGeoPolygon,
  getOfflineGeoPolygonOption
} from "../../store/actions/projectActions";
import {
  createTask,
  handleCreateTaskCondition,
  updateTask,
  updateTaskOffline,
  uploadImageStorage
} from "../../store/actions/tasksActions";
import { authStateInterface } from "../../store/reducers/authReducer";
import { utilsStateInterface } from "../../store/reducers/utilsReducer";
import { indexedDb } from "../../utils/indexedDB";
import Button from "../base/Button";
import Input from "../base/Input";
import Overlay from "../base/Overlay";
import Select, { Option } from "../base/Select";
import SmartSelect from "../base/SmartSelect";
import TextButton from "../base/TextButton";
import UploadInput from "../base/UploadInput";
import { DroneType, Task, TaskMode } from "../model/Task";

export enum TaskType {
  CREATE = "Task Creation",
  UPDATE = "Update Task",
  VIEW = "View Task",
  REFRESH = "Refresh Task",
}

interface Props {
  type: TaskType;
  visible: boolean;
  selectedTask: Task | null;
  utilsStore: utilsStateInterface;
  authStore: authStateInterface;
  getOfflineGeoPolygonOption: (selectedId: string) => void;
  clearOfflineGeoPolygon: () => void;
  onClose: (bool: boolean, type: TaskType) => void;
}

interface State {
  loading: boolean;
  taskState: TaskState;
  taskStateError: TaskErrorState;
}

interface InputAttribute {
  id: string;
  state: any;
  error: string;
  placeholder: string;
  type: string;
  title: string;
  option?: Option[];
}

export interface TaskState extends Task {
  file: File | "";
}

export interface TaskErrorState {
  projectError: string;
  polygonError: string;
  locationError: string;
  manPowerError: string;
  purposeError: string;
  droneOtherError: string;
  modeOtherError: string;
  flightSpeedError: string;
  flightHeightError: string;
  dosageError: string;
  atomizationError: string;
  routeIntervalError: string;
  boundarySafetyDistanceError: string;
  obstacleSafetyDistanceError: string;
  areaCoverError: string;
  batteryUsedError: string;
  chargingCycleError: string;
  totalChemicalUsedError: string;
  typeOfChemicalUsedError: string;
  operationHoursError: string;
  flightTimeError: string;
  totalDownTimeError: string;
  limitationError: string;
  conclusionError: string;
  recommendationError: string;
  fileError: string;
}

const dummyTask: TaskState = {
  _id: "",
  date: moment().toDate(),
  location: "",
  manPower: 0,
  purpose: "",
  droneModel: "XAGR150A",
  droneModelOther: "",
  mode: "SPRAY",
  modeOther: "",
  flightSpeed: "",
  flightHeight: 0,
  dosage: 0,
  atomization: 0,
  routeInterval: 0,
  boundarySafetyDistance: 0,
  obstacleSafetyDistance: 0,
  areaCover: 0,
  batteryUsed: 0,
  chargingCycle: 0,
  totalChemicalUsed: 0,
  typeOfChemicalUsed: "",
  operationHours: "",
  flightTime: "",
  totalDownTime: 0,
  limitation: "",
  conclusion: "",
  recommendation: "",
  attachmentToken: "",
  createdBy: "",
  completed: false,
  projectId: "",
  polygonId: [],
  file: "",
};

const dummyTaskError: TaskErrorState = {
  projectError: "",
  polygonError: "",
  locationError: "",
  manPowerError: "",
  purposeError: "",
  droneOtherError: "",
  modeOtherError: "",
  flightSpeedError: "",
  flightHeightError: "",
  dosageError: "",
  atomizationError: "",
  routeIntervalError: "",
  boundarySafetyDistanceError: "",
  obstacleSafetyDistanceError: "",
  areaCoverError: "",
  batteryUsedError: "",
  chargingCycleError: "",
  totalChemicalUsedError: "",
  typeOfChemicalUsedError: "",
  operationHoursError: "",
  flightTimeError: "",
  totalDownTimeError: "",
  limitationError: "",
  conclusionError: "",
  recommendationError: "",
  fileError: "",
};

const initTaskAttribute = () => {
  const clonedTaskAttribute = JSON.parse(JSON.stringify(dummyTask));
  dummyTask["date"] = moment().toDate();
  return clonedTaskAttribute;
};

class TasksModal extends Component<Props> {
  state: State = {
    loading: false,
    taskState: initTaskAttribute(),
    taskStateError: JSON.parse(JSON.stringify(dummyTaskError)),
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      JSON.stringify(this.props) !== JSON.stringify(prevProps) &&
      this.props.selectedTask?._id
    ) {
      const selectedDate = this.props.selectedTask?.date;
      const selectedTask: TaskState = JSON.parse(
        JSON.stringify(this.props.selectedTask)
      );
      if (selectedTask.projectId) {
        this.props.getOfflineGeoPolygonOption(selectedTask.projectId);
      }
      selectedTask["date"] = selectedDate;
      selectedTask["file"] = "";
      this.setState({ taskState: selectedTask });
    }
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleOnClose = () => {
    this.setState(
      {
        loading: false,
        taskState: initTaskAttribute(),
        taskStateError: JSON.parse(JSON.stringify(dummyTaskError)),
      },
      () => {
        this.props.clearOfflineGeoPolygon();
        this.props.onClose(false, TaskType.VIEW);
      }
    );
  };

  handleCloneTaskAttribute = () => {
    const clonedTaskAttribute = JSON.parse(
      JSON.stringify(this.state.taskState)
    );
    clonedTaskAttribute["date"] = this.state.taskState.date;
    clonedTaskAttribute["file"] = this.state.taskState.file;
    return clonedTaskAttribute;
  };

  handleOnChange = (e: any) => {
    const clonedTaskAttribute = this.handleCloneTaskAttribute();
    clonedTaskAttribute[e.target.id] = e.target.value;
    this.setState({
      taskState: clonedTaskAttribute,
    });
  };

  handleSelectChange = (id: string, value: string | string[]) => {
    const clonedTaskAttribute = this.handleCloneTaskAttribute();
    clonedTaskAttribute[id] = value;
    this.setState(
      {
        taskState: clonedTaskAttribute,
      },
      () => {
        if (this.state.taskState.projectId) {
          this.props.getOfflineGeoPolygonOption(this.state.taskState.projectId);
        }
        if (id === "projectId") {
          const clonedTaskAttribute = this.handleCloneTaskAttribute();
          clonedTaskAttribute["polygonId"] = [];
          this.setState({
            taskState: clonedTaskAttribute,
          });
        }
      }
    );
  };

  handleOnChangeDate = (date: Date) => {
    const clonedTaskAttribute = this.handleCloneTaskAttribute();
    clonedTaskAttribute["date"] = date;
    this.setState({
      taskState: clonedTaskAttribute,
    });
  };

  handleUploadChange = (e: any) => {
    const clonedTaskAttribute = this.handleCloneTaskAttribute();
    clonedTaskAttribute["file"] = e.target.files[0];
    this.setState({
      taskState: clonedTaskAttribute,
    });
  };

  handleGetUploadImageURL = async (uuid: string) => {
    let fileUrl;
    if (this.props.utilsStore.onlineStatus) {
      fileUrl = await uploadImageStorage(uuid, this.state.taskState.file);
    } else {
      new Promise(() => {
        let fileReader = new FileReader();
        fileReader.onload = async () => {
          const imageData = await indexedDb.getValue("local", "images");
          let imageLocalOffline: any[] = imageData?.images ?? [];

          if (
            !imageLocalOffline.some((offline) => {
              return offline.id === uuid;
            })
          ) {
            imageLocalOffline.push({
              id: uuid,
              dataURL: fileReader.result ?? "",
            });
          }

          await indexedDb.putValue("local", {
            images: imageLocalOffline,
            type: "images",
          });
        };
        if (this.state.taskState.file instanceof File) {
          fileReader.readAsDataURL(this.state.taskState.file);
        }
      });
    }
    return fileUrl;
  };

  handleSubmit = () => {
    this.handleLoading(true);
    let conditionsList: string[] = [
      "projectId",
      "polygonId",
      "location",
      "manPower",
      "purpose",
      "droneModelOther",
      "modeOther",
      "flightSpeed",
      "flightHeight",
      "dosage",
      "atomization",
      "routeInterval",
      "boundarySafetyDistance",
      "obstacleSafetyDistance",
      "areaCover",
      "batteryUsed",
      "chargingCycle",
      "totalChemicalUsed",
      "typeOfChemicalUsed",
      "operationHours",
      "flightTime",
      "totalDownTime",
      "limitation",
      "conclusion",
      "recommendation",
    ];

    if (this.state.taskState.file instanceof File) {
      conditionsList.push("file");
    }

    const clonedTaskError = JSON.parse(
      JSON.stringify(this.state.taskStateError)
    );

    handleCreateTaskCondition(
      this.state.taskState,
      clonedTaskError,
      conditionsList
    );

    this.setState(
      {
        taskStateError: clonedTaskError,
      },
      async () => {
        if (
          !this.state.taskStateError.projectError &&
          !this.state.taskStateError.polygonError &&
          !this.state.taskStateError.locationError &&
          !this.state.taskStateError.manPowerError &&
          !this.state.taskStateError.purposeError &&
          !this.state.taskStateError.droneOtherError &&
          !this.state.taskStateError.modeOtherError &&
          !this.state.taskStateError.flightSpeedError &&
          !this.state.taskStateError.flightHeightError &&
          !this.state.taskStateError.dosageError &&
          !this.state.taskStateError.atomizationError &&
          !this.state.taskStateError.routeIntervalError &&
          !this.state.taskStateError.boundarySafetyDistanceError &&
          !this.state.taskStateError.obstacleSafetyDistanceError &&
          !this.state.taskStateError.areaCoverError &&
          !this.state.taskStateError.batteryUsedError &&
          !this.state.taskStateError.chargingCycleError &&
          !this.state.taskStateError.totalChemicalUsedError &&
          !this.state.taskStateError.typeOfChemicalUsedError &&
          !this.state.taskStateError.operationHoursError &&
          !this.state.taskStateError.flightTimeError &&
          !this.state.taskStateError.totalDownTimeError &&
          !this.state.taskStateError.limitationError &&
          !this.state.taskStateError.conclusionError &&
          !this.state.taskStateError.recommendationError &&
          !this.state.taskStateError.fileError
        ) {
          const uuid =
            this.props.type === TaskType.CREATE
              ? crypto.randomUUID()
              : this.state.taskState._id;

          const clonedTaskAttribute = this.handleCloneTaskAttribute();
          clonedTaskAttribute["_id"] = uuid;
          clonedTaskAttribute["createdBy"] =
            this.props.type === TaskType.CREATE
              ? this.props.authStore.user?._id
              : this.state.taskState.createdBy;

          if (this.state.taskState.file instanceof File) {
            const fileToken = await this.handleGetUploadImageURL(uuid);
            if (this.props.utilsStore.onlineStatus) {
              clonedTaskAttribute["attachmentToken"] = fileToken;
            }
          }

          if (this.props.type === TaskType.CREATE) {
            if (this.props.utilsStore.onlineStatus) {
              const createTaskResponse = await createTask(clonedTaskAttribute);
              if (typeof createTaskResponse === "string") {
                const clonedTaskAttributeError = JSON.parse(
                  JSON.stringify(this.state.taskStateError)
                );
                clonedTaskAttributeError["conclusionError"] =
                  createTaskResponse;
                this.setState({
                  taskStateError: clonedTaskAttributeError,
                });
              } else {
                this.setState(
                  {
                    taskState: initTaskAttribute(),
                    taskStateError: JSON.parse(JSON.stringify(dummyTaskError)),
                  },
                  () => {
                    this.props.onClose(false, TaskType.REFRESH);
                  }
                );
              }
            } else {
              await updateTaskOffline(clonedTaskAttribute);
              this.setState(
                {
                  taskState: initTaskAttribute(),
                  taskStateError: JSON.parse(JSON.stringify(dummyTaskError)),
                },
                () => {
                  this.props.onClose(false, TaskType.REFRESH);
                }
              );
            }
          } else if (this.props.type === TaskType.UPDATE) {
            const updateTaskResponse = this.props.utilsStore.onlineStatus
              ? await updateTask(clonedTaskAttribute)
              : await updateTaskOffline(clonedTaskAttribute);

            if (typeof updateTaskResponse === "string" && updateTaskResponse) {
              const clonedTaskAttributeError = JSON.parse(
                JSON.stringify(this.state.taskStateError)
              );
              clonedTaskAttributeError["conclusionError"] = updateTaskResponse;
              this.setState({
                taskStateError: clonedTaskAttributeError,
              });
            } else {
              this.setState(
                {
                  taskState: initTaskAttribute(),
                  taskStateError: JSON.parse(JSON.stringify(dummyTaskError)),
                },
                () => {
                  this.props.onClose(false, TaskType.REFRESH);
                }
              );
            }
          }
        }
        this.handleLoading(false);
      }
    );
  };

  renderTaskContent = () => {
    let attribute: InputAttribute[] = [
      this.props.utilsStore.onlineStatus
        ? {
            id: "projectId",
            state: this.state.taskState.projectId,
            placeholder: "Project Name",
            title: "Linked Project",
            error: this.state.taskStateError.projectError,
            type: "smartSelect",
          }
        : {
            id: "projectId",
            state: this.state.taskState.projectId,
            placeholder: "Project Name",
            title: "Project Name",
            type: "select",
            error: this.state.taskStateError.projectError,
            option: this.props.utilsStore.projectOpts,
          },

      {
        id: "polygonId",
        state: this.state.taskState.polygonId,
        placeholder: "Polygon Name",
        title: "Link Polygon",
        error: this.state.taskStateError.polygonError,
        option: this.props.utilsStore.geoListOpts,
        type: "smartSelect",
      },
      {
        id: "date",
        state: moment(this.state.taskState.date).toDate(),
        error: "",
        placeholder: "Date",
        title: "Date",
        type: "date",
      },
      {
        id: "location",
        state: this.state.taskState.location,
        error: this.state.taskStateError.locationError,
        placeholder: "eg Gemas Paddy Field",
        title: "Location",
        type: "text",
      },
      {
        id: "manPower",
        state: this.state.taskState.manPower,
        error: this.state.taskStateError.manPowerError,
        placeholder: "4",
        title: "Man Power",
        type: "number",
      },
      {
        id: "purpose",
        state: this.state.taskState.purpose,
        error: this.state.taskStateError.purposeError,
        placeholder: "Eg Gemas... Plot 111/116",
        title: "Task Purpose",
        type: "text",
      },
      {
        id: "droneModel",
        state: this.state.taskState.droneModel,
        error: "",
        type: "select",
        title: "Drone Model",
        placeholder: "Drone Model",
        option: Object.keys(DroneType).map((eachDroneKey) => {
          return {
            key: eachDroneKey,
            title: DroneType[eachDroneKey as keyof typeof DroneType],
          };
        }),
      },
      {
        id: "mode",
        state: this.state.taskState.mode,
        error: "",
        type: "select",
        title: "Task Mode",
        placeholder: "Task Mode",
        option: Object.keys(TaskMode).map((eachTaskModeKey) => {
          return {
            key: eachTaskModeKey,
            title: TaskMode[eachTaskModeKey as keyof typeof TaskMode],
          };
        }),
      },
      {
        id: "flightSpeed",
        state: this.state.taskState.flightSpeed,
        error: this.state.taskStateError.flightSpeedError,
        placeholder: "Entry: 3ms, Operation:7ms, Exit:3ms",
        title: "Flight Speed (ms)",
        type: "text",
      },
      {
        id: "flightHeight",
        state: this.state.taskState.flightHeight,
        error: this.state.taskStateError.flightHeightError,
        placeholder: "2.5",
        title: "Flight Height (m)",
        type: "number",
      },
      {
        id: "dosage",
        state: this.state.taskState.dosage,
        error: this.state.taskStateError.dosageError,
        placeholder: "201",
        title: "Dosage (litre)",
        type: "number",
      },
      {
        id: "atomization",
        state: this.state.taskState.atomization,
        error: this.state.taskStateError.atomizationError,
        placeholder: "110",
        title: "Atomization (micron)",
        type: "number",
      },
      {
        id: "routeInterval",
        state: this.state.taskState.routeInterval,
        error: this.state.taskStateError.routeIntervalError,
        placeholder: "5",
        title: "Route Interval (m)",
        type: "number",
      },
      {
        id: "boundarySafetyDistance",
        state: this.state.taskState.boundarySafetyDistance,
        error: this.state.taskStateError.boundarySafetyDistanceError,
        placeholder: "5",
        title: "Boundary Safety Distance (m)",
        type: "number",
      },
      {
        id: "obstacleSafetyDistance",
        state: this.state.taskState.obstacleSafetyDistance,
        error: this.state.taskStateError.obstacleSafetyDistanceError,
        placeholder: "5",
        title: "Obstacle Safety Distance (m)",
        type: "number",
      },
      {
        id: "areaCover",
        state: this.state.taskState.areaCover,
        error: this.state.taskStateError.areaCoverError,
        placeholder: "50",
        title: "Area Cover (acre)",
        type: "number",
      },
      {
        id: "batteryUsed",
        state: this.state.taskState.batteryUsed,
        error: this.state.taskStateError.batteryUsedError,
        placeholder: "5",
        title: "Battery Used (unit)",
        type: "number",
      },
      {
        id: "chargingCycle",
        state: this.state.taskState.chargingCycle,
        error: this.state.taskStateError.chargingCycleError,
        placeholder: "5",
        title: "Charging Cycle (cycle)",
        type: "number",
      },
      {
        id: "totalChemicalUsed",
        state: this.state.taskState.totalChemicalUsed,
        error: this.state.taskStateError.totalChemicalUsedError,
        placeholder: "5",
        title: "Total Chemical Used (litre or kg)",
        type: "number",
      },
      {
        id: "typeOfChemicalUsed",
        state: this.state.taskState.typeOfChemicalUsed,
        error: this.state.taskStateError.typeOfChemicalUsedError,
        placeholder: "Eg Rarity Amine 48, Satunil, Clincher ",
        title: "Type of Chemical Used",
        type: "text",
      },
      {
        id: "operationHours",
        state: this.state.taskState.operationHours,
        error: this.state.taskStateError.operationHoursError,
        placeholder: "Eg 8.30 AM - 5.00 PM",
        title: "Operation Hours",
        type: "text",
      },
      {
        id: "flightTime",
        state: this.state.taskState.flightTime,
        error: this.state.taskStateError.flightTimeError,
        placeholder: "Eg F1: 10.00 AM, F2: 11.00 AM, F3:12.00 PM ",
        title: "Flight Time",
        type: "text",
      },
      {
        id: "totalDownTime",
        state: this.state.taskState.totalDownTime,
        error: this.state.taskStateError.totalDownTimeError,
        placeholder: "3",
        title: "Total Down Time (min)",
        type: "number",
      },
      {
        id: "limitation",
        state: this.state.taskState.limitation,
        error: this.state.taskStateError.limitationError,
        placeholder: "Limitation",
        title: "Limitation",
        type: "text",
      },
      {
        id: "conclusion",
        state: this.state.taskState.conclusion,
        error: this.state.taskStateError.conclusionError,
        placeholder: "Conclusion",
        title: "Conclusion",
        type: "text",
      },
      {
        id: "recommendation",
        state: this.state.taskState.recommendation,
        error: this.state.taskStateError.recommendationError,
        placeholder: "Recommendation",
        title: "Recommendation",
        type: "text",
      },
      {
        id: "completed",
        state: this.state.taskState.completed.toString(),
        error: "",
        placeholder: "",
        title: "Completed",
        option: [
          {
            key: "true",
            title: "Completed",
          },
          {
            key: "false",
            title: "Not Completed",
          },
        ],
        type: "select",
      },
    ];

    if (this.state.taskState.droneModel === "OTHER") {
      const index = attribute.findIndex(
        (eachAttribute) => eachAttribute.id === "droneModel"
      );
      attribute.splice(index + 1, 0, {
        id: "droneModelOther",
        state: this.state.taskState.droneModelOther,
        error: this.state.taskStateError.droneOtherError,
        placeholder: "Other Drone Model",
        title: "Other Drone Model",
        type: "text",
      });
    }

    if (this.state.taskState.mode === "OTHER") {
      const index = attribute.findIndex(
        (eachAttribute) => eachAttribute.id === "mode"
      );
      attribute.splice(index + 1, 0, {
        id: "modeOther",
        state: this.state.taskState.modeOther,
        error: this.state.taskStateError.modeOtherError,
        placeholder: "Other Task Mode",
        title: "Other Task Mode",
        type: "text",
      });
    }

    if (this.props.type === TaskType.VIEW) {
      attribute.splice(0, 1);
      if (this.props.utilsStore.onlineStatus) {
        attribute.push({
          id: "attachment",
          state: this.state.taskState.attachmentToken,
          error: "",
          placeholder: "",
          title: "Attachment",
          type: "image",
        });
      }
    } else if (this.props.type === TaskType.UPDATE) {
      attribute.splice(0, 1);
      attribute.push({
        id: "attachment",
        state: this.state.taskState.attachmentToken,
        error: this.state.taskStateError.fileError,
        placeholder: "",
        title: "Attachment",
        type: "image",
      });
    } else if (this.props.type === TaskType.CREATE) {
      attribute.push({
        id: "attachment",
        state: this.state.taskState.file,
        error: this.state.taskStateError.fileError,
        placeholder: "",
        title: "Attachment",
        type: "upload",
      });
    }

    return attribute.map((eachAttribute) => {
      return (
        <>
          <div className="inline-flex items-center text-md font-semibold text-gray-700">
            {eachAttribute.title}
          </div>
          <div className="w-full text-gray-600">
            {this.props.type === TaskType.VIEW
              ? this.renderTaskContentLabel(eachAttribute)
              : this.renderTaskContentInput(eachAttribute)}
          </div>
        </>
      );
    });
  };

  renderTaskContentLabel = (eachAttribute: InputAttribute) => {
    if (eachAttribute.state) {
      switch (eachAttribute.type) {
        case "select":
          if (eachAttribute.id === "mode") {
            return TaskMode[eachAttribute.state as keyof typeof TaskMode];
          } else if (eachAttribute.id === "droneModel") {
            return DroneType[eachAttribute.state as keyof typeof DroneType];
          } else {
            return eachAttribute.state;
          }
        case "date":
          return moment(eachAttribute.state).format("DD/MM/YYYY");
        case "image":
          const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
          let defaultWebUrl =
            "https://firebasestorage.googleapis.com/v0/b/lesq-spp-dev.appspot.com/o/tasks%2F";
          if (isProd) {
            defaultWebUrl =
              "https://firebasestorage.googleapis.com/v0/b/lesq-spp.appspot.com/o/tasks%2F";
          }
          return (
            <img
              className="w-40 h-auto"
              alt=""
              src={
                eachAttribute.state.substring(0, 5) === "data:"
                  ? eachAttribute.state
                  : `${defaultWebUrl}${this.state.taskState._id}%2Fpreview.png?alt=media&token=${eachAttribute.state}`
              }
            />
          );
        case "smartSelect":
          const selectedGeoList = this.props.utilsStore.geoListOpts.filter(
            (eachGeoList) => {
              return eachAttribute.state.includes(eachGeoList.key);
            }
          );
          return selectedGeoList.map((eachGeoList, index) => {
            return `${eachGeoList.title}${
              selectedGeoList.length - 1 > index ? ", " : ""
            }`;
          });
        default:
          return eachAttribute.state;
      }
    }
  };

  renderTaskContentInput = (eachAttribute: InputAttribute) => {
    switch (eachAttribute.type) {
      case "text":
      case "number":
        return (
          <Input
            placeholder={eachAttribute.placeholder}
            type={eachAttribute.type}
            id={eachAttribute.id}
            value={eachAttribute.state === 0 ? "" : eachAttribute.state}
            error={eachAttribute.error}
            onChange={this.handleOnChange}
          />
        );
      case "select":
        return (
          <Select
            id={eachAttribute.id}
            placeholder={eachAttribute.placeholder}
            value={eachAttribute.state}
            options={eachAttribute.option ?? []}
            error={eachAttribute.error}
            onChange={this.handleSelectChange}
          />
        );
      case "date":
        return (
          <DatePicker
            className="top-0"
            dateFormat="dd/MM/yyyy"
            selected={eachAttribute.state}
            onChange={(date: Date) => {
              this.handleOnChangeDate(date);
            }}
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
          />
        );
      case "upload":
        return (
          <UploadInput
            accept=".png,.jpg,.jpeg,.pdf"
            file={eachAttribute.state}
            error={eachAttribute.error}
            onChange={this.handleUploadChange}
          />
        );
      case "image":
        const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
        let defaultWebUrl =
          "https://firebasestorage.googleapis.com/v0/b/lesq-spp-dev.appspot.com/o/tasks%2F";
        if (isProd) {
          defaultWebUrl =
            "https://firebasestorage.googleapis.com/v0/b/lesq-spp.appspot.com/o/tasks%2F";
        }
        return (
          <div className="flex flex-row justify-start items-center w-full">
            {this.props.utilsStore.onlineStatus && (
              <img
                className="w-40 h-auto"
                alt=""
                src={
                  this.state.taskState.file instanceof File
                    ? URL.createObjectURL(this.state.taskState.file)
                    : `${defaultWebUrl}${this.state.taskState._id}%2Fpreview.png?alt=media&token=${eachAttribute.state}`
                }
              />
            )}
            <input
              id="file"
              style={{ border: "none", display: "none" }}
              type="file"
              accept=".png,.jpg,.jpeg"
              onChange={this.handleUploadChange}
            />
            <TextButton
              className="sm:ml-5"
              text={this.props.utilsStore.onlineStatus ? `Update` : `Overwrite`}
              onClick={() => {
                document.getElementById("file")?.click();
              }}
            />
            {!this.props.utilsStore.onlineStatus &&
              this.props.type === TaskType.UPDATE &&
              this.state.taskState.file instanceof File && (
                <div className="ml-2 w-full text-xs text-gray-600">
                  ({this.state.taskState.file.name})
                </div>
              )}
          </div>
        );
      case "smartSelect":
        let newState: string[] = [];
        if (
          eachAttribute.id === "polygonId" &&
          !Array.isArray(eachAttribute.state)
        ) {
          newState.push(eachAttribute.state);
        } else {
          newState = eachAttribute.state;
        }
        return (
          <SmartSelect
            id={eachAttribute.id}
            placeholder={eachAttribute.placeholder}
            options={eachAttribute.option ?? []}
            value={newState}
            error={eachAttribute.error}
            onChange={this.handleSelectChange}
          />
        );
    }
  };

  render() {
    return (
      <Transition.Root show={this.props.visible} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Overlay
            loading={this.state.loading}
            text="Please wait while we are uploading the image"
          />
          <div className="fixed z-20 inset-0 max-h-screen">
            <div className="flex items-center justify-center h-full max-h-screen pt-4 px-6">
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative inline-block w-full overflow-hidden align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl">
                  <div className="bg-white overflow-y-scroll scrollBar">
                    <div className="mx-4 px-4 pt-5 sm:p-6  ">
                      <div className="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-xl leading-6 font-bold text-gray-900"
                        >
                          {this.props.type}
                        </Dialog.Title>
                        <div className="grid grid-cols-1 gap-x-2 gap-y-4 mt-5 h-96 sm:grid-cols-2">
                          {this.renderTaskContent()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-3 px-4 sm:px-6 flex flex-row-reverse">
                    {this.props.type !== TaskType.VIEW && (
                      <Button
                        text={"Submit"}
                        type="normal"
                        onClick={this.handleSubmit}
                      />
                    )}
                    <Button
                      className="mr-5"
                      text={
                        this.props.type === TaskType.VIEW ? "Close" : "Cancel"
                      }
                      type={
                        this.props.type === TaskType.VIEW ? "normal" : "light"
                      }
                      onClick={this.handleOnClose}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getOfflineGeoPolygonOption: (selectedId: string) =>
      dispatch(getOfflineGeoPolygonOption(selectedId)),
    clearOfflineGeoPolygon: () => dispatch(clearOfflineGeoPolygon()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksModal);
