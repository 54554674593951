import moment from "moment";
import React, { Component, createRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import ReactToPrint from "react-to-print";
import Button from "../components/base/Button";
import Input from "../components/base/Input";
import Table, { TableHeader } from "../components/base/Table";
import TextButton from "../components/base/TextButton";
import {
  GeoPolygonColor,
  Project,
  ServiceType,
} from "../components/model/Project";
import { Task, TaskMode } from "../components/model/Task";
import { Role, UserModel } from "../components/model/User";
import {
  getSelectedProject,
  updateProject,
  uploadImageStorage,
} from "../store/actions/projectActions";
import { getLinkedTaskList } from "../store/actions/tasksActions";
import { getUserAccessList } from "../store/actions/userActions";
import { renderPolygonColorStatus } from "../store/actions/utilsActions";
import { utilsStateInterface } from "../store/reducers/utilsReducer";
import Logo from "./../images/logo.png";
import { sleep } from "./CreateProject";

interface Props {
  utilsStore: utilsStateInterface;
}

interface State {
  taskList: Task[];
  userList: UserModel[];
  projectStateAttribute: Project;
}

class ReportView extends Component<Props> {
  private printContainer: any;
  private map = createRef<HTMLDivElement>();
  private googleMap: any = "";
  private overlayMap = createRef<HTMLDivElement>();
  private mapWithoutPolygon = createRef<HTMLDivElement>();
  private canvasSignature: any = "";
  private overlayGoogleMap: any = "";
  private googleMapWithoutPolygon: any = "";
  private imageOverlay: any = null;
  private imageOverlayCopy: any = null;

  state: State = {
    taskList: [],
    userList: [],
    projectStateAttribute: {
      _id: "",
      name: "",
      location: {
        lat: 0,
        lng: 0,
        name: "",
      },
      createdAt: moment().toDate(),
      service: "SPRAY",
      crop: "",
      geoList: [],
      ownedBy: "",
      sharedTo: [],
      image: {
        token: "",
        east: 0,
        north: 0,
        south: 0,
        west: 0,
      },
      signature: "",
      remarks: "",
    },
  };

  componentDidMount = async () => {
    while (!window.google) {
      await sleep(1000);
    }

    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("id");

    if (selectedId) {
      const projectData = await getSelectedProject(selectedId);
      if (projectData) {
        this.setState(
          {
            projectStateAttribute: projectData,
          },
          () => {
            this.handleInitGoogleMap();
            this.handleLoadPolygon();
            this.handleGetSharedTo();

            if (this.handleGetImageExist()) {
              this.handleDisplayImageOverlay();
            }
          }
        );
      }

      const taskList = await getLinkedTaskList(selectedId);
      this.setState({ taskList });
    }
  };

  handleGetImageExist = () => {
    return (
      this.state.projectStateAttribute.image &&
      this.state.projectStateAttribute.image?.token &&
      this.state.projectStateAttribute.image?.east &&
      this.state.projectStateAttribute.image?.west &&
      this.state.projectStateAttribute.image?.north &&
      this.state.projectStateAttribute.image?.south
    );
  };

  handleInitGoogleMap = () => {
    const option = {
      center: new window.google.maps.LatLng(
        this.state.projectStateAttribute.location.lat,
        this.state.projectStateAttribute.location.lng
      ),
      zoom: 15,
      disableDefaultUI: true,
      mapTypeId: "satellite",
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      gestureHandling: "none",
    };
    this.googleMap = new window.google.maps.Map(this.map.current, option);

    const polygonStyle = {
      fillColor: "#5DB075",
      strokeColor: "#FFFFFF",
    };

    this.googleMap.data.setStyle(polygonStyle);

    if (this.handleGetImageExist()) {
      this.overlayGoogleMap = new window.google.maps.Map(
        this.overlayMap.current,
        option
      );

      this.googleMapWithoutPolygon = new window.google.maps.Map(
        this.mapWithoutPolygon.current,
        option
      );
      this.overlayGoogleMap.data.setStyle(polygonStyle);
    }
  };

  handleLoadPolygon = async () => {
    this.googleMap.data.forEach((element: any) => {
      this.googleMap.data.remove(element);
    });

    await this.googleMap.data.addGeoJson({
      type: "FeatureCollection",
      features: this.state.projectStateAttribute.geoList,
    });

    this.handleUpdatePolygonColor(this.googleMap.data);

    if (this.handleGetImageExist()) {
      this.overlayGoogleMap.data.forEach((element: any) => {
        this.overlayGoogleMap.data.remove(element);
      });

      await this.overlayGoogleMap.data.addGeoJson({
        type: "FeatureCollection",
        features: this.state.projectStateAttribute.geoList,
      });

      this.handleUpdatePolygonColor(this.overlayGoogleMap.data);
    }

    if (this.state.projectStateAttribute.geoList.length > 0) {
      var bounds = new window.google.maps.LatLngBounds();
      this.googleMap.data.forEach((feature: any) => {
        feature.getGeometry().forEachLatLng((latlng: any) => {
          bounds.extend(latlng);
        });
      });
      if (this.handleGetImageExist()) {
        this.overlayGoogleMap.data.forEach((feature: any) => {
          feature.getGeometry().forEachLatLng((latlng: any) => {
            bounds.extend(latlng);
          });
        });
      }
      this.googleMap.fitBounds(bounds);
    }
  };

  handleUpdatePolygonColor = (data: any) => {
    data.setStyle((feature: any) => {
      let color = GeoPolygonColor.DEFAULT;
      if (feature.j.color) {
        color =
          GeoPolygonColor[feature.j.color as keyof typeof GeoPolygonColor];
      }
      return {
        fillColor: color,
        strokeColor: "#FFFFFF",
      };
    });
  };

  handleDisplayImageOverlay = () => {
    if (
      this.state.projectStateAttribute.image &&
      this.state.projectStateAttribute.image?.token &&
      this.state.projectStateAttribute.image?.east &&
      this.state.projectStateAttribute.image?.west &&
      this.state.projectStateAttribute.image?.north &&
      this.state.projectStateAttribute.image?.south
    ) {
      const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
      let defaultWebUrl =
        "https://firebasestorage.googleapis.com/v0/b/lesq-spp-dev.appspot.com/o/projects%2F";
      if (isProd) {
        defaultWebUrl =
          "https://firebasestorage.googleapis.com/v0/b/lesq-spp.appspot.com/o/projects%2F";
      }
      const imageBounds = {
        north: Number(this.state.projectStateAttribute.image?.north),
        south: Number(this.state.projectStateAttribute.image?.south),
        east: Number(this.state.projectStateAttribute.image?.east),
        west: Number(this.state.projectStateAttribute.image?.west),
      };
      this.imageOverlay = new window.google.maps.GroundOverlay(
        `${defaultWebUrl}${this.state.projectStateAttribute._id}%2Fmap.png?alt=media&token=${this.state.projectStateAttribute.image?.token}`,
        imageBounds
      );

      this.imageOverlayCopy = new window.google.maps.GroundOverlay(
        `${defaultWebUrl}${this.state.projectStateAttribute._id}%2Fmap.png?alt=media&token=${this.state.projectStateAttribute.image?.token}`,
        imageBounds
      );

      this.imageOverlay.setMap(this.overlayGoogleMap);
      this.imageOverlayCopy.setMap(this.googleMapWithoutPolygon);
      this.overlayGoogleMap.fitBounds(imageBounds);
      this.googleMapWithoutPolygon.fitBounds(imageBounds);
    }
  };

  handleClearCanva = () => {
    this.canvasSignature.clear();
  };

  handleGetSharedTo = async () => {
    if (this.state.projectStateAttribute.sharedTo.length > 0) {
      const userData = await getUserAccessList(
        this.state.projectStateAttribute.sharedTo
      );
      this.setState({
        userList: userData,
      });
    }
  };

  handleUploadSignature = async () => {
    if (!this.state.projectStateAttribute.remarks) {
      window.alert("Place your remarks at the input provided");
    } else {
      const clonedProjectAttribute = JSON.parse(
        JSON.stringify(this.state.projectStateAttribute)
      );
      const imageUrl = await uploadImageStorage(
        this.state.projectStateAttribute._id,
        this.canvasSignature.getCanvas().toDataURL()
      );
      clonedProjectAttribute["signature"] = imageUrl;
      this.setState(
        {
          projectStateAttribute: clonedProjectAttribute,
        },
        async () => {
          const createProjectResponse = await updateProject(
            clonedProjectAttribute
          );
          if (createProjectResponse) {
            window.alert("Unable to update signature. Please try again later");
          }
        }
      );
    }
  };

  handleOnChangeFilter = (e: any) => {
    if (this.state.projectStateAttribute.signature) {
      return;
    }

    const clonedProjectAttribute = JSON.parse(
      JSON.stringify(this.state.projectStateAttribute)
    );
    clonedProjectAttribute[e.target.id] = e.target.value;
    this.setState({
      projectStateAttribute: clonedProjectAttribute,
    });
  };

  renderAccessUser = () => {
    const userList: JSX.Element[] = [];
    this.state.userList.map((eachUser: UserModel, index: number) => {
      userList.push(
        <li
          key={index}
          className="pr-4 py-3 flex items-center justify-between text-sm"
        >
          <div className="w-0 flex-1 flex items-center">
            <span className="flex-1 w-0 truncate">
              {eachUser.name} ({eachUser.company})
            </span>
          </div>
          <div className="ml-4 flex-shrink-0">{Role[eachUser.role]}</div>
        </li>
      );
      return "";
    });
    return userList;
  };

  renderPolygonInfo = (done: boolean) => {
    const reportList: Object[] = [];

    const filterGeoList = this.state.projectStateAttribute.geoList.filter(
      (eachGeo: any) => {
        return done
          ? eachGeo.properties.color === "BLUE"
          : eachGeo.properties.color !== "BLUE";
      }
    );

    filterGeoList.map((eachGeo) => {
      const acreUnit = this.props.utilsStore.units === "acre";
      const polygonProperties = {
        id: eachGeo.properties.id,
        polygon: eachGeo.properties.name,
        maxHeight: 0,
        totalSprayVol: 0,
        areaCovered: Number(
          eachGeo.properties.area / (acreUnit ? 4047 : 10000)
        ).toFixed(2),
        codes: "",
      };

      this.state.taskList.map((eachTask) => {
        if (eachTask.polygonId === eachGeo.properties.id) {
          if (eachTask.flightHeight > polygonProperties["maxHeight"]) {
            polygonProperties["maxHeight"] = eachTask.flightHeight;
          }
          polygonProperties["totalSprayVol"] += eachTask.totalChemicalUsed;
          if (polygonProperties["codes"]) {
            polygonProperties["codes"] += "," + eachTask.purpose;
          } else {
            polygonProperties["codes"] += eachTask.purpose;
          }
        }
        return "";
      });

      if (!polygonProperties["codes"]) {
        polygonProperties["codes"] = "-";
      }
      reportList.push(polygonProperties);

      return "";
    });
    return reportList;
  };

  renderTasksInfo = (done: boolean) => {
    const reportList: Object[] = [];

    const filterGeoList = this.state.projectStateAttribute.geoList.filter(
      (eachGeo: any) => {
        return done
          ? eachGeo.properties.color === "BLUE"
          : eachGeo.properties.color !== "BLUE";
      }
    );

    filterGeoList.map((eachGeo) => {
      this.state.taskList.map((eachTask) => {
        if (eachTask.polygonId === eachGeo.properties.id) {
          reportList.push({
            task: eachTask.purpose,
            date: moment(eachTask.date).format("DD MMM YY"),
            flightTime: eachTask.flightTime,
            dosage: eachTask.dosage,
            manPower: eachTask.manPower,
            batteryUsed: eachTask.batteryUsed,
            chemicalUsed: eachTask.typeOfChemicalUsed,
            flight: eachGeo.properties.name,
            type: TaskMode[eachTask.mode as keyof typeof TaskMode],
            completed:
              String(eachTask.completed) === "true"
                ? "Completed"
                : "Not Completed",
          });
        }
        return "";
      });
      return "";
    });

    return reportList;
  };

  renderReportUI = () => {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    const acreUnit = this.props.utilsStore.units === "acre";
    let defaultWebUrl =
      "https://firebasestorage.googleapis.com/v0/b/lesq-spp-dev.appspot.com/o/projects%2F";
    if (isProd) {
      defaultWebUrl =
        "https://firebasestorage.googleapis.com/v0/b/lesq-spp.appspot.com/o/projects%2F";
    }

    const polygonHeaders: TableHeader[] = [
      {
        key: "id",
        title: "ID",
      },
      {
        key: "polygon",
        title: "Polygon",
      },
      {
        key: "maxHeight",
        title: "Max Flight Height(m)",
      },
      {
        key: "totalSprayVol",
        title: "Total Spray Vol(l)",
      },
      {
        key: "areaCovered",
        title: `Area(${acreUnit ? "acre" : "hectare"})`,
      },
      {
        key: "codes",
        title: "Task Codes",
      },
    ];

    const taskHeaders: TableHeader[] = [
      {
        key: "task",
        title: "Task",
      },
      {
        key: "date",
        title: "Date",
      },
      {
        key: "flightTime",
        title: "Flight Time",
      },
      {
        key: "manPower",
        title: "Man Power",
      },
      {
        key: "batteryUsed",
        title: "Battery (unit)",
      },
      {
        key: "dosage",
        title: "Dosage (litre)",
      },
      {
        key: "chemicalUsed",
        title: "Chemical Used",
      },
      {
        key: "type",
        title: "Service Type",
      },
      {
        key: "completed",
        title: "Completed",
      },
    ];

    const element = (
      <div
        className="flex flex-col justify-center items-center w-full p-6 sm:w-3/4"
        ref={(el) => (this.printContainer = el)}
      >
        <div className="flex flex-row w-full mb-4 justify-center items-center">
          <div className="flex flex-col">
            <img
              className="h-10 w-20 object-fit mr-2"
              src={Logo}
              alt="LesQ SPP Logo"
            />
            <h2 className="mt-2 text-xs font-bold text-gray-700 font-display">
              Sistem Pertanian Pintar
            </h2>
          </div>
          <div className="flex-1" />
        </div>

        <div className="h-96 w-full" ref={this.map}></div>

        <div className="w-full mt-8 bg-white shadow-sm overflow-hidden sm:rounded-sm">
          <div className="px-4 py-3 sm:px-6">
            <h3 className="text-md leading-6 font-medium text-gray-900">
              Polygon Color Legend
            </h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-2 sm:px-6">
            {Object.keys(GeoPolygonColor).map((eachValue, index) => (
              <div className="flex flex-rol items-center my-0.5">
                <div
                  className="h-2 w-2 rounded-full"
                  style={{
                    backgroundColor:
                      GeoPolygonColor[
                        eachValue as keyof typeof GeoPolygonColor
                      ],
                  }}
                />
                <h1 className="mx-1 text-sm" key={index}>
                  {renderPolygonColorStatus(
                    eachValue as keyof typeof GeoPolygonColor
                  )}
                </h1>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full mt-8 bg-white shadow-sm overflow-hidden sm:rounded-sm">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Project Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {`ID : ${this.state.projectStateAttribute._id}`}
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Title</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {this.state.projectStateAttribute.name}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Service Type
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {ServiceType[this.state.projectStateAttribute.service]}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Location </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {this.state.projectStateAttribute.location.name}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  GPS Coordinates
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {this.state.projectStateAttribute.location.lat.toFixed(4)}
                  {" , "}
                  {this.state.projectStateAttribute.location.lng.toFixed(4)}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Crops Type
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {this.state.projectStateAttribute.crop}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Operators</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <ul className="divide-y divide-gray-200">
                    {this.renderAccessUser()}
                  </ul>
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500 mb-1">
                  Completed Polygon Information
                </dt>
                <Table
                  header={polygonHeaders}
                  pageIndex={1}
                  size={this.state.projectStateAttribute.geoList.length ?? "0"}
                  data={this.renderPolygonInfo(true)}
                  hideFooter={true}
                />
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500 mb-1">
                  Completed Task Information
                </dt>
                <Table
                  header={taskHeaders}
                  pageIndex={1}
                  size={this.state.projectStateAttribute.geoList.length ?? "0"}
                  data={this.renderTasksInfo(true)}
                  hideFooter={true}
                />
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500 mb-1">
                  Pending Polygon Information
                </dt>
                <Table
                  header={polygonHeaders}
                  pageIndex={1}
                  size={this.state.projectStateAttribute.geoList.length ?? "0"}
                  data={this.renderPolygonInfo(false)}
                  hideFooter={true}
                />
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500 mb-1">
                  Pending Task Information
                </dt>
                <Table
                  header={taskHeaders}
                  pageIndex={1}
                  size={this.state.projectStateAttribute.geoList.length ?? "0"}
                  data={this.renderTasksInfo(false)}
                  hideFooter={true}
                />
              </div>
            </dl>
          </div>
        </div>
        {this.handleGetImageExist() && (
          <div className="flex flex-col w-full mt-6 sm:flex-row">
            <div className="w-full mr-4">
              <div className="flex flex-row w-full mb-4">
                <h1 className=" font-bold">Map Overlay</h1>
                <div className="flex-1"></div>
              </div>
              <div className="h-96 w-full mb-8" ref={this.overlayMap}></div>
            </div>
            <div className="w-full">
              <div className="flex flex-row w-full mb-4">
                <h1 className=" font-bold">Map Overlay Without Polygon</h1>
                <div className="flex-1"></div>
              </div>
              <div className="h-96 w-full" ref={this.mapWithoutPolygon}></div>
            </div>
          </div>
        )}

        <div className="w-full mt-8 bg-white shadow-sm overflow-hidden sm:rounded-sm">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Action Required
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Client's Signature for the verification of the performed tasks
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <h1 className="my-2 text-sm font-medium">Signature</h1>
            {this.state.projectStateAttribute.signature ? (
              <>
                <img
                  style={{ width: 300, height: 200 }}
                  alt=""
                  src={`${defaultWebUrl}${this.state.projectStateAttribute._id}%2Fsignature.png?alt=media&token=${this.state.projectStateAttribute.signature}`}
                />
              </>
            ) : (
              <>
                <div className="flex flex-col" style={{ width: 300 }}>
                  <div
                    className="rounded border mb-1 bg-gray-50"
                    style={{ width: 300 }}
                  >
                    <SignatureCanvas
                      ref={(ref) => {
                        this.canvasSignature = ref;
                      }}
                      penColor="black"
                      canvasProps={{ width: 300, height: 200 }}
                    />
                  </div>
                  <TextButton
                    id="clear-button"
                    className=""
                    text="Clear"
                    onClick={this.handleClearCanva}
                  />
                </div>
              </>
            )}
            <h1 className="text-sm font-medium mt-3 mb-1"> Remarks</h1>
            <Input
              id="remarks"
              placeholder="Additional Remarks"
              onChange={this.handleOnChangeFilter}
              value={this.state.projectStateAttribute.remarks ?? ""}
            />
            {!this.state.projectStateAttribute.signature && (
              <div className="w-full mt-5 flex flex-row-reverse">
                <Button
                  onClick={this.handleUploadSignature}
                  text="Submit"
                  type="normal"
                  id="submit-button"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );

    return element;
  };

  render() {
    return (
      <>
        <div
          className="flex flex-col justify-center items-center w-full"
          id="report-view"
        >
          <ReactToPrint
            trigger={() => {
              return (
                <Button text="Download PDF" type="normal" id="submit-button" />
              );
            }}
            content={() => this.printContainer}
          />
          {this.renderReportUI()}
        </div>
      </>
    );
  }
}

export default ReportView;
