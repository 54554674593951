import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import React, { Component, Fragment } from "react";
import {
  createSensor,
  getSelectedSensors,
  handleCreateSensorCondition,
  updateSensor,
} from "../../store/actions/sensorActions";
import { Items, items } from "../../utils";
import AddressInput, { Address } from "../base/AddressInput";
import Button from "../base/Button";
import Input from "../base/Input";
import Select, { Option } from "../base/Select";
import SmartSelect from "../base/SmartSelect";
import { Sensor, SensorAnalyticsType } from "../model/Sensor";

interface Props {
  visible: boolean;
  sensorType: keyof typeof SensorAnalyticsType;
  creation: boolean;
  selectedSensor: {
    _id: string;
    name: string;
  };
  handleGetPreviewData: () => void;
  onClose: (
    createSensorVisible: boolean,
    creation: boolean,
    id: string,
    e?: any
  ) => void;
}

interface State {
  sensor: Sensor;
  sensorError: SensorErrorAttribute;
}

export interface SensorErrorAttribute {
  idError: string;
  nameError: string;
  codeError: string;
  cropError: string;
  tagError: string;
  locationError: string;
  sharedToError: string;
}

export default class CreateSensorModal extends Component<Props> {
  state: State = {
    sensor: {
      _id: "",
      name: "",
      code: "",
      crop: "",
      location: {
        lat: 0,
        lng: 0,
        name: "",
      },
      status: "INIT",
      tag: "",
      type: "SOIL",
      sharedTo: "",
      createdAt: moment().toDate(),
    },
    sensorError: {
      idError: "",
      nameError: "",
      codeError: "",
      cropError: "",
      tagError: "",
      locationError: "",
      sharedToError: "",
    },
  };

  componentDidUpdate = async (prevProps: Props) => {
    if (this.props.creation !== prevProps.creation) {
      if (!this.props.creation) {
        const sensorData = await getSelectedSensors(
          this.props.selectedSensor._id
        );
        this.setState({
          sensor: sensorData,
        });
      }
    }
  };

  componentWillUnmount = () => {
    this.handleClearSensorState();
  };

  handleClearSensorState = () => {
    this.setState({
      sensor: {
        _id: "",
        name: "",
        code: "",
        crop: "",
        location: {
          lat: 0,
          lng: 0,
          name: "",
        },
        status: "INIT",
        tag: "",
        type: "SOIL",
        sharedTo: "",
        createdAt: moment().toDate(),
      },
      sensorError: {
        idError: "",
        nameError: "",
        codeError: "",
        cropError: "",
        tagError: "",
        locationError: "",
        sharedToError: "",
      },
    });
  };

  handleOnClose = () => {
    this.handleClearSensorState();
    this.props.onClose(false, true, "");
  };

  handleOnSelectChange = (id: string, value: string | string[]) => {
    const clonedSensorAttribute = JSON.parse(JSON.stringify(this.state.sensor));
    clonedSensorAttribute[id] = value;
    this.setState({
      sensor: clonedSensorAttribute,
    });
  };

  handleAddressChange = (address: Address) => {
    const clonedSensorAttribute = JSON.parse(JSON.stringify(this.state.sensor));
    clonedSensorAttribute["location"] = address;
    this.setState({
      sensor: clonedSensorAttribute,
    });
  };

  handleOnChange = (e: any) => {
    const clonedSensorAttribute = JSON.parse(JSON.stringify(this.state.sensor));
    clonedSensorAttribute[e.target.id] = e.target.value;
    this.setState({
      sensor: clonedSensorAttribute,
    });
  };

  renderCodeOptions = () => {
    const codeOptions: Option[] = [];
    items.map((eachItem: Items) => {
      if (eachItem.type === this.props.sensorType) {
        codeOptions.push({
          key: eachItem.code,
          title:
            eachItem.name.length > 30
              ? eachItem.name.substring(0, 30) + "..."
              : eachItem.name,
        });
      }
      return null;
    });
    return codeOptions;
  };

  handleSubmit = () => {
    const conditionsList: string[] = [
      "id",
      "name",
      "code",
      "crop",
      "tag",
      "location",
    ];
    const clonedSensorError = JSON.parse(
      JSON.stringify(this.state.sensorError)
    );
    handleCreateSensorCondition(
      this.state.sensor,
      clonedSensorError,
      conditionsList
    );
    this.setState(
      {
        sensorError: clonedSensorError,
      },
      async () => {
        if (
          !this.state.sensorError.idError &&
          !this.state.sensorError.nameError &&
          !this.state.sensorError.codeError &&
          !this.state.sensorError.cropError &&
          !this.state.sensorError.tagError &&
          !this.state.sensorError.locationError
        ) {
          const clonedSensorAttribute = JSON.parse(
            JSON.stringify(this.state.sensor)
          );
          clonedSensorAttribute["type"] = this.props.sensorType;
          const createSensorResponse = this.props.creation
            ? await createSensor(clonedSensorAttribute)
            : await updateSensor(clonedSensorAttribute);
          if (
            typeof createSensorResponse === "string" &&
            createSensorResponse
          ) {
            clonedSensorError["nameError"] = createSensorResponse;
            this.setState({
              sensorError: clonedSensorError,
            });
          } else {
            this.props.handleGetPreviewData();
            this.handleClearSensorState();
            this.handleOnClose();
          }
        }
      }
    );
  };

  render() {
    return (
      <Transition.Root show={this.props.visible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={this.handleOnClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-bold text-gray-900"
                      >
                        {this.props.creation ? "Create" : "Edit"} Sensor
                      </Dialog.Title>
                    </div>
                    <div className="flex flex-col space-y-5 ml-4 mt-5">
                      {this.props.creation && (
                        <div className="flex flex-row">
                          <span className="w-44 inline-flex items-center text-md text-gray-800">
                            Code
                          </span>
                          <div className="ml-5 w-full">
                            <Select
                              id="code"
                              placeholder="Code"
                              options={this.renderCodeOptions()}
                              value={this.state.sensor.code}
                              error={this.state.sensorError.codeError}
                              onChange={this.handleOnSelectChange}
                            />
                          </div>
                        </div>
                      )}
                      {this.props.creation && (
                        <div className="flex flex-row">
                          <span className="w-44 inline-flex items-center text-md text-gray-800">
                            Sensor ID
                          </span>
                          <div className="ml-5 w-full">
                            <Input
                              id="_id"
                              placeholder="Sensor ID"
                              onChange={this.handleOnChange}
                              value={this.state.sensor._id}
                              error={this.state.sensorError.idError}
                            />
                          </div>
                        </div>
                      )}
                      <div className="flex flex-row">
                        <span className="w-44 inline-flex items-center text-md text-gray-800">
                          Name
                        </span>
                        <div className="ml-5 w-full">
                          <Input
                            id="name"
                            placeholder="Name"
                            onChange={this.handleOnChange}
                            value={this.state.sensor.name}
                            error={this.state.sensorError.nameError}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <span className="w-44 inline-flex items-center text-md text-gray-800">
                          Tag
                        </span>
                        <div className="ml-5 w-full">
                          <Input
                            id="tag"
                            placeholder="Eg Zone A"
                            onChange={this.handleOnChange}
                            value={this.state.sensor.tag}
                            error={this.state.sensorError.tagError}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <span className="w-44 inline-flex items-center text-md text-gray-800">
                          Crop
                        </span>
                        <div className="ml-5 w-full">
                          <Input
                            id="crop"
                            placeholder="Eg Palm Oil/ Paddy"
                            onChange={this.handleOnChange}
                            value={this.state.sensor.crop}
                            error={this.state.sensorError.cropError}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <span className="w-44 inline-flex items-center text-md text-gray-800">
                          Address
                        </span>
                        <div className="ml-5 w-full">
                          <AddressInput
                            disableTitle={true}
                            id="address"
                            onChange={this.handleAddressChange}
                            value={this.state.sensor.location}
                            error={this.state.sensorError.locationError}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <span className="w-44 inline-flex items-center text-md text-gray-800">
                          Shared To
                        </span>
                        <div className="ml-5 w-full">
                          <SmartSelect
                            id="sharedTo"
                            onChange={this.handleOnSelectChange}
                            value={this.state.sensor.sharedTo}
                            error={this.state.sensorError.sharedToError}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <Button
                      text={!this.props.creation ? "Edit" : "Add"}
                      type="normal"
                      onClick={this.handleSubmit}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
