export enum ServiceType {
  SPRAY = "Spraying",
  MAP = "Mapping",
  SEED = "Spreading",
}

export enum GeoPolygonColor {
  DEFAULT = "#5DB075",
  RED = "#ef4444",
  BLUE = "#60a5fa",
  YELLOW = "#facc15",
  PURPLE = "#c084fc",
}

export interface GeoPolygon {
  id: string;
  name: string;
  area: number;
  color: keyof typeof GeoPolygonColor;
}

/**
 * @description Image object to overlay map
 *
 * token - Token for image saved at projects/${id}/map.png
 * east - East boundary of the image (eg 12.5)
 * west - West boundary of the image (eg 12.5)
 * north - North boundary of the image (eg 12.5)
 * south - South boundary of the image (eg 12.5)
 */
export interface Image {
  token: string;
  east: number;
  west: number;
  north: number;
  south: number;
}

/**
 * @collection projects
 * @description Projects of LesQ SPP
 *
 * _id - Generated UUID v4
 * name - Name of the project
 * location - Location of the sensor
 * createdAt - Creation date of the project
 * service - Service type of LesQ
 * crop - Crop type
 * geoList - GeoJson.io data structure
 * ownedBy - Created by Firebase Auth ID
 * sharedTo - Shared to Firebase Auth ID
 * image - Image object that render on map
 * signature - Signature link that is store on the storage with the name as signature.png
 * remarks - Remarks for the projects
 */
export interface Project {
  _id: string;
  name: string;
  location: {
    name: string;
    lat: number;
    lng: number;
  };
  createdAt: Date;
  service: keyof typeof ServiceType;
  crop: string;
  geoList: any[];
  ownedBy: string;
  sharedTo: string[];
  image?: Image;
  signature?: string;
  remarks?: string;
}
