import React, { Component } from "react";

interface Props {
  id: string;
  text: string;
  direction: string;
  value: boolean;
  className?: string;
  onClick?: (e: any) => void;
}

export default class Toggle extends Component<Props> {
  handleOnClick = (e: any) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  handleTranslate = () => {
    let selectedTranslate = "translate-x-5";
    let unselectedTranslate = "translate-x-0";
    let defaultTranslate =
      "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ";
    if (this.props.value) {
      defaultTranslate += selectedTranslate;
    } else {
      defaultTranslate += unselectedTranslate;
    }

    return defaultTranslate;
  };

  handleClassName = () => {
    let selectedColor = "bg-emerald-600";
    let unselectColor = "bg-gray-200";
    let defaultClassName =
      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ";

    if (this.props.value) {
      defaultClassName += ` ${selectedColor}`;
    } else {
      defaultClassName += ` ${unselectColor}`;
    }
    return defaultClassName;
  };

  render() {
    return (
      <div className={`flex items-center ${this.props.className}`}>
        {this.props.direction === "left" && (
          <span className="mr-3">
            <span className="text-sm font-medium text-gray-900">
              {this.props.text}
            </span>
          </span>
        )}
        <button
          id={this.props.id}
          type="button"
          className={this.handleClassName()}
          role="switch"
          aria-checked="false"
          value={this.props.value.toString()}
          onClick={this.handleOnClick}
        >
          <span aria-hidden="true" className={this.handleTranslate()} />
        </button>
        {this.props.direction === "right" && (
          <span className="ml-3">
            <span className="text-sm font-medium text-gray-900">
              {this.props.text}
            </span>
          </span>
        )}
      </div>
    );
  }
}
