import {
  ChipIcon,
  CloudUploadIcon,
  EyeIcon,
  InformationCircleIcon,
  PencilIcon,
  ShieldExclamationIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/base/Button";
import ConfirmationModal from "../components/base/ConfirmationModal";
import Input from "../components/base/Input";
import LineChart from "../components/base/LineChart";
import Overlay from "../components/base/Overlay";
import Table, { TableHeader } from "../components/base/Table";
import CreateSensorModal from "../components/dashboard/CreateSensorModal";
import { DashboardPage } from "../components/dashboard/LeftNav";
import { SensorAnalyticsType, SensorStatus } from "../components/model/Sensor";
import SensorAnalyticsModel from "../components/model/SensorAnalytics";
import { withRouter } from "../navigator/NavigateWrapper";
import {
  clearSensors,
  deleteSensor,
  getSensorAnalytics,
  getSensorsWithPagination,
} from "../store/actions/sensorActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { sensorStateInterface } from "../store/reducers/sensorReducer";

interface Props {
  page: DashboardPage;
  navigate: any;
  authStore: authStateInterface;
  sensorStore: sensorStateInterface;
  getSensorsWithPagination: (
    text: string,
    type: keyof typeof SensorAnalyticsType,
    skip: number
  ) => void;
  clearSensors: () => void;
}

interface State {
  searchText: string;
  pageIndex: number;
  createSensorVisible: boolean;
  analytics: SensorAnalyticsModel;
  selectedSensor: {
    _id: string;
    name: string;
  };
  loading: boolean;
  creation: boolean;
}

class SensorScreen extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;

  state: State = {
    searchText: "",
    pageIndex: 0,
    createSensorVisible: false,
    analytics: {
      abnormalSensor: 0,
      createdDate: moment().toDate(),
      dateString: "",
      dateStringWithTime: "",
      deployedSensor: 0,
      id: "",
      inactiveSensor: 0,
      totalSensor: 0,
      type: "SOIL",
      userId: "",
    },
    selectedSensor: {
      _id: "",
      name: "",
    },
    loading: false,
    creation: true,
  };

  componentDidMount = () => {
    this.handleGetPreviewData();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      JSON.stringify(prevProps.authStore.user) !==
        JSON.stringify(this.props.authStore.user) ||
      prevProps.page !== this.props.page
    ) {
      this.props.clearSensors();
      this.setState({ searchText: "" }, () => {
        this.handleGetPreviewData();
      });
    }
  };

  componentWillUnmount = () => {
    this.props.clearSensors();
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = null;
    }
  };

  handleUpdateVisible = (
    createSensorVisible: boolean,
    creation: boolean,
    id: string,
    e?: any
  ) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({
      createSensorVisible,
      creation,
      selectedSensor: {
        _id: id,
        name: "",
      },
    });
  };

  handleGetPreviewData = async () => {
    if (this.props.authStore.user) {
      this.props.getSensorsWithPagination(
        this.state.searchText,
        this.renderPageDetail(),
        this.state.pageIndex
      );
      const analyticsData = await getSensorAnalytics(
        this.props.authStore.user?._id ?? "",
        this.renderPageDetail()
      );
      this.setState({
        analytics: analyticsData.data,
      });
    }
  };

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex }, () => {
      this.handleGetPreviewData();
    });
  };

  handleNavigateDetails = (id: string) => {
    const sensorType = this.renderPageDetail();
    if (sensorType === "SOIL") {
      this.props.navigate(`/dashboard/soilDetail?id=${id}`);
    } else if (sensorType === "WEATHER") {
      this.props.navigate(`/dashboard/weatherDetail?id=${id}`);
    }
  };

  handleFilter = (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.searchTimeout = setTimeout(() => {
        this.handleUpdatePageIndex(0);
      }, 500);
    });
  };

  handleUpdateSelectedSensor = (
    selectedSensor: {
      _id: string;
      name: string;
    },
    e: any
  ) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({
      selectedSensor,
    });
  };

  handleUpdateLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleRemoveSensor = async () => {
    this.handleUpdateLoading(true);
    const deleteSensorResponse = await deleteSensor(
      this.state.selectedSensor._id
    );

    if (!deleteSensorResponse) {
      this.handleGetPreviewData();
    } else {
      window.alert("Unable to delete. Please try again later");
    }
    this.handleUpdateLoading(false);
  };

  renderPageDetail = () => {
    let sensorType: keyof typeof SensorAnalyticsType = "WEATHER";
    if (this.props.page === DashboardPage.SOILFERTILITY) {
      sensorType = "SOIL";
    }
    return sensorType;
  };

  renderFilteredData = () => {
    const sensorList: Object[] = [];
    this.props.sensorStore.sensors.map((eachSensor: any) => {
      sensorList.push({
        id: eachSensor._id,
        name: eachSensor.name,
        status: SensorStatus[eachSensor.status as keyof typeof SensorStatus],
        crop: eachSensor.crop,
        code: eachSensor.code,
        estate: eachSensor.location.name,
        tag: (
          <span className="inline-flex items-center px-2 py-0.5 rounded-md text-sm font-medium bg-emerald-600 text-white">
            {eachSensor.tag}
          </span>
        ),
        view: (
          <div className="cursor-pointer ml-2">
            <EyeIcon
              className="h-5 w-5 block text-green-600"
              onClick={this.handleNavigateDetails.bind(this, eachSensor._id)}
            />
          </div>
        ),
        edit: (
          <div className="cursor-pointer ml-2">
            <PencilIcon
              className="h-5 w-5 block text-green-600"
              onClick={this.handleUpdateVisible.bind(
                this,
                true,
                false,
                eachSensor._id
              )}
            />
          </div>
        ),
        delete: (
          <div className="cursor-pointer ml-2">
            <TrashIcon
              className="h-5 w-5 block text-green-600"
              onClick={this.handleUpdateSelectedSensor.bind(this, {
                _id: eachSensor._id,
                name: eachSensor.name,
              })}
            />
          </div>
        ),
      });
      return "";
    });
    return sensorList;
  };

  renderDashboardContent = () => {
    const updated = moment(this.state.analytics.createdDate).format(
      "DD/MM/YY ha"
    );
    const content = [
      {
        icon: <ChipIcon className="h-5 w-5 text-white" />,
        title: "Total Sensors",
        unit: "Total Unit(s)",
        content: this.state.analytics.totalSensor,
        viewBox: "0 0 24 24",
        fontSize: "text-lg",
        bgColor: "bg-emerald-400",
        textColor: "text-emerald-400",
        updated: updated,
      },
      {
        icon: <CloudUploadIcon className="h-5 w-5 text-white" />,
        title: "Deployed Sensors",
        unit: "Total Unit(s)",
        content: this.state.analytics.deployedSensor,
        fontSize: "text-lg",
        viewBox: "0 0 24 24",
        inverted: true,
        bgColor: "bg-emerald-400",
        textColor: "text-emerald-400",
        updated: updated,
      },
      {
        icon: <InformationCircleIcon className="h-5 w-5 text-white" />,
        title: "Inactive Sensors",
        unit: "Total Unit(s)",
        content: this.state.analytics.inactiveSensor,
        fontSize: "text-lg",
        viewBox: "0 0 24 24",
        inverted: true,
        bgColor: "bg-red-400",
        textColor: "text-red-400",
        updated: updated,
      },
      {
        icon: <ShieldExclamationIcon className="h-5 w-5 text-white" />,
        title: "Abnormal Sensors",
        unit: "Total Unit(s)",
        content: this.state.analytics.abnormalSensor,
        fontSize: "text-lg",
        viewBox: "0 0 24 24",
        inverted: true,
        bgColor: "bg-red-400",
        textColor: "text-red-400",
        updated: updated,
      },
    ];

    const contentViewList: any = [];

    content.map((eachContent, key: number) => {
      contentViewList.push(
        <div className="bg-white overflow-hidden shadow rounded-lg" key={key}>
          <div className="py-3 px-5">
            <div className="flex">
              <div>
                <div className="flex flex-row justify-center items-center">
                  <span
                    className={`flex p-2 mt-1 rounded-lg ${eachContent.bgColor}`}
                  >
                    {eachContent.icon}
                  </span>
                  <dt
                    className={`ml-2 text-lg ${eachContent.textColor} font-medium truncate`}
                  >
                    {eachContent.title}
                  </dt>
                </div>
                <div
                  className={`${eachContent.fontSize} px-2 text-2xl font-bold text-gray-900 mt-2`}
                >
                  {eachContent.content}
                </div>
                <span className={`px-2 text-xs text-gray-600 mt-2`}>
                  {eachContent.unit}
                </span>
                <div className={`px-2 text-xs text-gray-600`}>
                  Updated - {eachContent.updated}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      return null;
    });

    return contentViewList;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "ID",
      },
      {
        key: "name",
        title: "Sensor",
      },
      {
        key: "code",
        title: "Code",
      },
      {
        key: "crop",
        title: "Crop Type",
      },
      {
        key: "status",
        title: "Status",
      },
      {
        key: "estate",
        title: "Location",
      },
      {
        key: "tag",
        title: "Tag",
      },
      {
        key: "view",
        title: "View",
      },
      {
        key: "edit",
        title: "Edit",
      },
      {
        key: "delete",
        title: "Delete",
      },
    ];

    const headerComponent = {
      title: "List of Sensors",
      desc: "Order from latest creation date",
      buttonText: "Add Sensors",
      buttonOnClick: this.handleUpdateVisible.bind(this, true, true, ""),
    };

    const sensorType = this.renderPageDetail();

    return (
      <div className="mt-8">
        <Overlay
          loading={this.state.loading}
          text="Please wait while we are processing your request"
        />
        <ConfirmationModal
          content={`Are you sure that you want to delete ${this.state.selectedSensor.name} ?`}
          onClick={this.handleRemoveSensor}
          onClose={this.handleUpdateSelectedSensor.bind(this, {
            _id: "",
            name: "",
          })}
          title="Delete Sensor Confirmation"
          open={this.state.selectedSensor._id ? true : false}
        />
        <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
          <div className="lg:flex lg:space-x-4 w-full">
            <div className="flex-shrink-0 lg:w-1/2">
              <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2">
                {this.renderDashboardContent()}
              </div>
            </div>
            <LineChart type={sensorType} />
          </div>

          <div className="mt-5">
            <div className="flex flex-col py-2 sm:items-center sm:flex-row">
              <div className="flex flex-col">
                <p className="text-xl font-medium">{headerComponent.title}</p>
                <span className="text-sm text-gray-500">
                  {headerComponent.desc ?? ""}
                </span>
              </div>
              <div className="flex-1" />
              <div className="mt-3 sm:mt-0">
                <Input
                  placeholder="Search"
                  onChange={this.handleFilter}
                  value={this.state.searchText}
                />
              </div>
              {this.props.authStore.user?.role === "SUPER" && (
                <Button
                  className="my-3 sm:my-0 sm:ml-5"
                  text={headerComponent.buttonText}
                  type="normal"
                  onClick={headerComponent.buttonOnClick}
                />
              )}
            </div>
            <Table
              newTabUrl={
                sensorType === "SOIL"
                  ? "/dashboard/soilDetail?id="
                  : "/dashboard/weatherDetail?id="
              }
              loading={this.props.sensorStore.loading}
              header={headers}
              pageIndex={this.state.pageIndex}
              size={this.props.sensorStore.size}
              data={this.renderFilteredData()}
              handlePage={this.handleUpdatePageIndex}
              onClickRow={(id) => {
                this.handleNavigateDetails(id);
              }}
            />
          </div>
        </div>
        <CreateSensorModal
          sensorType={sensorType}
          visible={this.state.createSensorVisible}
          creation={this.state.creation}
          selectedSensor={this.state.selectedSensor}
          handleGetPreviewData={this.handleGetPreviewData}
          onClose={this.handleUpdateVisible.bind(this, false, true, "")}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    sensorStore: state.sensorStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearSensors: () => dispatch(clearSensors()),
    getSensorsWithPagination: (
      text: string,
      type: keyof typeof SensorAnalyticsType,
      skip: number
    ) => dispatch(getSensorsWithPagination(text, type, skip)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SensorScreen));
