import axios from "axios";
import { UserState, UserStateError } from "../../pages/Users";
import { getAPIUrl } from "../../utils/database";
import { auth } from "../../utils/firebase";

export const handleCreateUserCondition = (
  userAttributeState: UserState,
  userAttributeError: UserStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "email":
        /* eslint-disable */
        const filter =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        /* eslint-enable */
        if (!filter.test(userAttributeState.email.replace(/\s/g, ""))) {
          userAttributeError["emailError"] =
            "Please enter your email in the correct format";
        } else {
          userAttributeError["emailError"] = "";
        }
        break;
      case "name":
        if (userAttributeState.name.replace(/\s/g, "").length <= 0) {
          userAttributeError["nameError"] = "Please enter your name";
        } else {
          userAttributeError["nameError"] = "";
        }
        break;
      case "company":
        if (userAttributeState.name.replace(/\s/g, "").length <= 0) {
          userAttributeError["companyError"] = "Please enter your company";
        } else {
          userAttributeError["companyError"] = "";
        }
        break;
      case "password":
        if (userAttributeState.password.replace(/\s/g, "").length < 6) {
          userAttributeError["passwordError"] =
            "Please enter at least 6 characters";
        } else {
          userAttributeError["passwordError"] = "";
        }
        break;
    }
    return null;
  });
};

export const clearUsers = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_USER_LIST",
      payload: {
        users: [],
        size: 0,
      },
    });
  };
};

const updateUserLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_USER_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const getUsersWithPagination = (search: string, skip: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = await auth.currentUser?.getIdToken();

      let userListResponse;
      const data = {
        text: search,
        skip: skip * 10,
      };

      updateUserLoadingState(dispatch, true);

      if (search.length > 0) {
        userListResponse = await axios.post(`${getAPIUrl()}/user/search`, {
          data,
          idToken: token,
          token: process.env.REACT_APP_API_TOKEN,
        });
      } else {
        userListResponse = await axios.post(`${getAPIUrl()}/user/list`, {
          data,
          idToken: token,
          token: process.env.REACT_APP_API_TOKEN,
        });
      }

      dispatch({
        type: "UPDATE_USER_LIST",
        payload: {
          users: userListResponse.data.userData,
          size: userListResponse.data.size,
        },
      });

      updateUserLoadingState(dispatch, false);
    } catch (err: any) {
      updateUserLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const getUserSuggestions = async (search: string) => {
  try {
    const token = await auth.currentUser?.getIdToken();
    const data = {
      text: search,
    };

    const userListResponse = await axios.post(`${getAPIUrl()}/user/search`, {
      data,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });

    return userListResponse.data.userData;
  } catch (err) {}
};

export const getUserAccessList = async (id: string[]) => {
  try {
    const token = await auth.currentUser?.getIdToken();
    const userAccessList = await axios.post(
      `${getAPIUrl()}/project/sharedUsers`,
      {
        id,
        idToken: token,
        token: process.env.REACT_APP_API_TOKEN,
      }
    );
    return userAccessList.data.userData;
  } catch (err) {
    return [];
  }
};

export const getUserName = async (selectedId: string) => {
  const token = await auth.currentUser?.getIdToken();
  const userData = await axios.post(`${getAPIUrl()}/user/get`, {
    id: selectedId,
    idToken: token,
    token: process.env.REACT_APP_API_TOKEN,
  });
  if (userData) {
    return userData.data.userData.name;
  } else {
    return selectedId;
  }
};

export const createUser = async (userState: UserState) => {
  try {
    const token = await auth.currentUser?.getIdToken();

    const createUserResponse = await axios.post(`${getAPIUrl()}/user/create`, {
      user: userState,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    return createUserResponse;
  } catch (err: any) {
    return err.message;
  }
};

export const deleteUser = async (id: string) => {
  try {
    const token = await auth.currentUser?.getIdToken();
    await axios.post(`${getAPIUrl()}/user/delete`, {
      userId: id,
      idToken: token,
      token: process.env.REACT_APP_API_TOKEN,
    });
    return "";
  } catch (err: any) {
    return err.message;
  }
};
