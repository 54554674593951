export enum Role {
  SUPER = "Super Admin",
  OPERATOR = "Operator",
  USER = "User",
}

/**
 * @collection users
 * @description Client of LesQ
 *
 * id - Firebase Auth Id
 * name - Name of the user
 * email - Email of the user
 * company - Company of the user
 * role - Interface of Role
 * createdAt - User creation date
 */
export interface UserModel {
  _id: string;
  name: string;
  email: string;
  company: string;
  role: keyof typeof Role;
  createdAt: Date;
}
