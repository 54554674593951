import { MenuIcon } from "@heroicons/react/outline";
import React, { Component } from "react";
import IconButton from "../base/IconButton";

interface Props {
  handleToggleLeftNavMobileMenu: () => void;
}

class Header extends Component<Props> {
  render() {
    return (
      <div className="relative flex-shrink-0 flex h-16 bg-white shadow border-b border-gray-200 lg:hidden">
        <IconButton
          icon={<MenuIcon className="h-5 w-5 text-gray-400" />}
          className="px-4 border-r rounded-md border-gray-200 lg:hidden"
          onClick={this.props.handleToggleLeftNavMobileMenu}
        />
      </div>
    );
  }
}

export default Header;
