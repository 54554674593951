import { XIcon } from "@heroicons/react/solid";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { handleSignOut } from "../../store/actions/authActions";
import { authStateInterface } from "../../store/reducers/authReducer";
import { utilsStateInterface } from "../../store/reducers/utilsReducer";
import IconButton from "../base/IconButton";
import Toggle from "../base/Toggle";
import LocationImg from "./../../images/icon_location.png";
import ProjectImg from "./../../images/icon_project.png";
import SignOutImg from "./../../images/icon_signout.png";
import SoilFertilityImg from "./../../images/icon_soil.png";
import TasksImg from "./../../images/icon_tasks.png";
import UserImg from "./../../images/icon_user.png";
import WeatherImg from "./../../images/icon_weather.png";
import DashboardLogo from "./../../images/logo.png";

interface Props {
  navigate: any;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
  page: DashboardPage;
  leftNavMobileMenu: boolean;
  handleToggleLeftNavMobileMenu: () => void;
  updateUnits: (units: string) => void;
}

export enum DashboardPage {
  PROJECTS = "Projects",
  CREATEPROJECT = "Create Project",
  SOILFERTILITY = "Soil Fertility",
  SOILFERTILITYDETAIL = "Soil Fertility Detail",
  WEATHERSTATION = "Weather Station",
  WEATHERSTATIONDETAIL = "Weather Station Detail",
  TASKS = "Tasks",
  MAP = "Map View",
  REPORT = "Report",
  USERS = "Users",
  LOGOUT = "Sign Out",
}

export default class LeftNav extends Component<Props> {
  renderDashboardLogo = () => {
    return (
      <Link to="/dashboard">
        <div className="flex items-center flex-shrink-0 px-6 cursor-pointer">
          <img className="h-10 w-auto" src={DashboardLogo} alt="Favicon" />
        </div>
      </Link>
    );
  };

  renderLeftNavItems = (index: DashboardPage, others: DashboardPage[]) => {
    let title = "";
    let path = "";
    let img = ProjectImg;
    let defaultClass =
      "text-gray-500 hover:text-gray-500 hover:bg-yellow-100 group flex items-center px-2 py-2 text-sm font-bold rounded-md cursor-pointer h-12";

    if (this.props.page === index || others.includes(this.props.page)) {
      defaultClass =
        "bg-yellow-100 text-gray-500 group flex items-center px-2 py-2 text-sm font-bold rounded-md cursor-pointer h-12";
    }

    switch (index) {
      case DashboardPage.PROJECTS:
        title = "Projects";
        img = ProjectImg;
        path = "/dashboard";
        break;
      case DashboardPage.TASKS:
        title = "Tasks";
        img = TasksImg;
        path = "/dashboard/tasks";
        break;
      case DashboardPage.SOILFERTILITY:
      case DashboardPage.SOILFERTILITYDETAIL:
        title = "Soil Fertility";
        img = SoilFertilityImg;
        path = "/dashboard/soil";
        break;
      case DashboardPage.WEATHERSTATION:
      case DashboardPage.WEATHERSTATIONDETAIL:
        title = "Weather";
        img = WeatherImg;
        path = "/dashboard/weather";
        break;
      case DashboardPage.USERS:
        title = "Users";
        img = UserImg;
        path = "/dashboard/users";
        break;
      case DashboardPage.MAP:
        title = "Map View";
        img = LocationImg;
        path = "/dashboard/map";
        break;
      case DashboardPage.LOGOUT:
        title = "Log Out";
        img = SignOutImg;
        break;
    }

    const content = (
      <p className={defaultClass} aria-current="page">
        {title}
        <div className="flex-grow" />
        <div className="h-8 w-8">
          <img className="h-full w-auto object-cover" src={img} alt="" />
        </div>
      </p>
    );

    if (path) {
      return <Link to={path}>{content}</Link>;
    } else {
      return <div onClick={handleSignOut.bind(this)}>{content}</div>;
    }
  };

  renderNavItems = () => {
    if (this.props.utilsStore.onlineStatus) {
      const acreUnit = this.props.utilsStore.units === "acre";
      return (
        <>
          {this.renderLeftNavItems(DashboardPage.PROJECTS, [
            DashboardPage.CREATEPROJECT,
          ])}
          {this.renderLeftNavItems(DashboardPage.MAP, [])}
          {this.props.authStore.user?.role !== "USER" &&
            this.renderLeftNavItems(DashboardPage.TASKS, [])}
          {this.renderLeftNavItems(DashboardPage.SOILFERTILITY, [])}
          {this.renderLeftNavItems(DashboardPage.WEATHERSTATION, [])}
          {this.props.authStore.user?.role === "SUPER" &&
            this.renderLeftNavItems(DashboardPage.USERS, [])}
          {this.renderLeftNavItems(DashboardPage.LOGOUT, [])}
          <p
            className="text-gray-500 flex items-center px-2 py-2 text-sm font-bold h-12"
            aria-current="page"
          >
            <Toggle
              id="language"
              direction="right"
              text={""}
              value={acreUnit ? false : true}
              onClick={this.props.updateUnits.bind(
                this,
                acreUnit ? "hectare" : "acre"
              )}
            />
            Units : {acreUnit ? "Acre" : "Hectare"}
          </p>
        </>
      );
    } else {
      return (
        <>
          {this.props.authStore.user?.role !== "USER" &&
            this.renderLeftNavItems(DashboardPage.TASKS, [])}
        </>
      );
    }
  };

  render() {
    return (
      <>
        {this.props.leftNavMobileMenu && (
          <div
            className="fixed inset-0 flex z-40 lg:hidden"
            role="dialog"
            aria-modal="true"
          >
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-75"
              onClick={this.props.handleToggleLeftNavMobileMenu}
              aria-hidden="true"
            ></div>
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <IconButton
                  icon={<XIcon className="h-5 w-5 text-white" />}
                  background="bg-transparent"
                  className="text-white ml-1 rounded-md flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={this.props.handleToggleLeftNavMobileMenu}
                />
              </div>
              {this.renderDashboardLogo()}
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2">
                  <div className="flex flex-col space-y-1">
                    {this.renderNavItems()}
                  </div>
                </nav>
              </div>
            </div>
            <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
          </div>
        )}

        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-white">
            {this.renderDashboardLogo()}
            <div className="h-0 flex-1 flex flex-col overflow-y-auto">
              <nav className="px-3 mt-6">
                <div className="flex flex-col space-y-1">
                  {this.renderNavItems()}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  }
}
