import { CheckIcon } from "@heroicons/react/solid";
import React, { Component } from "react";
import { sleep } from "../../pages/CreateProject";
import {
  getProjectName,
  getProjectSuggestion,
} from "../../store/actions/projectActions";
import {
  getSensorCode,
  getSensorSuggestion,
} from "../../store/actions/sensorActions";
import {
  getUserName,
  getUserSuggestions,
} from "../../store/actions/userActions";
import { Project } from "../model/Project";
import { Sensor, SensorAnalyticsType } from "../model/Sensor";
import { UserModel } from "../model/User";
import { Option } from "./Select";

interface Props {
  id: string;
  type?: keyof typeof SensorAnalyticsType;
  options?: Option[];
  title?: string;
  value?: string | string[];
  placeholder?: string;
  className?: string;
  error?: string;
  onChange: (id: string, e: string | string[]) => void;
}

interface State {
  valuePlaceholder: string;
  onFocus: boolean;
  options: Option[];
}

export default class SmartSelect extends Component<Props> {
  typingTimeout: ReturnType<typeof setTimeout> | null = null;

  state: State = {
    valuePlaceholder: "",
    onFocus: false,
    options: [],
  };

  componentDidMount = () => {
    this.handleGetSuggestions();
    this.handleGetPreviewData();
  };

  componentDidUpdate = async (prevProps: Props) => {
    if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) {
      this.handleGetPreviewData();
    }
    if (this.props.type !== prevProps.type) {
      this.handleGetSuggestions();
    } else if (
      JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)
    ) {
      this.handleGetSuggestions();
    }
  };

  handleGetPreviewData = async () => {
    let valuePlaceholder = "";
    if (this.props.value && !Array.isArray(this.props.value)) {
      switch (this.props.id) {
        case "sensorId":
          valuePlaceholder = await getSensorCode(this.props.value);
          break;
        case "sharedTo":
          valuePlaceholder = await getUserName(this.props.value);
          break;
        case "projectId":
          valuePlaceholder = await getProjectName(this.props.value);
          break;
      }
      this.setState({
        valuePlaceholder,
      });
    } else {
      const selectedLabel: string[] = [];
      this.props.options?.map((eachOptions) => {
        if (this.props.value?.includes(eachOptions.key)) {
          selectedLabel.push(eachOptions.title);
        }
        return null;
      });

      this.setState({
        valuePlaceholder: selectedLabel.toString(),
      });
    }
  };

  handleGetSuggestions = async () => {
    let options: Option[] = [];
    switch (this.props.id) {
      case "sensorId":
        await sleep(300);
        const sensorData = await getSensorSuggestion(
          this.props.type ?? "SOIL",
          this.state.valuePlaceholder
        );
        if (sensorData.length > 0) {
          sensorData.map((eachSensor: Sensor) => {
            options.push({
              key: eachSensor._id,
              title: eachSensor.name,
            });
            return null;
          });
        }
        break;
      case "projectId":
        const projectData = await getProjectSuggestion(
          this.state.valuePlaceholder
        );
        if (projectData.length > 0) {
          projectData.map((eachProject: Project) => {
            options.push({
              key: eachProject._id,
              title: eachProject.name,
            });
            return null;
          });
        }
        break;
      case "sharedTo":
        const userData = await getUserSuggestions(this.state.valuePlaceholder);
        if (userData.length > 0) {
          userData.map((eachUser: UserModel) => {
            if (eachUser.role !== "SUPER") {
              options.push({
                key: eachUser._id,
                title: eachUser.email,
              });
            }
            return null;
          });
        }
        break;
      case "polygonId":
        options = this.props.options ?? [];
    }

    this.setState({
      options,
    });
  };

  handleOnChange = (e: any) => {
    this.setState({
      valuePlaceholder: e.target.value,
    });

    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
      this.typingTimeout = null;
    }

    this.typingTimeout = setTimeout(() => {
      this.handleGetSuggestions();
    }, 250);
  };

  handleBlur = () => {
    this.setState(
      {
        onFocus: false,
        valuePlaceholder: "",
      },
      () => {
        this.handleGetPreviewData();
      }
    );
  };

  handleFocus = () => {
    this.setState({
      onFocus: true,
      valuePlaceholder: "",
    });
  };

  handleOnSelectChange = (key: string) => {
    if (this.props.value && Array.isArray(this.props.value)) {
      const selectedValue = this.props.value ?? [];
      let newSelectedList = selectedValue?.length > 0 ? this.props.value : [];
      if (this.props.value?.includes(key)) {
        newSelectedList.splice(newSelectedList.indexOf(key), 1);
        this.props.onChange(this.props.id, newSelectedList);
      } else {
        newSelectedList?.push(key);
        this.props.onChange(this.props.id, newSelectedList);
      }
    } else {
      this.props.onChange(this.props.id, key === this.props.value ? "" : key);
    }
  };

  renderOptions = () => {
    let optionView: any = [];
    this.state.options.map((eachOption) => {
      let isSelected = false;
      if (Array.isArray(this.props.value)) {
        isSelected = this.props.value.includes(eachOption.key);
      } else {
        isSelected = this.props.value === eachOption.key;
      }
      optionView.push(
        <li
          key={eachOption.key}
          className="text-gray-900 cursor-default select-none relative px-5 py-3 hover:bg-green-300 group"
          onMouseDown={this.handleOnSelectChange.bind(this, eachOption.key)}
        >
          <span className="font-normal block truncate group-hover:text-white">
            {eachOption.title}
          </span>
          {isSelected && (
            <span className="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4 group-hover:text-white">
              <CheckIcon className="h-5 w-5" />
            </span>
          )}
        </li>
      );
      return null;
    });

    return optionView;
  };

  render() {
    let defaultClassName =
      "block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 focus:border-green-500 focus:outline-none";
    if (this.props.className) {
      defaultClassName += ` ${this.props.className}`;
    }
    if (this.props.error) {
      defaultClassName += " mb-0.5 border-red-300";
    }

    return (
      <div className="w-full">
        <div className="relative">
          <div className="flex justify-between">
            <label className="block text-sm font-medium text-gray-700">
              {this.props.title}
            </label>
          </div>
          <label className="sr-only">{this.props.title}</label>
          <div>
            <input
              id={this.props.id}
              className={defaultClassName}
              value={this.state.valuePlaceholder}
              placeholder={this.props.placeholder}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.handleOnChange}
            />
            {this.state.onFocus && (
              <ul className="absolute z-10 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {this.renderOptions()}
              </ul>
            )}
          </div>

          <span className="text-red-500 text-sm">{this.props.error}</span>
        </div>
      </div>
    );
  }
}
