import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { getSelectedProject } from "../../store/actions/projectActions";
import { getSelectedSensors } from "../../store/actions/sensorActions";
import { authStateInterface } from "../../store/reducers/authReducer";
import { Project, ServiceType } from "../model/Project";
import { Sensor, SensorStatus } from "../model/Sensor";
import Button from "./Button";

interface Props {
  open: boolean;
  authStore: authStateInterface;
  selectedMarker: {
    _id: string;
    type: string;
  };
  onClick: () => void;
  onClose: () => void;
}

interface State {
  sensor: Sensor;
  project: Project;
}

export default class MarkerDetailsModal extends Component<Props> {
  private initFocusDiv = React.createRef<HTMLDivElement>();
  state: State = {
    sensor: {
      _id: "",
      name: "",
      code: "",
      crop: "",
      location: {
        lat: 0,
        lng: 0,
        name: "",
      },
      status: "INIT",
      tag: "",
      type: "SOIL",
      sharedTo: "",
      createdAt: moment().toDate(),
    },
    project: {
      _id: "",
      name: "",
      location: {
        lat: 0,
        lng: 0,
        name: "",
      },
      createdAt: moment().toDate(),
      service: "SPRAY",
      crop: "",
      geoList: [],
      ownedBy: "",
      sharedTo: [],
      image: {
        token: "",
        east: 0,
        north: 0,
        south: 0,
        west: 0,
      },
    },
  };

  componentDidUpdate = async (prevProps: Props) => {
    if (this.props.selectedMarker._id !== prevProps.selectedMarker._id) {
      if (this.props.selectedMarker.type === "project") {
        const projectData = await getSelectedProject(
          this.props.selectedMarker._id
        );
        this.setState({
          project: projectData,
        });
      } else {
        const sensorData = await getSelectedSensors(
          this.props.selectedMarker._id
        );
        this.setState({
          sensor: sensorData,
        });
      }
    }
  };

  render() {
    const isProject = this.props.selectedMarker.type === "project";
    const isAdmin =
      this.props.authStore.user?.role === "SUPER" ||
      this.props.authStore.user?.role === "OPERATOR";
    return (
      <Transition.Root show={this.props.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={this.initFocusDiv}
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <div
            className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            ref={this.initFocusDiv}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-10 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg sm:p-6">
                <div>
                  <div className="border-b border-gray-200 pb-4">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {this.props.selectedMarker.type === "project"
                        ? "Project"
                        : "Sensor"}{" "}
                      Details
                    </Dialog.Title>
                    <span className="text-sm">
                      ID: {this.props.selectedMarker._id}
                    </span>
                  </div>
                  <div className="py-5">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {isProject ? "Title" : "Name"}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <span>
                            {isProject
                              ? this.state.project.name
                              : this.state.sensor.name}
                          </span>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {isProject ? "Service Type" : "Status"}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {isProject
                            ? ServiceType[this.state.project.service]
                            : SensorStatus[this.state.sensor.status]}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Location
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {isProject
                            ? this.state.project.location.name
                            : this.state.sensor.location.name}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          GPS Coordinates
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {isProject
                            ? this.state.project.location.lat.toFixed(4)
                            : this.state.sensor.location.lat.toFixed(4)}
                          {" , "}
                          {isProject
                            ? this.state.project.location.lng.toFixed(4)
                            : this.state.sensor.location.lng.toFixed(4)}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Crops Type
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {isProject
                            ? this.state.project.crop
                            : this.state.sensor.crop}
                        </dd>
                      </div>
                    </dl>
                  </div>
                  {isProject && isAdmin && (
                    <div className="flex flex-row">
                      <div className="flex-1"></div>
                      <Button
                        text="Edit"
                        type="normal"
                        onClick={this.props.onClick}
                      />
                    </div>
                  )}
                  {!isProject && (
                    <div className="flex flex-row">
                      <div className="flex-1"></div>
                      <Button
                        text="View"
                        type="normal"
                        onClick={this.props.onClick}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
