import { Dialog, Transition } from "@headlessui/react";
import { BriefcaseIcon, EyeIcon } from "@heroicons/react/outline";
import moment from "moment";
import React, { Component } from "react";
import { getLinkedTaskList } from "../../store/actions/tasksActions";
import { authStateInterface } from "../../store/reducers/authReducer";
import { utilsStateInterface } from "../../store/reducers/utilsReducer";
import Button from "../base/Button";
import Input from "../base/Input";
import Select, { Option } from "../base/Select";
import { GeoPolygon, GeoPolygonColor } from "../model/Project";
import { Task } from "../model/Task";
import TasksModal, { TaskType } from "./TaskModal";

interface Props {
  visible: boolean;
  projectId: string;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
  geoPolygon?: GeoPolygon;
  handleUpdateModalVisible: (id: string, bool: boolean) => void;
  handleSubmitPolygonCard: (properties: GeoPolygon) => void;
}

interface State {
  tasksList: Task[];
  selectedTaskIndex: number;
  properties: GeoPolygon;
}

const geoPolygonColorOption: Option[] = Object.keys(GeoPolygonColor).map(
  (eachColor) => {
    return {
      key: eachColor,
      title: eachColor.charAt(0) + eachColor.slice(1).toLowerCase(),
    };
  }
);

export default class PolygonCard extends Component<Props> {
  state: State = {
    properties: {
      id: this.props.geoPolygon?.id ?? "",
      name: this.props.geoPolygon?.name ?? "",
      area: this.props.geoPolygon?.area ?? 0,
      color: this.props.geoPolygon?.color ?? "DEFAULT",
    },
    tasksList: [],
    selectedTaskIndex: -1,
  };

  componentDidMount = () => {
    this.handleRetrieveTask();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      JSON.stringify(this.props.geoPolygon) !==
      JSON.stringify(prevProps.geoPolygon)
    ) {
      this.setState(
        {
          properties: {
            id: this.props.geoPolygon?.id ?? "",
            name: this.props.geoPolygon?.name ?? "",
            area: this.props.geoPolygon?.area ?? 0,
            color: this.props.geoPolygon?.color ?? "DEFAULT",
          },
          tasksList: [],
          selectedTaskIndex: -1,
        },
        () => {
          this.handleRetrieveTask();
        }
      );
    }
  };

  handleRetrieveTask = async () => {
    if (this.state.properties.id && this.props.projectId) {
      const taskData = await getLinkedTaskList(
        this.props.projectId,
        this.state.properties.id
      );
      if (taskData.length > 0) {
        this.setState({
          tasksList: taskData,
        });
      }
    }
  };

  handleOnChange = (e: any) => {
    const clonedProperties = JSON.parse(JSON.stringify(this.state.properties));
    clonedProperties[e.target.id] = e.target.value;
    this.setState({
      properties: clonedProperties,
    });
  };

  handleOnSelectChange = (id: string, value: string | string[]) => {
    const clonedProperties = JSON.parse(JSON.stringify(this.state.properties));
    clonedProperties[id] = value;
    this.setState({
      properties: clonedProperties,
    });
  };

  handleSubmit = () => {
    this.props.handleSubmitPolygonCard(this.state.properties);
  };

  handleUpdateSelectedTaskIndex = (selectedTaskIndex: number) => {
    this.setState({
      selectedTaskIndex,
    });
  };

  renderTaskDetails = () => {
    let taskList: JSX.Element[] = [];
    if (this.state.tasksList && this.state.tasksList.length > 0) {
      this.state.tasksList.map((eachTask: Task, index: number) => {
        taskList.push(
          <div
            className="flex justify-center items-center mt-2.5"
            key={eachTask._id}
          >
            <div className="p-2 rounded-full inline-flex items-center justify-center text-gray-400 bg-emerald-500">
              <BriefcaseIcon className="w-5 h-5 text-white" />
            </div>
            <div className="flex flex-col ml-3">
              <p className="font-medium">{eachTask.purpose}</p>
              <span className="text-sm text-gray-500">
                {moment(eachTask.date.seconds).format("DD/MM/YYYY")}
              </span>
            </div>
            <div className="grow" />
            <EyeIcon
              className="w-5 h-5 ml-2 text-emerald-500 hover:text-emerald-600 cursor-pointer"
              onClick={this.handleUpdateSelectedTaskIndex.bind(this, index)}
            />
          </div>
        );

        return null;
      });
    }

    return taskList;
  };

  render() {
    const isAdmin =
      this.props.authStore.user?.role === "SUPER" ||
      this.props.authStore.user?.role === "OPERATOR";
    const acreUnit = this.props.utilsStore.units === "acre";

    return (
      <Transition.Root show={this.props.visible}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={this.props.handleUpdateModalVisible.bind(
            this,
            "polygonModalVisible",
            false
          )}
        >
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <TasksModal
            type={TaskType.VIEW}
            selectedTask={
              this.state.selectedTaskIndex >= 0
                ? this.state.tasksList[this.state.selectedTaskIndex]
                : null
            }
            visible={this.state.selectedTaskIndex >= 0}
            onClose={this.handleUpdateSelectedTaskIndex.bind(this, -1)}
          />
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 mb-6 pb-8 sm:p-6 sm:pb-8">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-bold text-gray-900"
                      >
                        Polygon Details
                      </Dialog.Title>
                    </div>
                    <div className="flex flex-col space-y-5 ml-4 mt-5">
                      <div className="flex flex-row">
                        <span className="w-36 inline-flex items-center text-md text-gray-800">
                          Name
                        </span>
                        <div className="ml-5 w-full">
                          {isAdmin ? (
                            <Input
                              id="name"
                              value={this.state.properties.name}
                              placeholder=""
                              onChange={this.handleOnChange}
                            />
                          ) : (
                            <span className="text-base">
                              {this.state.properties.name}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <span className="w-36 inline-flex items-center text-md text-gray-800">
                          Color
                        </span>
                        <div className="ml-5 w-full text-base">
                          <Select
                            id="color"
                            placeholder="Pick A Color"
                            value={this.state.properties.color}
                            options={geoPolygonColorOption}
                            onChange={this.handleOnSelectChange}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <span className="w-36 inline-flex items-center text-md text-gray-800">
                          Area
                        </span>
                        <div className="ml-5 w-full text-base">
                          {Number(
                            this.state.properties.area /
                              (acreUnit ? 4047 : 10000)
                          ).toFixed(2)}{" "}
                          {acreUnit ? "acre" : "hectare"}
                        </div>
                      </div>
                    </div>

                    {this.state.tasksList.length > 0 && (
                      <>
                        <div className="border-t border-gray-200 mt-6 pt-6 pb-2 ml-4">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-bold text-gray-900"
                          >
                            Linked Task
                          </Dialog.Title>
                          <div className="mt-2">{this.renderTaskDetails()}</div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="px-4 py-3 pt-20 sm:px-6 flex flex-row-reverse">
                    {isAdmin && (
                      <Button
                        text="Add"
                        type="normal"
                        onClick={this.handleSubmit}
                      />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
