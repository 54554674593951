import axios from "axios";
import {
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { AdminState, AdminStateError } from "../../pages/Login";
import { getAPIUrl } from "../../utils/database";
import { auth } from "../../utils/firebase";
import { indexedDb } from "../../utils/indexedDB";

//INFO : Auth Listener
let unsubscribeAuthListener: any = null;

export const getAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (!unsubscribeAuthListener) {
      unsubscribeAuthListener = onAuthStateChanged(auth, async (user: any) => {
        if (user) {
          dispatch({
            type: "UPDATE_USER_AUTH",
            payload: {
              userAuth: user,
            },
          });
          if (getState().utilsStore.onlineStatus) {
            getUserInfo(dispatch);
          } else {
            const indexData = await indexedDb.getValue("local", "user");
            if (indexData) {
              dispatch({
                type: "UPDATE_USER",
                payload: {
                  user: indexData,
                },
              });
            }
          }
        } else {
          if (getState().utilsStore.onlineStatus) {
            dispatch({
              type: "UPDATE_USER_AUTH",
              payload: {
                userAuth: null,
              },
            });
            dispatch({
              type: "UPDATE_USER",
              payload: {
                user: null,
              },
            });
            await indexedDb.deleteValue("local", "user");
            await indexedDb.deleteValue("local", "tasks");
            await indexedDb.deleteValue("local", "projects");
            await indexedDb.deleteValue("local", "images");
            await indexedDb.deleteValue("local", "update");
          }
        }
      });
    }
  };
};

export const resendEmailVerification = async () => {
  if (auth.currentUser) {
    await sendEmailVerification(auth.currentUser);
  }
};

export const removeAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (unsubscribeAuthListener) {
      unsubscribeAuthListener();
      unsubscribeAuthListener = null;
    }
  };
};

export const signIn = async (credentials: {
  email: string;
  password: string;
}) => {
  try {
    await signInWithEmailAndPassword(
      auth,
      credentials.email,
      credentials.password
    );
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return "";
  } catch (err: any) {
    if (err.message.includes("user-not-found")) {
      return "Password reset link has been sent to your email if you have previously signed up";
    }
    return err.message;
  }
};

export const handleSignOut = async () => {
  try {
    await signOut(auth);
  } catch (err) {}
};

const getUserInfo = async (dispatch: any) => {
  try {
    if (auth.currentUser?.uid) {
      const token = await auth.currentUser.getIdToken();
      const authData = await axios.post(`${getAPIUrl()}/admin/getAuthInfo`, {
        idToken: token,
        token: process.env.REACT_APP_API_TOKEN,
      });
      if (authData.data) {
        const indexData = await indexedDb.getValue("local", "user");
        if (!indexData) {
          await indexedDb.putValue("local", {
            ...authData.data,
            type: "user",
          });
        }
        dispatch({
          type: "UPDATE_USER",
          payload: {
            user: authData.data,
          },
        });
      }
    }
  } catch (err: any) {
    if (err.message === "Network Error" || err.response.status === 400) {
      handleSignOut();
    }
  }
};

export const handleAuthCondition = (
  adminAttributeState: AdminState,
  adminAttributeError: AdminStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "email":
        /* eslint-disable */
        const filter =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        /* eslint-enable */
        if (!filter.test(adminAttributeState.email.replace(/\s/g, ""))) {
          adminAttributeError["emailError"] =
            "Please enter your email in the correct format";
        } else {
          adminAttributeError["emailError"] = "";
        }
        break;
      case "password":
        if (adminAttributeState.password.replace(/\s/g, "").length < 6) {
          adminAttributeError["passwordError"] =
            "Please enter at least 6 characters";
        } else {
          adminAttributeError["passwordError"] = "";
        }
        break;
    }
    return null;
  });
};
